import Vue from 'vue'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { generateIssueID } from '@/services/tiktrac'
import i18N from '@/plugins/i18n'

import type Tiktrac from 'vue/types/tiktrac'
import { AxiosError, AxiosResponse } from 'axios'

export enum AttendanceState {
  UNKNOWN = -1,
  INACTIVE = 0,
  ACTIVE = 1,
}

@Module({ namespaced: true })
class Attendance extends VuexModule {
  attendanceState: AttendanceState = AttendanceState.UNKNOWN

  get isAgencyMode() {
    return !!this.context.rootGetters['user/getUserInfo']?.company?.agency_mode
  }

  get getTimetracking(): Tiktrac.Timetracking {
    return this.context.rootGetters['Timetracking/getTimetracking']
  }

  get getAttendanceList(): Tiktrac.AttendanceList {
    return this.getTimetracking.attendanceList || {}
  }

  get getAttendanceActiveTimer(): string {
    return this.getTimetracking.properties?.attendanceActiveTimer || ''
  }

  get getAttendanceActiveTimerLimit(): number | null {
    return this.getTimetracking.properties?.attendanceActiveTimerLimit || null
  }

  get getAttendanceActiveStartTime(): number {
    if (!this.getTimetracking.attendanceList || !this.getTimetracking.properties) {
      return 0
    }
    const issue =
      this.getTimetracking.attendanceList[
        this.getTimetracking.properties.attendanceActiveTimer
      ] || null
    return issue ? issue.start : 0
  }

  get getAttendanceToday(): Tiktrac.AttendanceListElem | null {
    const list = this.getAttendanceList
    const today = new Date()

    let attendance: null | Tiktrac.AttendanceListElem = null

    const todayStartDateObject = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    )

    const todayEndDateObject = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1,
      0,
      0,
      0,
      0
    )

    const todayStart = todayStartDateObject.getTime()
    const todayEnd = todayEndDateObject.getTime() - 1

    for (const a in list) {
      const instance = list[a]
      if (instance.end > todayStart && instance.start < todayEnd) {
        attendance = instance
      }
    }
    return attendance
  }

  get getAttendanceState(): AttendanceState {
    return this.attendanceState
  }

  @Mutation
  public SET_ATTENDANCE(data: {
    month: string
    userId: string
    attendanceList: Tiktrac.AttendanceList
  }) {
    const month = this.getTimetracking[data.month]
    const timetracking = month[data.userId] as Tiktrac.Timetracking
    timetracking.attendanceList = { ...data.attendanceList }
  }

  @Mutation
  public SET_ATTENDANCE_STATE(state: AttendanceState) {
    this.attendanceState = state
  }

  @Action
  async checkIn(): Promise<boolean> {
    // check if there already is an active timer
    if (this.getAttendanceState === AttendanceState.ACTIVE) {
      this.context.dispatch(
        'UI/showSnackbar',
        {
          text: `${i18N.t(
            'ticktrack.A_presence_timer_is_already_active!_Please_end_this_first'
          )}`,
          type: 'warning',
        },
        { root: true }
      )
      return false
    }

    // create new issue
    const { issueId, now } = generateIssueID()

    const issue: Tiktrac.AttendanceListElem = {
      id: issueId,
      start: now,
      end: now,
      split: 0,
      split_count: 0,
    }

    const data = {
      intent: 'checkin',
      entry: issue,
    }

    const { curMonth, curUserId } = this.context.rootGetters['Timetracking/getCurrent']

    const response: AxiosResponse = await Vue.prototype.$http
      .post(`tiktrac/attendances/${curMonth}/${curUserId}/${issueId}/`, data)
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          // conflict
          this.context.dispatch(
            'UI/showSnackbar',
            {
              text: `${i18N.t('ticktrack.ticktrackMini.snackBars.checkoutError')}`,
              type: 'error',
            },
            { root: true }
          )
        } else {
          // error
          this.context.dispatch(
            'UI/showSnackbar',
            {
              text: `${i18N.t('ticktrack.ticktrackMini.snackBars.checkoutTimeError')}`,
              type: 'error',
            },
            { root: true }
          )
        }
      })
    if (!response) {
      return false
    }
    // set data
    this.context.commit(
      'Timetracking/SET_TIMETRACKING',
      {
        month: curMonth,
        userId: curUserId,
        timetracking: response.data.timetracking,
      },
      { root: true }
    )
    this.context.commit('SET_ATTENDANCE_STATE', AttendanceState.ACTIVE)
    return true
  }

  @Action({ rawError: true })
  async checkOut(): Promise<Tiktrac.Response> {
    const data = {
      intent: 'checkout',
      time: Date.now(),
    }
    const issueId = this.getAttendanceActiveTimer || 'none'
    const response: AxiosResponse = await Vue.prototype.$http
      .put(`tiktrac/attendances/${issueId}/`, data)
      .catch((error: AxiosError) => {
        return {
          error: error,
          responseCode: error.response?.status || 0,
        }
      })
    if (!response) {
      return {
        error: 'Internal Server Error',
        responseCode: 500,
      }
    }
    // set data
    this.context.commit(
      'Timetracking/SET_TIMETRACKING',
      {
        month: response.data.month,
        userId: response.data.user,
        timetracking: response.data.timetracking,
      },
      { root: true }
    )
    this.context.commit('SET_ATTENDANCE_STATE', AttendanceState.INACTIVE)
    return { responseCode: 200 }
  }

  @Action({ rawError: true })
  async doUpdate(data: {
    issueId: string
    entry: Tiktrac.AttendanceListElemPartial
  }): Promise<Tiktrac.Response> {
    const issueId = data.issueId || 'none'
    const { curUserId } = this.context.rootGetters['Timetracking/getCurrent']
    data['intent'] = 'update'
    const response: AxiosResponse = await Vue.prototype.$http
      .put(`tiktrac/attendances/${issueId}/`, data)
      .catch((error: AxiosError) => {
        return {
          error: error,
          responseCode: error.response?.status || 0,
        }
      })
    if (!response) {
      return {
        error: 'Internal Server Error',
        responseCode: 500,
      }
    }
    // set data
    this.context.commit(
      'Timetracking/SET_TIMETRACKING',
      {
        month: response.data.month,
        userId: response.data.user,
        timetracking: response.data.timetracking,
      },
      { root: true }
    )
    return { responseCode: 200 }
  }

  @Action({ rawError: true })
  async doDeleteIssue(data: { issueId: string }): Promise<Tiktrac.Response> {
    const issueId = data.issueId || 'none'
    const response: AxiosResponse = await Vue.prototype.$http
      .delete(`tiktrac/attendances/${issueId}/`)
      .catch((error: AxiosError) => {
        return {
          error: error,
          responseCode: error.response?.status || 0,
        }
      })
    if (!response) {
      return {
        error: 'Internal Server Error',
        responseCode: 500,
      }
    }
    const { curMonth } = this.context.rootGetters['Timetracking/getCurrent']
    // set data
    if (response.data.month === curMonth) {
      this.context.commit(
        'Timetracking/SET_TIMETRACKING',
        {
          month: response.data.month,
          userId: response.data.user,
          timetracking: response.data.timetracking,
        },
        { root: true }
      )
    }
    return { responseCode: 200 }
  }
}
export default Attendance
