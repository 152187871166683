
import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DailyPlanner from '@/components/calendar/DailyPlanner.vue'
import DailyTaskList from '@/components/lists/DailyTaskList.vue'
import LayoutTwoColumns from '@/components/ui/LayoutTwoColumns.vue'
import ProjectList from '@/components/lists/ProjectList.vue'
import QuickTaskCreator from '@/components/forms/QuickTaskCreator.vue'
import QuickProjectCreator from '@/components/forms/QuickProjectCreator.vue'
import TaskDialog from '@/components/dialogs/TaskDialog.vue'

import { IDialog, ITask } from '@/interfaces'

import { CalendarModule } from '@/store/modules/calendarModule'
import { ProjectsModule } from '@/store/modules/projectsModule'
import { TimetrackingState } from '@/store/modules/tiktrac/timetracking'
import { UserModule } from '@/store/modules/userModule'

const StoreTiktrac = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({
  components: {
    DailyPlanner,
    DailyTaskList,
    LayoutTwoColumns,
    ProjectList,
    QuickTaskCreator,
    QuickProjectCreator,
    TaskDialog,
  },
})
export default class Home extends Vue {
  @StoreTiktrac.Getter
  public getState!: TimetrackingState
  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  @Ref('dialogRef') dialogRef!: TaskDialog

  get userName(): string {
    return UserModule.getUserName
  }
  get tasksWithTimeTracking() {
    return Object.values(CalendarModule.tasksWithTimeTracking)
  }

  @Watch('getState')
  newTaskCheck(state: TimetrackingState, oldState: TimetrackingState) {
    if (state !== oldState) {
      CalendarModule.fetchTasksWithTimetracking()
    }
  }

  created() {
    CalendarModule.fetchTasksWithTimetracking()
    ProjectsModule.fetchAllTasks()
  }
}
