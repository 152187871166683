
import { UserModule } from '@/store/modules/userModule'
import { Component, Vue } from 'vue-property-decorator'

import { IUserData } from '@/interfaces'

@Component({
  components: {},
})
export default class AppBar extends Vue {
  value = false
  get userInfo(): IUserData {
    return UserModule.getUserInfo
  }
  get imagePath() {
    return UserModule.getProfilePicture
  }
}
