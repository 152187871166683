import { render, staticRenderFns } from "./NotificationList.vue?vue&type=template&id=4cefc0b7&scoped=true&"
import script from "./NotificationList.vue?vue&type=script&lang=ts&"
export * from "./NotificationList.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationList.vue?vue&type=style&index=0&id=4cefc0b7&prod&lang=scss&"
import style1 from "./NotificationList.vue?vue&type=style&index=1&id=4cefc0b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cefc0b7",
  null
  
)

export default component.exports