import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=59cc6d56&scoped=true&"
import script from "./ProjectList.vue?vue&type=script&lang=ts&"
export * from "./ProjectList.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=59cc6d56&prod&scoped=true&lang=scss&"
import style1 from "./ProjectList.vue?vue&type=style&index=1&id=59cc6d56&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59cc6d56",
  null
  
)

export default component.exports