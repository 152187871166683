
import { Component, Vue } from 'vue-property-decorator'
import BaseButton from '@/components/ui/BaseButton.vue'

@Component({
  components: {
    BaseButton,
  },
})
export default class EULADialog extends Vue {
  redirectToHome() {
    window.location.href = 'https://arttacsolutions.de'
  }
  acceptedEULA() {
    localStorage.setItem('acceptedEULA', new Date().toISOString())
    this.$emit('accept', true)
  }
}
