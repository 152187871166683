/**
 * Use this for custom component props.
 *
 * Custom component:
 * ```
 * <script>
 * class Custom extends Vue {
 *   @Prop({ type: [String, Boolean] })
 *   readonly customProp?: string | boolean
 *
 *   get isCustomProp() {
 *     return isProp(this.customProp)
 *   }
 * }
 * </script>
 * ```
 *
 * Use custom component and activate `customProp`:
 * ```
 * <template>
 *   <Custom
 *     custom-prop
 *   />
 * </template>
 * ```
 */
export function isProp(prop: string | boolean | undefined) {
  return prop !== undefined && (prop === '' || prop === 'true' || prop === true)
}
