
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class BaseSnackbar extends Vue {
  @Prop({ default: '' }) type?: string
  @Prop() value? = false

  show = this.value
  timeout = -1
  dismissible = true

  @Watch('show')
  emitShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.$emit('input', val)
    if (!val) return
    // set timeout according to type
    if (this.type === 'warning' || this.type === 'error') {
      this.dismissible = true
      this.timeout = 10000
    } else {
      this.dismissible = false
      this.timeout = 3000
    }
  }

  @Watch('value')
  toggleShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.show = val
  }
}
