
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import type Tiktrac from 'vue/types/tiktrac'
import { AttendanceListElem } from 'vue/types/tiktrac'

const StoreAttendance = namespace('Attendance')

@Component
export default class AttendanceBar extends Vue {
  @StoreAttendance.Getter
  public getAttendanceList!: Tiktrac.Timetracking

  @StoreAttendance.Getter
  public getAttendanceToday!: AttendanceListElem | null

  @StoreAttendance.Action
  public doUpdate!: (data: {
    issueId: string
    entry: Tiktrac.AttendanceListElemPartial
  }) => Promise<Tiktrac.Response>

  barHeight = 0
  startCoordinate = 0
  barDisplay = 'none'

  calendarMinutesSum = 1080

  isAttendanceListLoaded = false
  attendance: AttendanceListElem | null = null

  isExpandingTop = false
  isExpandingBottom = false

  dayStart = new Date()

  mounted() {
    this.setDayStart()
    this.attendance = this.$deepCopy(this.getAttendanceToday)
  }

  @Watch('getAttendanceList')
  logTimetracking() {
    this.attendance = this.$deepCopy(this.getAttendanceToday)
  }

  @Watch('attendance')
  toggleLoaded() {
    if (this.attendance) {
      this.toggleAttendanceState()
    }
  }

  @Watch('isAttendanceListLoaded')
  createBar() {
    if (this.attendance) {
      this.calculateAttendance(this.attendance.start, this.attendance.end)
    }
  }

  doAttendanceUpdate() {
    if (this.attendance != null) {
      const data = {
        issueId: this.attendance.id,
        entry: this.attendance,
      }

      this.doUpdate(data)
        .then()
        .catch(() => {
          this.attendance = this.$deepCopy(this.getAttendanceToday)
        })
    }
  }

  expandTop() {
    this.isExpandingTop = true
  }

  extendTop(value) {
    if (this.attendance != null) {
      this.attendance.start += value
      this.calculateAttendance(this.attendance.start, this.attendance.end)
    }
  }

  extendBottom(value) {
    if (this.attendance != null) {
      this.attendance.end += value
      this.calculateAttendance(this.attendance.start, this.attendance.end)
    }
  }

  expandBottom() {
    this.isExpandingBottom = true
  }

  setDayStart() {
    this.dayStart.setMilliseconds(0)
    this.dayStart.setMinutes(0)
    this.dayStart.setSeconds(0)
    this.dayStart.setHours(5)
  }

  toggleAttendanceState() {
    this.$nextTick(() => {
      this.isAttendanceListLoaded = !this.isAttendanceListLoaded
    })
  }

  calculateAttendance(start, end) {
    this.$nextTick(() => {
      let attendanceStart = new Date(start)
      let attendanceEnd = new Date(end)

      let minuteLength =
        attendanceEnd.getDate() * 24 * 60 +
        attendanceEnd.getHours() * 60 +
        attendanceEnd.getMinutes() -
        (attendanceStart.getDate() * 24 * 60 +
          attendanceStart.getHours() * 60 +
          attendanceStart.getMinutes())
      this.barHeight = (minuteLength / 1080) * 100

      let minutesOffset =
        attendanceStart.getHours() * 60 +
        attendanceStart.getMinutes() -
        (this.dayStart.getHours() * 60 + this.dayStart.getMinutes())
      this.startCoordinate = (minutesOffset / 1080) * 100
      this.barDisplay = 'block'
    })
  }
}
