import Vue, { PluginObject } from 'vue'
import Keycloak, { KeycloakConfig, KeycloakInstance } from 'keycloak-js'

const options: KeycloakConfig = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM || '',
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT || '',
}

const _keycloak = Keycloak(options)

const KCPlugin: PluginObject<KeycloakInstance> = {
  install: (Vue) => {
    Vue.prototype.$keycloak = _keycloak
  },
}

KCPlugin.install = (Vue) => {
  Vue.prototype.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      },
    },
  })
}

Vue.use(KCPlugin)

export default KCPlugin
