
import { Component, Prop, Vue } from 'vue-property-decorator'
import { isProp } from '@/services/common'

@Component
export default class ChatBotBtn extends Vue {
  // @Prop({ default: 'primary' }) private color!: string
  @Prop() data!: any

  buttonSendAction(payload) {
    this.$emit('sendPayloadToRasa', payload)
  }
}
