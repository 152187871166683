
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DailyBaseComponent from './DailyBaseComponent.vue'
import EisenHoverMatrixDialog from '@/components/dialogs/EisenHoverMatrixDialog.vue'
import { generateIssueID, setIssueEndToNow } from '@/services/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'

import type Tiktrac from 'vue/types/tiktrac'
import { IProject, ISnackbar, ITask } from '@/interfaces'
import { CalendarDayBodySlotScope } from 'vuetify'
import type { VForm } from 'vue/types/extend'
import AttendanceBar from './AttendanceBar.vue'
import store from '@/store'

const StoreTimetracking = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({ components: { AttendanceBar, EisenHoverMatrixDialog } })
export default class DailyPlanner extends DailyBaseComponent {
  @Ref('form')
  readonly form!: VForm

  @Ref()
  readonly EisenHoverMatrixDialogRef!: EisenHoverMatrixDialog

  @Ref()
  readonly calendar!: any

  @StoreTimetracking.Getter
  public getBusyList!: Tiktrac.BusyList

  @StoreTimetracking.Getter
  public getActiveTimer!: string

  @StoreTimetracking.Getter
  public getCurrent!: { curUserId: string; curMonth: string; isAdmin: boolean }

  @StoreTimetracking.Getter
  public getIsInitializing!: boolean

  @StoreTimetracking.Action
  public doAddIssue!: (data: {
    entry: Tiktrac.BusyListElem
  }) => Promise<Tiktrac.Response>

  @StoreTimetracking.Action
  public doDeleteIssue!: (data: {
    issueId: string
    msSeriesMasterId?: string
  }) => Promise<Tiktrac.Response>

  @StoreTimetracking.Action
  public doUpdate!: (data: {
    issueId: string
    entry: Tiktrac.BusyListElemPartial
  }) => Promise<Tiktrac.Response>

  hideHeader = true
  ready = false
  selectedOpen = false
  selectedEvent = null
  busyList: Tiktrac.BusyList = {}
  taskList: Array<ITask> = []
  tasks: Array<ITask> = []

  firstPoint: number = 0
  moveTime: number = 0
  moveTimeInterval: number = 0
  isDragging: Boolean = false
  isExpanding: Boolean = false
  timetrackingCollision: Boolean = false

  menuActivator = {}
  selProjects: Array<any> = []
  selectedProject = 0
  selTasks: Array<any> = []
  selectedTaskName: string | Tiktrac.LooseObject = ''
  rulesName = [(v) => !!v || `${this.$t('ticktrack.forms.required')}`]
  attendancebarRef: AttendanceBar | any = {}

  updatedEntry = {
    issueId: '',
    entry: {
      end: 0,
      start: 0,
    },
  }

  newEvent: Tiktrac.BusyListElem = {
    id: '',
    start: 0,
    end: 0,
    pause: 0,
    status: 1,
    status_descr: '',
    split: 0,
    split_count: 0,
    project: 0,
    task: 0,
    taskName: '',
    taskCompleted: false,
  }
  get isDailyPlannerLoading() {
    return this.getIsInitializing
  }

  get projects() {
    return ProjectsModule.getProjects
  }
  get getTaskList() {
    return ProjectsModule.getTasks
  }

  get getProjectsArray() {
    let projects: Array<IProject> = []
    for (let project in ProjectsModule.getProjects) {
      projects.push(ProjectsModule.getProjects[project])
    }
    return projects
  }

  get events() {
    let hideHeader = true
    const now = new Date()
    const d = new Date()
    // get first millisecond of current day
    const dayStart = d.setHours(0, 0, 0, 0)
    // get last millisecond of current day
    d.setDate(d.getDate() + 1)
    const dayEnd = d.setMilliseconds(d.getMilliseconds() - 1)
    // only include timetracking from current day
    const timetracking = Object.values(this.busyList).filter((e) => {
      const is_today =
        (e.start >= dayStart && e.start <= dayEnd) ||
        (e.end >= dayStart && e.end <= dayEnd)
      if (!is_today) {
        return false
      }
      if (e.is_all_day) {
        const start = new Date(e.start)
        if (now.getUTCDate() !== start.getUTCDate()) {
          return false
        }
        hideHeader = false
      }
      return true
    })
    this.hideHeader = hideHeader
    // parse to event objects
    return timetracking.map((item) => {
      // clone the item, because we don't want to manipulate the store
      const issue = { ...item }
      issue['allDay'] = issue['is_all_day'] || false
      issue['timed'] = !issue['allDay']
      issue['name'] = ProjectsModule.getTasks[item.task]?.name || ''
      if (issue.id === this.getActiveTimer) {
        setIssueEndToNow(issue)
      }
      return issue
    })
  }

  get cal(): any | null {
    return this.ready ? this.calendar : null
  }

  get nowY() {
    return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
  }

  @Watch('getBusyList')
  onBusyListUpdate(busyList: Tiktrac.BusyList) {
    // we need a local copy to manipulate the values without changing the store
    this.busyList = this.$deepCopy(busyList)
  }

  @Watch('projects')
  projectsUpdated(projects) {
    if (!projects) return
    this.getSelProjectList()
  }

  @Watch('getIsInitializing', { immediate: true })
  init(isInitializing) {
    if (isInitializing) {
      return
    }

    this.$nextTick(() => {
      this.ready = true
      this.scrollToTime()
      this.updateTime()
      this.updateActiveEvent()
      this.mountAttendanceBarNode()
      ProjectsModule.fetchProjects().then(() => {
        this.getSelProjectList()
        this.getSelTaskList()
      })
    })
  }

  @Watch('getTaskList')
  initTaskList() {
    this.tasks = Object.values(this.$deepCopy(this.getTaskList))
  }

  @Watch('hideHeader')
  remountAttendanceBarNode() {
    this.unmountAttendanceBarNode()
    this.mountAttendanceBarNode()
  }

  mounted() {
    this.busyList = this.$deepCopy(this.getBusyList)
  }

  endIntervalDrag() {
    this.attendancebarRef.isExpandingTop = false
    this.attendancebarRef.isExpandingBottom = false
    this.moveTimeInterval = 0
    this.attendancebarRef.doAttendanceUpdate()
  }

  moveInterval(e) {
    this.moveTimeInterval = this.toTime(e)
    if (this.attendancebarRef.isExpandingTop) {
      let delta =
        this.roundTime(this.moveTimeInterval) - this.attendancebarRef.attendance.start
      this.attendancebarRef.extendTop(delta + 600000)
    }

    if (this.attendancebarRef.isExpandingBottom) {
      let delta =
        this.roundTime(this.moveTimeInterval) - this.attendancebarRef.attendance.end
      this.attendancebarRef.extendBottom(delta - 300000)
    }
  }

  mountAttendanceBarNode() {
    this.$nextTick(() => {
      let dailyPlannerIntervals = document.querySelector(
        '.v-calendar-daily__intervals-body'
      ) as HTMLElement
      dailyPlannerIntervals.style.position = 'relative'
      let attendanceBar = document.createElement('div')
      attendanceBar.setAttribute('id', 'attendance-bar-daily-planner')
      dailyPlannerIntervals.prepend(attendanceBar)

      this.attendancebarRef = new AttendanceBar({
        el: '#attendance-bar-daily-planner',
        store: store,
        parent: this,
      })
    })
  }

  unmountAttendanceBarNode() {
    this.$nextTick(() => {
      const attendaceBar = document.querySelector('.v-attendance-bar')
      attendaceBar?.remove()
    })
  }

  timestampToString(timestamp: number) {
    return Vue.prototype.$moment(timestamp).format('HH:mm')
  }

  calendarDragHandler(event) {
    this.updatedEntry.entry = event
    this.updatedEntry.issueId = event.id
    this.firstPoint = this.moveTime
    this.isDragging = true
  }

  extendBottom(event) {
    this.updatedEntry.issueId = event.id
    this.updatedEntry.entry = event
    this.isExpanding = true
  }

  updateSelProjects(projectId: number) {
    this.selectedProject = projectId
    // update select task list
    this.getSelTaskList()
    // If the user choosed an existing task from the list and then changed the project,
    // set a flag that causes the creation of a new task.
    if (typeof this.selectedTaskName === 'string') {
      return
    }
  }

  getSelTaskList() {
    this.selTasks = []
    for (const t of Object.values(ProjectsModule.getTasks) as Array<ITask>) {
      if (this.selectedProject && t.project !== this.selectedProject) {
        continue
      }
      if (t.id === this.getActiveTaskId) {
        // skip tasks where the timetracking is active
        continue
      }
      if (t.is_completed) {
        // skip finished tasks
        continue
      }
      const projectName = ProjectsModule.getProjects[t.project || 0]?.name
      this.selTasks.push({
        projectName: projectName,
        projectId: t.project,
        text: t.name,
        value: t.id,
      })
    }
  }

  getSelProjectList() {
    this.selProjects = [
      {
        text: `${this.$t('ticktrack.ticktrackMini.getSelProjectList')}`,
        value: 0,
      },
    ]
    for (const p of Object.values(ProjectsModule.getProjects)) {
      this.selProjects.push({
        text: p.name,
        value: p.id,
      })
    }
  }

  updateSelectedProject(item: string | Tiktrac.LooseObject) {
    // If the user choosed an existing task from the list,
    // auto select the appropriate project.
    if (typeof item === 'string') {
      return
    }
    this.selectedProject = item.projectId || 0
  }

  mouseMove(tms) {
    this.moveTime = this.toTime(tms)

    if (this.isDragging) {
      let delta = this.moveTime - this.firstPoint
      let start = this.updatedEntry.entry.start + delta
      let end = this.updatedEntry.entry.end + delta

      this.busyList[this.updatedEntry.issueId].start = this.roundTime(start)
      this.busyList[this.updatedEntry.issueId].end = this.roundTime(end)
    }

    if (this.isExpanding) {
      if (this.updatedEntry.issueId) {
        if (this.busyList[this.updatedEntry.issueId].start < this.moveTime) {
          this.busyList[this.updatedEntry.issueId].end = this.roundTime(this.moveTime)
        } else if (this.busyList[this.updatedEntry.issueId].start > this.moveTime) {
          this.busyList[this.updatedEntry.issueId].end =
            this.updatedEntry.entry.start + 300000 // prevents reverse extending
        }
      }
    }
  }

  endDrag() {
    if (this.updatedEntry.issueId) {
      this.updatedEntry.entry = this.busyList[this.updatedEntry.issueId]
      this.doUpdate(this.updatedEntry)
        .catch((e) => {
          this.busyList = this.$deepCopy(this.getBusyList)
        })
        .finally(() => {
          this.updatedEntry = {
            issueId: '',
            entry: {
              end: 0,
              start: 0,
            },
          }
          this.firstPoint = 0
        })
      this.isDragging = false
      this.isExpanding = false
    }
  }

  dropTaskFromTaskList(event) {
    if (event.target.classList.value === 'v-calendar-daily__day-interval') {
      const taskId = event.dataTransfer.getData('taskId')
      const task = this.getTaskList[taskId]
      const calendarHead = document.querySelector(
        '.v-calendar-daily__head'
      ) as HTMLElement
      const headHeight = calendarHead ? calendarHead.offsetHeight : 0
      const calendarHeight = this.calendar.$el.offsetHeight - headHeight
      const amountOfHoursInCalendar = 18
      const intervalOffset = event.target.offsetTop
      // x - hour where interval starts
      // calendarHeight / 18 ==intervalOffset /  x
      // x == 18 * intervalOffset / calendarHeight

      let minutes =
        (amountOfHoursInCalendar * 60 * intervalOffset) / calendarHeight + 5 * 60 // + the hour calendar starts from times minutes in one hour

      if (
        event.clientY - event.target.getBoundingClientRect().top >
        event.target.clientHeight / 2
      ) {
        minutes += 30
      }
      let now = new Date()

      let issueStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0,
        0
      ).getTime()

      issueStart += minutes * 60000

      let issueEnd = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0,
        0
      ).getTime()

      issueEnd += (minutes + 30) * 60000

      const { issueId } = generateIssueID()

      const entry = {
        id: issueId,
        start: issueStart,
        end: issueEnd,
        pause: 0,
        status: 1,
        status_descr: '',
        split: 0,
        split_count: 0,
        project: task.project,
        task: task.id,
        taskName: task.name,
        taskCompleted: false,
      }
      event.target.style.background = 'none'
      this.saveTimetracking(entry)
    }
  }

  dragOverHandler(e) {
    if (e.target.classList.value === 'v-calendar-daily__day-interval') {
      if (
        e.clientY - e.target.getBoundingClientRect().top >
        e.target.clientHeight / 2
      ) {
        e.target.style.background = 'linear-gradient(180deg, #f9f9f9 50%, #abc5c5 50%)'
      } else {
        e.target.style.background = 'linear-gradient(180deg, #abc5c5 50%, #f9f9f9 50%)'
      }
    }
  }

  dragLeaveHandler(e) {
    if (e.target.classList.value === 'v-calendar-daily__day-interval') {
      e.target.style.background = 'none'
    }
  }

  createNewEvent(tms: CalendarDayBodySlotScope) {
    const time = this.getTimeSlot(tms)
    const { issueId } = generateIssueID()

    this.newEvent.start = time.start
    this.newEvent.end = time.end
    this.newEvent.id = issueId
  }

  resetNewEvent() {
    this.newEvent = {
      id: '',
      start: 0,
      end: 0,
      pause: 0,
      status: 1,
      status_descr: '',
      split: 0,
      split_count: 0,
      project: 0,
      task: 0,
      taskName: '',
      taskCompleted: false,
    }
    this.taskList = []
    this.menuActivator = {}
  }

  cancelMenuEvent() {
    this.selectedOpen = false
    this.resetNewEvent()
    this.selectedProject = 0
    this.selectedTaskName = ''
    this.form.reset()
  }

  confirmMenuEvent(e) {
    e.preventDefault()
    const input = this.$refs['selectedTaskName'] as HTMLElement
    input.blur()
    const event: Tiktrac.BusyListElem = this.$deepCopy(this.newEvent)
    this.$nextTick(() => {
      if (!this.form.validate()) {
        return
      }
      if (typeof this.selectedTaskName !== 'string') {
        this.newEvent.task = this.selectedTaskName.value
        this.newEvent.project = this.selectedProject
        this.newEvent.taskName = this.selectedTaskName.text
        this.saveTimetracking(this.newEvent)
      } else {
        let newTask: ITask = {
          id: 0,
          name: this.selectedTaskName,
          project: this.selectedProject,
          description: '',
          urgency: 0,
          is_completed: false,
          importance: 0,
          created: '',
          tags: [],
          notes: {},
        }
        ProjectsModule.addTaskAction({
          projectId: this.selectedProject,
          task: newTask,
        }).then((res) => {
          event.task = res.id
          event.project = res.project
          this.saveTimetracking(event)
        })
      }
      this.cancelMenuEvent()
    })
  }

  saveTimetracking(entry) {
    this.doAddIssue({
      entry: entry,
    })
      .then(() => {
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.createSuccess'
          )}`,
          type: 'success',
        })
      })
      .catch((error: Tiktrac.Response) => {
        if (error.responseCode === 409) {
          // conflict
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.trackingHistory.snackBars.timesCollideError'
            )}`,
            type: 'error',
          })
        } else {
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.trackingHistory.snackBars.updateError'
            )}`,
            type: 'error',
          })
        }
      })
    this.resetNewEvent()
  }

  openMenu(e) {
    if (
      e.target._prevClass === 'v-calendar-daily__day-interval' &&
      !this.selectedOpen
    ) {
      this.selectedOpen = true
      this.menuActivator = e.target
    }
  }

  roundTime(time, down = true) {
    const roundTo = 5 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
      ? time - (time % roundDownTime)
      : time + (roundDownTime - (time % roundDownTime))
  }

  getTimeSlot(tms: CalendarDayBodySlotScope) {
    if (tms.minute >= 30) {
      tms.minute = 30
    } else {
      tms.minute = 0
    }
    return { start: this.toTime(tms), end: this.toTime(tms) + 1800000 }
  }

  toTime(tms: CalendarDayBodySlotScope) {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
  }

  isActiveEvent(id: string) {
    return id === this.getActiveTimer
  }

  getCurrentTime() {
    return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
  }

  getTaskByEvent(event): ITask {
    return ProjectsModule.getTasks[event.task]
  }

  scrollToTime() {
    const time = this.getCurrentTime()
    const first = Math.max(0, time - (time % 30) - 30)

    this.cal.scrollToTime(first)
  }

  updateActiveEvent() {
    setInterval(() => {
      if (!this.getActiveTimer) {
        return
      }
      // clone the active issue
      // clone is necessary. otherwise we would manipulate the store
      const issue = { ...this.busyList[this.getActiveTimer] }
      if (!issue || !Object.keys(issue).length) {
        return
      }
      // update issue
      setIssueEndToNow(issue)
      this.busyList[this.getActiveTimer] = issue
    }, 60 * 1000)
  }

  updateTime() {
    setInterval(() => this.cal.updateTimes(), 60 * 1000)
  }

  deleteTask(selectedEvent) {
    // ask user if he really wants to perfom this action
    this.showDialog({
      text: `${this.$t('calendar.dailyPlanner.snackBars.deleteTaskTitle')}`,
    }).then((response: boolean) => {
      if (!response) {
        // declined
        return
      }
      // delete task
      if (selectedEvent.start && selectedEvent.start === selectedEvent.end) {
        this.showSnackbar({
          text: `${this.$t('calendar.dailyPlanner.snackBars.deleteTaskError')}`,
          type: 'error',
        })
      } else {
        if (selectedEvent.ms_series_master_id) {
          this.showDialog({
            text: `${this.$t('dialogs.this_event_is_part_of_event_series')}`,
          }).then((response) => {
            if (response) {
              this.doDeleteIssue({
                issueId: selectedEvent.id,
                msSeriesMasterId: selectedEvent.ms_series_master_id,
              })
            } else {
              this.doDeleteIssue({ issueId: selectedEvent.id })
            }
          })
        } else {
          this.doDeleteIssue({ issueId: selectedEvent.id })
        }
      }
    })
  }

  openEisenhoverDialog() {
    this.EisenHoverMatrixDialogRef.createDialog()
  }

  amountTask(priorityUrgency: number, priorityImportance: number) {
    const result = this.tasks.filter(
      (task) =>
        task.urgency === priorityUrgency &&
        task.importance === priorityImportance &&
        !task.is_completed
    )

    return result.length
  }
}
