
import NavigationDrawerContent from './NavigationDrawerContent.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    NavigationDrawerContent,
  },
})
export default class NavigationDrawerOverall extends Vue {
  value = false

  getDrawerIcon() {
    return this.value ? 'mdi-close' : 'mdi-menu'
  }
}
