
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DatePicker from '@/components/forms/DatePicker.vue'
import TimePicker from '@/components/forms/TimePicker.vue'
import InlineEdit from '@/components/forms/InlineEdit.vue'
import DialogContent from '@/components/dialogs/DialogContent.vue'
import TrackingEntry from '@/components/tiktrac/forms/TrackingEntry.vue'

import {
  decimalToHours,
  formatterDecimalNumber,
  toISODateString,
  toFixedNumber,
} from '@/services/datetime'

import type Tiktrac from 'vue/types/tiktrac'
import { IDialog, ISnackbar, ITask } from '@/interfaces'
import { generateIssueID } from '@/services/tiktrac'

const StoreTiktrac = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({
  components: {
    InlineEdit,
    DatePicker,
    TimePicker,
    TrackingEntry,
    DialogContent,
  },
})
export default class TaskTimetrackingList extends Vue {
  @Prop()
  readonly task!: ITask

  @Prop()
  readonly highlightedIssueId!: string

  @StoreTiktrac.Action
  public doAddIssue!: (data: {
    entry: Tiktrac.BusyListElem
  }) => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public doUpdate!: (data: {
    issueId: string
    entry: Tiktrac.BusyListElemPartial
  }) => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public doDeleteIssue!: (data: {
    issueId: string
    msSeriesMasterId?: string
  }) => Promise<Tiktrac.Response>

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  @StoreTiktrac.Getter
  public getActiveTimer!: string

  isAddingNewIssue = false
  isAddingNewIssueProcess = false
  dialog = false
  busyListElemId = ''
  itemsPerPage = 10
  page = 1
  updateProcess = false
  loading = false
  newIssue = {
    dates: [],
    start: 0,
    end: 0,
    pause: 0,
  }
  issues: Tiktrac.BusyList = {}

  // validation
  get numberRules() {
    return [
      (v) => !!v || this.$t('ticktrack.tables.taskTimetrackingList.numberRules.enter'),
      (v) =>
        Number.parseFloat(v) >= 0 ||
        this.$t('ticktrack.tables.taskTimetrackingList.numberRules.positive'),
    ]
  }

  get headers() {
    return [
      {
        text: this.$t('tables.taskTimetrackingList.headers.date'),
        value: 'date',
      },
      {
        text: this.$t('tables.taskTimetrackingList.headers.starts'),
        value: 'start',
      },
      {
        text: this.$t('tables.taskTimetrackingList.headers.end'),
        value: 'end',
      },
      {
        text: this.$t('tables.taskTimetrackingList.headers.pause'),
        value: 'pause',
      },
      {
        text: this.$t('tables.taskTimetrackingList.headers.time'),
        value: 'time',
        align: 'end',
      },
      { text: '', value: 'actions' },
    ]
  }

  get getIssues() {
    const issues = [] as Array<any>
    for (const issue of Object.values(this.issues)) {
      const dStart = new Date(issue.start)
      const dEnd = new Date(issue.end)
      const dateStrings = [toISODateString(dStart), toISODateString(dEnd)]
      let time = issue.end - issue.start - issue.pause
      time = time / 1000 / 60 / 60
      issues.push({
        id: issue.id,
        dates: dateStrings,
        start: issue.start,
        end: issue.end,
        pause: issue.pause,
        time: decimalToHours(time),
        task: issue.task,
      })
    }

    issues.sort((a: any, b: any) => {
      return b.start - a.start
    })

    return issues
  }

  @Watch('isAddingNewIssue')
  resetTiktracField(value) {
    if (!value) {
      Object.assign(this.newIssue, this.initialStateOfTiktrac())
    }
  }

  @Watch('task', { immediate: true })
  onTaskLoad(task: ITask, oldTask: ITask) {
    if (task?.id === oldTask?.id) return
    if (!task.id) {
      this.issues = {}
      return
    }
    this.fetchIssues()
  }

  @Watch('getIssues')
  highlightIssue(issues) {
    if (!issues || !issues.length) {
      return
    }
    if (!this.highlightedIssueId) {
      return
    }
    // find issue
    const idx = issues.findIndex((i) => i.id === this.highlightedIssueId)
    if (idx === -1) {
      return
    }
    // calc page that contains the issue
    this.page = Math.ceil((idx + 1) / this.itemsPerPage)
  }

  initialStateOfTiktrac() {
    this.newIssue = {
      dates: [],
      start: 0,
      end: 0,
      pause: 0,
    }
  }

  getAPIissues() {
    this.$http.get(`/tiktrac/timetracking/tasks/${this.task.id}/`).then((response) => {
      this.issues = response.data
      this.loading = false
    })
  }

  fetchIssues() {
    this.issues = {}
    if (!this.task?.id) {
      return
    }
    this.loading = true
    this.getAPIissues()
  }

  calcTimeForIssue(issue, formatFunction: Function | null = decimalToHours) {
    if (formatFunction === null) {
      formatFunction = (a) => a
    }
    if (!issue.start || !issue.end) {
      return formatFunction(0)
    }
    // get date
    let dateStart = new Date()
    let dateEnd = new Date()
    if (issue.dates.length === 2) {
      dateStart = new Date(issue.dates[0])
      dateEnd = new Date(issue.dates[1])
    }
    // get time
    let dateTimeStart = new Date(issue.start)
    let dateTimeEnd = new Date(issue.end)
    // merge date and time
    dateStart.setHours(dateTimeStart.getHours())
    dateStart.setMinutes(dateTimeStart.getMinutes())
    dateEnd.setHours(dateTimeEnd.getHours())
    dateEnd.setMinutes(dateTimeEnd.getMinutes())
    // get pause
    const pause = isNaN(issue.pause) || issue.pause < 0 ? 0 : issue.pause
    // calc time difference
    let diff = dateEnd.getTime() - dateStart.getTime() - pause
    diff = diff / 60 / 60 / 1000
    if (diff < 0) {
      diff = 0
    }
    return formatFunction(diff)
  }

  formatDecimal(number: number): number {
    return toFixedNumber(number)
  }

  hoursToDecimal(hours: string) {
    const idxDelimiter = hours.indexOf(':')
    const h = Number.parseInt(hours.substr(0, idxDelimiter))
    const m = Number.parseInt(hours.substr(idxDelimiter + 1))
    const decimal = h + m / 60
    return formatterDecimalNumber.format(decimal)
  }

  addIssue() {
    this.isAddingNewIssueProcess = true
    // validate issue
    if (!this.validateNewIssue()) {
      this.showSnackbar({
        text: `${this.$t(
          'ticktrack.tables.taskTimetrackingList.snackBars.checkInputError'
        )}`,
        type: 'error',
      })
      this.isAddingNewIssueProcess = false
      return
    }

    const { issueId } = generateIssueID()
    // parse values
    const start = this.mergeDateAndTime(this.newIssue.dates[0], this.newIssue.start)
    const end = this.mergeDateAndTime(this.newIssue.dates[1], this.newIssue.end)
    const pause = this.newIssue.pause * 60 * 60 * 1000
    // create new issue
    const issue: Tiktrac.BusyListElem = {
      id: issueId,
      start: start.getTime(),
      end: end.getTime(),
      pause: pause,
      status: 1, // employee is working
      status_descr: '',
      split: 0,
      split_count: 0,
      project: this.task.project || 0,
      task: this.task.id,
      taskName: this.task.name,
      taskCompleted: this.task.is_completed,
    }
    // save issue
    this.doAddIssue({ entry: issue })
      .then(() => {
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.createSuccess'
          )}`,
          type: 'success',
        })
      })
      .catch((error: Tiktrac.Response) => {
        if (error.responseCode === 409) {
          // conflict
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.taskTimetrackingList.snackBars.timesCollideError'
            )}`,
            type: 'error',
          })
        } else {
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.taskTimetrackingList.snackBars.createError'
            )}`,
            type: 'error',
          })
        }
      })
      .finally(() => {
        this.isAddingNewIssue = false
        this.isAddingNewIssueProcess = false
        this.fetchIssues()
      })
  }

  validateNewIssue() {
    if (!this.calcTimeForIssue(this.newIssue, null)) {
      return false
    }
    if (this.newIssue.dates.length !== 2) {
      return false
    }
    if (this.newIssue.start <= 0 || this.newIssue.end <= 0 || this.newIssue.pause < 0) {
      return false
    }
    return true
  }

  /**
   * Create a new DateTime object setting the
   * year, month and day according to `date` and the
   * time according to `time`.
   *
   * @param date Date string in format like '2022-03-07'
   * @param time Unix timestamp in milliseconds
   */
  mergeDateAndTime(date: string, time: number): Date {
    const dateDate = new Date(date)
    const dateTime = new Date(time)
    // TODO use UTC?
    dateTime.setDate(dateDate.getDate())
    dateTime.setMonth(dateDate.getMonth())
    dateTime.setFullYear(dateDate.getFullYear())

    return dateTime
  }

  updateIssue(issue) {
    if (this.updateProcess) return
    if (!issue || !issue.id) return

    const start = this.mergeDateAndTime(issue.dates[0], issue.start)
    const end = this.mergeDateAndTime(issue.dates[1], issue.end)

    const entry: Tiktrac.BusyListElemPartial = {
      start: start.getTime(),
      end: end.getTime(),
      pause: issue.pause,
    }
    const data = {
      issueId: issue.id,
      entry: entry,
    }
    this.doUpdate(data)
      .then(() => {
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.updateSuccess'
          )}`,
          type: 'success',
        })
      })
      .catch((error: Tiktrac.Response) => {
        if (error.responseCode === 409) {
          // conflict
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.taskTimetrackingList.snackBars.timesCollideError'
            )}`,
            type: 'error',
          })
        } else {
          this.showSnackbar({
            text: `${this.$t(
              'ticktrack.tables.taskTimetrackingList.snackBars.updateError'
            )}`,
            type: 'error',
          })
        }
      })
      .finally(() => {
        this.updateProcess = false
      })
  }

  editIssue(issue: Tiktrac.BusyListElem) {
    this.busyListElemId = issue.id
    this.dialog = true
  }

  async deleteIssue(issue: Tiktrac.BusyListElem) {
    const consent = await this.showDialog({
      text: `${this.$t(
        'ticktrack.tables.taskTimetrackingList.snackBars.deleteDialog'
      )}`,
    })
    if (!consent) {
      return
    }

    const data = {
      issueId: issue.id,
    }

    this.doDeleteIssue(data)
      .then(() => {
        // delete issue from local list
        this.$delete(this.issues, issue.id)
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.deleteSuccess'
          )}`,
          type: 'success',
        })
      })
      .catch((error: Tiktrac.Response) => {
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.deleteError'
          )}`,
          type: 'error',
        })
      })
  }

  isCurrentTaskActive(id) {
    if (!id) {
      return
    }

    return this.getActiveTimer === id
  }
  handleCloseDialog() {
    this.dialog = !this.dialog
  }
}
