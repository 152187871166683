import Vue from 'vue'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { generateIssueID, getMonth } from '@/services/tiktrac'

import type Tiktrac from 'vue/types/tiktrac'
import { AttendanceState } from '@/store/modules/tiktrac/attendance'
import { ITask } from '@/interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import i18N from '@/plugins/i18n'
import { getAccessMSToken } from '@/plugins/msal'

export enum TimetrackingState {
  UNKNOWN = -1,
  INACTIVE = 0,
  ACTIVE = 1,
  PAUSED = 2,
}

@Module({ namespaced: true })
class Timetracking extends VuexModule {
  isInitializing = true
  timetrackingState: TimetrackingState = TimetrackingState.UNKNOWN
  timetrackings: Tiktrac.TimetrackingsPerMonth = {}

  get getCurrent(): { curUserId: string; curMonth: string; isAdmin: boolean } {
    const curUserId = this.context.rootGetters['user/getUserID']
    const curMonth = getMonth()
    const isAdmin = this.context.rootGetters['user/isUserAdmin']
    return { curUserId, curMonth, isAdmin }
  }

  get getIsInitializing(): boolean {
    return this.isInitializing
  }

  /**
   * Get timetracking for the current user and the current month
   */
  get getTimetracking(): Tiktrac.Timetracking | Tiktrac.LooseObject {
    const { curMonth, curUserId } = this.getCurrent
    const curTimetrackings = this.timetrackings[curMonth]
    if (!curTimetrackings) {
      return {}
    }
    return curTimetrackings[curUserId] || {}
  }

  /**
   * Get `this.timetrackings`
   */
  get getAllTimetrackings(): Tiktrac.TimetrackingsPerMonth {
    return this.timetrackings
  }

  get getBusyList(): Tiktrac.BusyList {
    return this.getTimetracking.busyList || {}
  }

  get getActiveTaskId(): number {
    return this.getBusyList[this.getActiveTimer]?.task || 0
  }

  get getActiveTimer(): string {
    return this.getTimetracking.properties?.activeTimer || ''
  }

  get getActiveTimerLimit(): number | null {
    return this.getTimetracking.properties?.activeTimerLimit || null
  }

  get getActivePause(): number | null {
    return this.getTimetracking.properties?.activePause || null
  }

  get getActivePauses(): Array<Tiktrac.PausesEntry> {
    return this.getTimetracking.properties?.activePauses || []
  }

  get getPauseTime(): number {
    if (!this.getTimetracking.busyList || !this.getTimetracking.properties) {
      return 0
    }
    const issue =
      this.getTimetracking.busyList[this.getTimetracking.properties.activeTimer] || null
    return issue ? issue.pause : 0
  }

  get getActiveStartTime(): number {
    if (!this.getTimetracking.busyList || !this.getTimetracking.properties) {
      return 0
    }
    const issue =
      this.getTimetracking.busyList[this.getTimetracking.properties.activeTimer] || null
    return issue ? issue.start : 0
  }

  get getState(): TimetrackingState {
    return this.timetrackingState
  }

  @Mutation
  public SET_IS_INITIALIZING(value: boolean) {
    this.isInitializing = value
  }

  @Mutation
  public SET_ALL_TIMETRACKINGS(timetrackings: Tiktrac.TimetrackingsPerMonth) {
    this.timetrackings = { ...timetrackings }
  }

  @Mutation
  public SET_TIMETRACKING(data: {
    month: string
    timetracking: Tiktrac.Timetracking
    userId: string
  }) {
    const timetrackings = {
      ...this.timetrackings[data.month],
    }

    timetrackings[data.userId] = data.timetracking
    Vue.set(this.timetrackings, data.month, timetrackings)
  }

  @Mutation
  public SET_TIMETRACKING_STATE(state: TimetrackingState) {
    this.timetrackingState = state
  }

  @Mutation
  public TOGGLE_PAUSE(data: {
    time: number
    pauseStart: boolean
    timetracking: Tiktrac.Timetracking | Tiktrac.LooseObject
  }) {
    const timetracking = data.timetracking
    if (!Object.keys(timetracking).length) {
      // Abort if there is no current timetracking.
      // This should be not possible, since a pause can
      // only be toggled on an existing timetracking.
      return
    }
    if (data.pauseStart) {
      // pause
      timetracking.properties.activePause = data.time
      timetracking.properties.activePauses.push({
        start: data.time,
        end: 0,
      })
      this.timetrackingState = TimetrackingState.PAUSED
    } else {
      // end
      const startTime = timetracking.properties.activePause || 0
      timetracking.properties.activePause = null
      const activePauses = timetracking.properties.activePauses
      activePauses[activePauses.length - 1].end = data.time
      timetracking.busyList[timetracking.properties.activeTimer].pause +=
        data.time - startTime
      this.timetrackingState = TimetrackingState.ACTIVE
    }
  }

  @Action
  public fetchAllTimetrackings(month: string = ''): Promise<Tiktrac.Response> {
    const { curMonth, curUserId, isAdmin } = this.getCurrent
    if (!month) {
      month = curMonth
    }
    return new Promise((resolve, reject) => {
      if (!isAdmin) {
        reject({
          error: 'Unauthorized',
          responseCode: 401,
        })
      }
      Vue.prototype.$http
        .get(`tiktrac/timetracking/${month}/all/`)
        .then((response: AxiosResponse) => {
          const data = response.data as Array<{
            month: string
            timetracking: Tiktrac.Timetracking
            user: string
          }>
          const allTimetrackings = this.getAllTimetrackings
          for (const user of data) {
            if (!allTimetrackings[user.month]) {
              allTimetrackings[user.month] = {}
            }
            allTimetrackings[user.month][user.user] = user.timetracking
          }
          // set data
          this.context.commit('SET_ALL_TIMETRACKINGS', allTimetrackings)
          // update state
          if (month === curMonth) {
            let timetracking: Tiktrac.Timetracking | undefined = undefined
            try {
              timetracking = allTimetrackings[curMonth][curUserId]
            } catch (_) {
              timetracking = undefined
            }
            if (timetracking) {
              if (timetracking.properties.activeTimer) {
                if (timetracking.properties.activePause) {
                  this.context.commit(
                    'SET_TIMETRACKING_STATE',
                    TimetrackingState.PAUSED
                  )
                } else {
                  this.context.commit(
                    'SET_TIMETRACKING_STATE',
                    TimetrackingState.ACTIVE
                  )
                }
              } else {
                this.context.commit(
                  'SET_TIMETRACKING_STATE',
                  TimetrackingState.INACTIVE
                )
              }
            }
          }
          resolve({
            response: allTimetrackings,
            responseCode: 200,
          })
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 404) {
            resolve({ responseCode: 404 })
          }
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
        .finally(() => {
          this.context.commit('SET_IS_INITIALIZING', false)
        })
    })
  }

  @Action
  public fetchTimetracking(month: string = ''): Promise<Tiktrac.Response> {
    const { curMonth } = this.getCurrent
    if (!month) {
      month = curMonth
    }
    const { issueId } = generateIssueID()
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`tiktrac/timetracking/${month}/?daily_attendance=${issueId}`)
        .then((response: AxiosResponse) => {
          const data = response.data
          // set data
          this.context.commit('SET_TIMETRACKING', {
            month: data.month,
            timetracking: data.timetracking as Tiktrac.Timetracking,
            userId: data.user,
          })
          // update state
          if (month === curMonth) {
            // set busyList properties
            if (data.timetracking.properties.activeTimer) {
              if (data.timetracking.properties.activePause) {
                this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.PAUSED)
              } else {
                this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.ACTIVE)
              }
            } else {
              this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.INACTIVE)
            }
            // set attendanceList properties
            if (data.timetracking.properties.attendanceActiveTimer) {
              this.context.commit(
                'Attendance/SET_ATTENDANCE_STATE',
                AttendanceState.ACTIVE,
                { root: true }
              )
            } else {
              this.context.commit(
                'Attendance/SET_ATTENDANCE_STATE',
                AttendanceState.INACTIVE,
                { root: true }
              )
            }
          }
          resolve({
            response: data.timetracking,
            responseCode: 200,
          })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
        .finally(() => {
          this.context.commit('SET_IS_INITIALIZING', false)
        })
    })
  }

  @Action({ rawError: true })
  public async doAddIssue(data: {
    entry: Tiktrac.BusyListElem
  }): Promise<Tiktrac.Response> {
    const userId = this.context.rootGetters['user/getUserID']
    const month = getMonth(data.entry.start)
    const issueId = data.entry.id || 'none'
    const ms_auth = await getAccessMSToken()
    data['intent'] = 'issueAdd'
    data['ms_auth'] = ms_auth
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`tiktrac/timetracking/${userId}/${month}/${issueId}/`, data)
        .then((response: AxiosResponse) => {
          const data = response.data
          const monthCurrent = getMonth()
          // set data
          if (data.month === monthCurrent) {
            this.context.commit('SET_TIMETRACKING', {
              month: data.month,
              timetracking: data.timetracking,
              userId: userId,
            })
            // TODO check if state active?
            // this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.ACTIVE)
          }
          resolve({ responseCode: 201 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error.message,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }

  @Action
  async checkIn(task: ITask | undefined = undefined): Promise<boolean> {
    // check if there already is an active timer
    if (this.getState === TimetrackingState.ACTIVE) {
      this.context.dispatch(
        'UI/showSnackbar',
        {
          text: `${i18N.t(
            'ticktrack.A_presence_timer_is_already_active!_Please_end_this_first'
          )}`,
          type: 'warning',
        },
        { root: true }
      )
      return false
    }

    // create new issue
    const { issueId, now } = generateIssueID()
    const projectId = task && task.project ? task.project : 0
    const taskId = task ? task.id : 0

    const issue: Tiktrac.BusyListElem = {
      id: issueId,
      start: now,
      end: now,
      pause: 0,
      status: 1, // employee is working
      status_descr: '',
      split: 0,
      split_count: 0,
      project: projectId,
      task: taskId,
    }

    const data = {
      intent: 'checkin',
      entry: issue,
    }

    const userId = this.context.rootGetters['user/getUserID']
    const month = getMonth()

    const response: AxiosResponse = await Vue.prototype.$http
      .post(`tiktrac/timetracking/${userId}/${month}/${issueId}/`, data)
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          // conflict
          this.context.dispatch(
            'UI/showSnackbar',
            {
              text: `${i18N.t('ticktrack.ticktrackMini.snackBars.checkoutError')}`,
              type: 'error',
            },
            { root: true }
          )
        } else {
          // error
          this.context.dispatch(
            'UI/showSnackbar',
            {
              text: `${i18N.t('ticktrack.ticktrackMini.snackBars.checkoutTimeError')}`,
              type: 'error',
            },
            { root: true }
          )
        }
      })
    if (!response) {
      return false
    }
    // set data
    this.context.commit('SET_TIMETRACKING', {
      month: response.data.month,
      timetracking: response.data.timetracking,
      userId: userId,
    })
    this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.ACTIVE)
    return true
  }

  @Action({ rawError: true })
  public doCheckOut(data: {
    intent: string
    issueId: string
    time: number
    project: number
    taskName: string
    taskId: number
  }): Promise<Tiktrac.Response> {
    const { curUserId } = this.getCurrent
    return new Promise((resolve, reject) => {
      const issueId = data.issueId || 'none'
      Vue.prototype.$http
        .put(`tiktrac/timetracking/issues/${issueId}/`, data)
        .then((response: AxiosResponse) => {
          const data = response.data
          // set data
          this.context.commit('SET_TIMETRACKING', {
            month: data.month,
            timetracking: data.timetracking,
            userId: curUserId,
          })
          this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.INACTIVE)
          // TODO update projects and tasks
          resolve({ responseCode: 200 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }

  @Action({ rawError: true })
  public doCheckOutWhenTaskCompleted(task: ITask) {
    const data = {
      intent: 'checkout',
      issueId: this.getActiveTimer,
      time: Date.now(),
      project: task.project || 0,
      taskName: task.name,
      taskId: task.id,
      taskCompleted: task.is_completed,
    }
    this.context.dispatch('Timetracking/doCheckOut', data, {
      root: true,
    })
  }

  @Action({ rawError: true })
  public doPauseStart(data: {
    intent: string
    issueId: string
    time: number
  }): Promise<Tiktrac.Response> {
    return new Promise((resolve, reject) => {
      const issueId = data.issueId || 'none'
      Vue.prototype.$http
        .put(`tiktrac/timetracking/issues/${issueId}/`, data)
        .then((_) => {
          // set data
          this.context.commit('TOGGLE_PAUSE', {
            time: data.time,
            pauseStart: true,
            timetracking: this.getTimetracking,
          })
          resolve({ responseCode: 200 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }

  @Action({ rawError: true })
  public doPauseEnd(data: {
    intent: string
    issueId: string
    time: number
  }): Promise<Tiktrac.Response> {
    return new Promise((resolve, reject) => {
      const issueId = data.issueId || 'none'
      Vue.prototype.$http
        .put(`tiktrac/timetracking/issues/${issueId}/`, data)
        .then((response: AxiosResponse) => {
          // set data
          this.context.commit('TOGGLE_PAUSE', {
            time: data.time,
            pauseStart: false,
            timetracking: this.getTimetracking,
          })
          resolve({ responseCode: 200 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }

  // TODO update function signature
  @Action({ rawError: true })
  public async doUpdate(data: {
    issueId: string
    entry: Tiktrac.BusyListElemPartial
  }): Promise<Tiktrac.Response> {
    const token = await getAccessMSToken()
    return new Promise((resolve, reject) => {
      const issueId = data.issueId || 'none'
      const { curUserId } = this.getCurrent
      data['intent'] = 'update'
      Vue.prototype.$http
        .put(`tiktrac/timetracking/issues/${issueId}/?ms_auth=${token}`, data)
        .then((response: AxiosResponse) => {
          const data = response.data
          // set data
          this.context.commit('SET_TIMETRACKING', {
            month: data.month,
            timetracking: data.timetracking,
            userId: curUserId,
          })
          resolve({ responseCode: 200 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }

  @Action({ rawError: true })
  public async doDeleteIssue(data: {
    issueId: string
    msSeriesMasterId?: string
  }): Promise<Tiktrac.Response> {
    const token = await getAccessMSToken()
    return new Promise((resolve, reject) => {
      const issueId = data.issueId || 'none'
      const msSeriesMasterId = data.msSeriesMasterId || 'none'
      Vue.prototype.$http
        .delete(
          `tiktrac/timetracking/issues/${issueId}/?ms_auth=${token}&ms_series_master_id=${msSeriesMasterId}`
        )
        .then((response: AxiosResponse) => {
          const data = response.data
          const { curMonth, curUserId } = this.getCurrent
          // set data
          if (data.month === curMonth) {
            this.context.commit('SET_TIMETRACKING', {
              month: data.month,
              timetracking: data.timetracking,
              userId: curUserId,
            })
            // TODO check if state active?
            // this.context.commit('SET_TIMETRACKING_STATE', TimetrackingState.ACTIVE)
          }
          resolve({ responseCode: 200 })
        })
        .catch((error: AxiosError) => {
          reject({
            error: error,
            responseCode: error.response?.status || 0,
          })
        })
    })
  }
}
export default Timetracking
