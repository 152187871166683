
import { Component, Vue, VModel, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class DialogContent extends Vue {
  @Prop({ default: false })
  readonly isEdit!: boolean

  @VModel({ default: false }) dialog!: boolean
}
