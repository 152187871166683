
import { IUserFields } from '@/interfaces'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/userModule'
import BaseButton from '@/components/ui/BaseButton.vue'

@Component({
  components: {
    BaseButton,
  },
})
export default class TutorialDialog extends Vue {
  dialog = true
  toggle = 'https://demo.arcade.software/qmMYPVJrUwgTaFqqG4zo?embed'

  videoDialog = false
  completedTutorials: Array<string> = []

  get playButtons() {
    return [
      {
        text: 'How to create a project',
        value: 'https://demo.arcade.software/qmMYPVJrUwgTaFqqG4zo?embed',
      },
      {
        text: 'How to create a task',
        value: 'https://demo.arcade.software/NENtAJk9PoGONbP7A7ss?embed',
      },
      {
        text: 'How to add a new user',
        value: 'https://demo.arcade.software/JNuORe5gvRfBr0c121bA?embed',
      },
    ]
  }

  get userInfo() {
    return UserModule.userInfo
  }

  get allTutorialsFinished() {
    return this.completedTutorials.length === this.playButtons.length
  }

  get isCurrentVideoFinished() {
    return this.completedTutorials.includes(this.toggle)
  }

  get isLastVideo() {
    let length = this.playButtons.length

    return this.toggle === this.playButtons[length - 1]?.value
  }

  @Watch('videoDialog')
  markVideoAsDone() {
    if (!this.completedTutorials.includes(this.toggle)) {
      this.completedTutorials.push(this.toggle)
    }
  }

  closeTutorialDialog() {
    this.dialog = false
    this.toggle = ''
  }

  closeVideoDialog() {
    this.videoDialog = false
  }

  async markTutorialAsRead() {
    let userFields: IUserFields = {
      uid: this.userInfo.uid,
      first_name: this.userInfo.first_name,
      last_name: this.userInfo.last_name,
      role: this.userInfo.role,
      wage: this.userInfo.wage,
      availability: this.userInfo.availability,
      settings: {
        ...this.userInfo.settings,
        tutorial_completed: true,
      },
    }
    await UserModule.updateUserAction(userFields)
  }

  skipTutorial() {
    this.closeTutorialDialog()
    this.markTutorialAsRead()
  }

  startTutorial() {
    this.videoDialog = true
  }

  isVideoCompleted(video) {
    return this.completedTutorials.includes(video)
  }

  nextTutorial() {
    if (!this.completedTutorials.includes(this.toggle)) {
      this.completedTutorials.push(this.toggle)
    }
    this.$nextTick(() => {
      let toggleObject = { text: '', value: '' }
      for (let i of this.playButtons) {
        if (i.value === this.toggle) {
          toggleObject = i
        }
      }
      this.toggle = this.playButtons[this.playButtons.indexOf(toggleObject) + 1].value
    })
  }

  completeTutorial() {
    if (!this.completedTutorials.includes(this.toggle)) {
      this.completedTutorials.push(this.toggle)
    }
    this.closeVideoDialog()
  }
}
