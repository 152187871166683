import Vue from 'vue'

async function updateToken(): Promise<string> {
  await Vue.prototype.$keycloak.updateToken(70)
  return Vue.prototype.$keycloak.token as string
}

function logout() {
  try {
    Vue.prototype.$keycloak.logout()
  } catch (e) {
    console.log(e)
  }
}

function deleteAccount() {
  Vue.prototype.$http.delete('/overall/company/')
    .then(() => {
      logout()
    })
    .catch((e) => console.error({e}))
}

export const authService = {
  deleteAccount,
  logout,
  updateToken,
}
