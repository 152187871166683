import { render, staticRenderFns } from "./ChatBotMenu.vue?vue&type=template&id=f7e976de&scoped=true&"
import script from "./ChatBotMenu.vue?vue&type=script&lang=ts&"
export * from "./ChatBotMenu.vue?vue&type=script&lang=ts&"
import style0 from "./ChatBotMenu.vue?vue&type=style&index=0&id=f7e976de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7e976de",
  null
  
)

export default component.exports