
import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { formatterDecimalNumber } from '@/services/datetime'
import DailyBaseComponent from '@/components/calendar/DailyBaseComponent.vue'
import BaseMenuButton from '@/components/ui/BaseMenuButton.vue'

const StoreTiktrac = namespace('Timetracking')
import { ProjectsModule } from '@/store/modules/projectsModule'

import { ITask } from '@/interfaces'

@Component({
  components: {
    BaseMenuButton,
  },
})
export default class DailyTaskList extends DailyBaseComponent {
  @StoreTiktrac.Getter
  public getActiveTaskId!: number

  @StoreTiktrac.Action
  public checkIn!: (task: ITask | undefined) => Promise<boolean>

  actionProcess = false
  checkedItems = [] // quickfix for checking the next item on delete
  tasksToday = {}

  get isLoading() {
    return !(ProjectsModule.isInitializedTasks && ProjectsModule.isInitializedProjects)
  }

  get taskActions() {
    return [
      {
        title: this.$t('homePage.dailyTaskList.actions.checkin'),
        icon: 'mdi-clock',
        action: this.checkIn,
      },
      {
        title: this.$t('homePage.dailyTaskList.actions.edit'),
        icon: 'mdi-pencil',
        action: this.editTask,
      },
      {
        title: this.$t('homePage.dailyTaskList.actions.delete'),
        icon: 'mdi-delete',
        action: this.deleteTask,
      },
    ]
  }

  get tasksToDo(): ITask[] {
    if (!ProjectsModule.isInitializedProjects) {
      // dependency not met. pass an empty array
      return []
    }

    return Object.values(ProjectsModule.getTasks).filter((task) => !task.is_completed)
  }

  get getTaskListDone() {
    if (!ProjectsModule.isInitializedProjects) {
      // dependency not met. pass an empty array
      return []
    }

    return Object.values(ProjectsModule.getTasks).filter((task) => task.is_completed)
  }

  get getDoneTasksPerWeek() {
    return this.getTaskListDone.filter((task) =>
      this.isBetweenLastMondayAndNow(task.completion_date)
    ).length
  }

  get todaysTasks() {
    return this.tasksToDo
      .sort((a, b) => {
        if (a.urgency > b.urgency) {
          return -1
        }
        if (a.urgency < b.urgency) {
          return 1
        }
        // same urgency
        if (a.importance > b.importance) {
          return -1
        }
        if (a.importance < b.importance) {
          return 1
        }
        // same project importance
        return a.created > b.created ? -1 : 1
      })
      .slice(0, 10)
  }

  get urgency() {
    return (value: number) => {
      return ProjectsModule.getTaskUrgency(value)
    }
  }

  handleCheckItem(task) {
    this.completeTask(task).then(() => {
      this.checkedItems = []
    })
  }

  isBetweenLastMondayAndNow(date: string | null | undefined) {
    if (date === undefined || date === null) {
      return false
    }
    // Get the current date
    const now = new Date()

    // Get the date of last Monday
    const lastMonday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)
    )

    // Check if the given date is between last Monday and now
    return new Date(date) >= lastMonday && new Date(date) <= now
  }

  get effortText() {
    return (value: number | undefined) => {
      if (!value || value < 0) {
        // user did not set a value
        return '—' // &mdash;
      }
      if (value < 1) {
        // return value as minutes
        const minutes = Math.round(60 * value)
        return minutes.toString() + ' min'
      }
      // return value as hours in decimal
      const hours = formatterDecimalNumber.format(value)
      return `${hours} std`
    }
  }

  getProjectNameById(id: number | undefined) {
    if (!id) {
      return ''
    }
    return ProjectsModule.getProjects[id]?.name || ''
  }

  isActiveTask(id: number) {
    return id === this.getActiveTaskId
  }

  async triggerAction(task: ITask, taskAction) {
    this.actionProcess = true
    await taskAction.action(task)
    this.actionProcess = false
  }

  dragStart(event, task) {
    event.dataTransfer.setData('taskId', task.id)
  }
}
