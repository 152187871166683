import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import Vue from 'vue'
import i18N from '@/plugins/i18n'
import { IBudget, IGeneralCosts, IProjectCosts } from '@/interfaces'

@Module({
  name: 'finance',
  store: store,
  dynamic: true,
  namespaced: true,
})
class Finance extends VuexModule {
  // TODO add to any standard data
  timeSlots = [
    { text: `${i18N.t('modules.budgetTracking.timeSlots.unique')}`, value: 1 },
    { text: `${i18N.t('modules.budgetTracking.timeSlots.weekly')}`, value: 7 },
    { text: `${i18N.t('modules.budgetTracking.timeSlots.month')}`, value: 30 },
    { text: `${i18N.t('modules.budgetTracking.timeSlots.year')}`, value: 365 },
  ]
  workingcostlist: any[] = []
  projectBudget: IBudget = {
    id: 0,
    project: {
      id: 0,
      name: '',
      due_date: '',
      importance: 0,
      is_active: false,
      is_default: false,
      description: '',
      effort: 0,
      tasks: [],
      users: [],
      creator: '',
      main_responsibility: null,
    },
    main_responsibility: '',
    amount: 0,
    calculated_days: 0,
    notes: '',
    recurring: 0,
  }
  generalCosts: IGeneralCosts = {
    id: 0,
    amount: 0,
    name: '',
    notes: '',
    recurring: 0,
  }
  projectCosts: IProjectCosts = {
    project: {
      id: 0,
      name: '',
      due_date: '',
      importance: 0,
      is_active: false,
      is_default: false,
      description: '',
      effort: 0,
      tasks: [],
      users: [],
      creator: '',
      main_responsibility: null,
    },
    id: 0,
    project_id: 0,
    amount: 0,
    name: '',
    notes: '',
    recurring: 0,
  }
  // Project details in financial way
  // to add a project in financial way it has to be added first ONLY UPDATE POSSIBLE
  projectBudgetList: Array<IBudget> = []

  get isAdmin() {
    return this.context.rootGetters['user/isUserAdmin']
  }

  get getTimeSlots(): Array<{ text: string; value: number }> {
    return this.timeSlots
  }

  get getprojectBudget(): IBudget {
    return this.projectBudget
  }

  get getProjectBudgetList(): Array<IBudget> | null {
    return this.projectBudgetList
  }

  @Mutation
  setProjectBudgets(projects: IBudget[]) {
    if (projects) this.projectBudgetList = projects
  }

  @Action({ commit: 'setProjectBudgets' })
  async fetchProjectBudgets() {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.get('/paytrac/budgettracking/')
      // convert `amount` from string to float
      const data = result.data.map((budget) => {
        const amount = Number.parseFloat(budget.amount)
        return {
          ...budget,
          amount,
        }
      })
      return data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  createProjectBudget(projectBudget: IBudget) {
    if (projectBudget) this.projectBudgetList.push(projectBudget)
  }
  @Action({ commit: 'createProjectBudget' })
  async createProjectBudgetAction(projectBudget: IBudget) {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.post(
        '/paytrac/budgettracking/',
        projectBudget
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  updateProjectBudget(project: IBudget) {
    const foundIndex = this.projectBudgetList.findIndex((p) => p.id === project.id)
    if (foundIndex === -1) {
      return
    }
    // trigger change detection
    Vue.set(this.projectBudgetList, foundIndex, project)
  }
  @Action({ commit: 'updateProjectBudget' })
  async updateProjectBudgetAction(editedProject: IBudget) {
    // convert the date for the backend
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.patch(
        `/paytrac/budgettracking/${editedProject.id}/`,
        editedProject
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteProjectBudget(id: number) {
    // find the index of the deleted element in the array
    const foundIndex = this.projectBudgetList.findIndex((p) => p.id === id)
    if (foundIndex === -1) {
      return
    }
    // splice can also delete the item
    this.projectBudgetList.splice(foundIndex, 1)
  }
  @Action({ commit: 'deleteProjectBudget' })
  async deleteProjectBudgetAction(id: number) {
    if (!this.isAdmin) {
      return
    }
    try {
      await Vue.prototype.$http.delete(`/paytrac/budgettracking/${id}/`)
      // just pass the id here, since on a successful response we can assume the item got deleted
      return id
    } catch (e) {
      console.log(e)
    }
  }
  // Costs to calculate with
  // to add a project in financial way it has to be added first ONLY UPDATE POSSIBLE
  generalCostsList: Array<IGeneralCosts> = []

  get getGeneralCosts(): IGeneralCosts {
    return this.generalCosts
  }
  get getGeneralCostsList(): Array<IGeneralCosts> | null {
    return this.generalCostsList
  }
  @Mutation
  setGeneralCosts(generalCosts: IGeneralCosts[]) {
    if (generalCosts) this.generalCostsList = generalCosts
  }

  @Action({ commit: 'setGeneralCosts' })
  async fetchGeneralCosts() {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.get('/paytrac/generalcosts/')
      // convert `amount` from string to float
      const data = result.data.map((generalCosts) => {
        const amount = Number.parseFloat(generalCosts.amount)
        return {
          ...generalCosts,
          amount,
        }
      })
      return data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  createGeneralCosts(generalCosts: IGeneralCosts) {
    if (generalCosts) this.generalCostsList.push(generalCosts)
  }
  @Action({ commit: 'createGeneralCosts' })
  async createGeneralCostsAction(generalCosts: IGeneralCosts) {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.post(
        '/paytrac/generalcosts/',
        generalCosts
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  updateGeneralCosts(costs: IGeneralCosts) {
    // find the index of the edited element in the array
    const foundIndex = this.generalCostsList.findIndex((c) => c.id === costs.id)
    if (foundIndex === -1) {
      return
    }
    // trigger change detection
    Vue.set(this.generalCostsList, foundIndex, costs)
  }
  @Action({ commit: 'updateGeneralCosts' })
  async updateGeneralCostsAction(editedGeneralCosts: IGeneralCosts) {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.patch(
        `/paytrac/generalcosts/${editedGeneralCosts.id}/`,
        editedGeneralCosts
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteGeneralCosts(id: number) {
    // find the index of the deleted element in the array
    const foundIndex = this.generalCostsList.findIndex((p) => p.id === id)
    if (foundIndex === -1) {
      return
    }
    // splice can also delete the item
    this.generalCostsList.splice(foundIndex, 1)
  }
  @Action({ commit: 'deleteGeneralCosts' })
  async deleteGeneralCostsAction(id: number) {
    if (!this.isAdmin) {
      return
    }
    try {
      await Vue.prototype.$http.delete(`/paytrac/generalcosts/${id}/`)
      // just pass the id here, since on a successful response we can assume the item got deleted
      return id
    } catch (e) {
      console.log(e)
    }
  }

  // Projectcosts to calculate with
  // to add a project in financial way it has to be added first ONLY UPDATE POSSIBLE
  projectCostsList: Array<IProjectCosts> = []

  get getProjectCosts(): IProjectCosts {
    return this.projectCosts
  }
  get getProjectCostsList(): Array<IProjectCosts> | null {
    return this.projectCostsList
  }
  @Mutation
  setProjectCosts(projectCosts: IProjectCosts[]) {
    if (projectCosts) this.projectCostsList = projectCosts
  }

  @Action({ commit: 'setProjectCosts' })
  async fetchProjectCosts() {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.get('/paytrac/projectcosts/')
      // convert `amount` from string to float
      const data = result.data.map((projectCosts) => {
        const amount = Number.parseFloat(projectCosts.amount)
        return {
          ...projectCosts,
          amount,
        }
      })
      return data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  createProjectCosts(projectCosts: IProjectCosts) {
    if (projectCosts) this.projectCostsList.push(projectCosts)
  }
  @Action({ commit: 'createProjectCosts' })
  async createProjectCostsAction(projectCosts: IProjectCosts) {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.post(
        '/paytrac/projectcosts/',
        projectCosts
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  updateProjectCosts(costs: IProjectCosts) {
    //find the index of the edited element in the array
    const foundIndex = this.projectCostsList.findIndex((p) => p.id === costs.id)
    if (foundIndex === -1) {
      return
    }
    //trigger change detection
    Vue.set(this.projectCostsList, foundIndex, costs)
  }
  @Action({ commit: 'updateProjectCosts' })
  async updateProjectCostsAction(editedProjectCosts: IProjectCosts) {
    if (!this.isAdmin) {
      return
    }
    try {
      const result = await Vue.prototype.$http.patch(
        `/paytrac/projectcosts/${editedProjectCosts.id}/`,
        editedProjectCosts
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteProjectCosts(id: number) {
    // find the index of the deleted element in the array
    const foundIndex = this.projectCostsList.findIndex((p) => p.id === id)
    if (foundIndex === -1) {
      return
    }
    // splice can also delete the item
    this.projectCostsList.splice(foundIndex, 1)
  }
  @Action({ commit: 'deleteProjectCosts' })
  async deleteProjectCostsAction(id: number) {
    if (!this.isAdmin) {
      return
    }
    try {
      await Vue.prototype.$http.delete(`/paytrac/projectcosts/${id}/`)
      // just pass the id here, since on a successful response we can assume the item got deleted
      return id
    } catch (e) {
      console.log(e)
    }
  }
}
export const FinanceModule = getModule(Finance)
