
import { Component, Ref, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ProjectsModule } from '@/store/modules/projectsModule'

import { VForm } from 'vue/types/extend'
import { ISnackbar } from '@/interfaces'

const StoreUI = namespace('UI')

@Component({})
export default class QuickTaskCreatorForm extends Vue {
  @Ref('form') readonly form!: VForm
  @Prop() isEditAlways?: boolean

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  handleIsOpenForm() {
    this.$emit('setIsEditingFromChatMenu')
  }

  // isEditing = true
  taskData: any = {
    id: 0,
    name: '',
    is_completed: false,
    importance: 2,
    urgency: -1,
    description: '',
    effort: '',
    project: 0,
    created: '',
    tags: [],
    notes: {},
    assigned_to_id: null,
  }
  get getProjectList() {
    return Object.values(ProjectsModule.getProjects)
  }

  urgency = 0
  // validator
  nameRules = [
    (v) => !!v || this.$t('forms.quickTaskCreator.nameRules.enter'),
    (v) => v.length <= 100 || this.$t('forms.quickTaskCreator.nameRules.length'),
  ]
  numberRules = [
    (v) =>
      (typeof v === 'number' && v >= 0) ||
      v === '' ||
      this.$t('forms.quickTaskCreator.numberRules.positive'),
  ]

  get getTaskUrgencyIcon() {
    const urgency = this.taskData.urgency
    return ProjectsModule.getTaskUrgency(urgency).icon
  }

  get getTaskUrgencyArray() {
    const urgencies: Array<any> = []
    for (let i = 0; i < ProjectsModule.taskUrgency.length; i++) {
      urgencies.push({
        text: ProjectsModule.getTaskUrgency(i).text,
        value: i,
      })
    }
    return urgencies
  }

  get getCurrentProjects() {
    return ProjectsModule.getAllProjects
  }

  async addTask() {
    if (!this.form.validate()) {
      // invalid user input -> abort
      this.showSnackbar({
        text: `${this.$t('forms.quickTaskCreator.snackBars.addTaskError')}`,
        type: 'error',
      })
      return
    }
    if (this.taskData.urgency < 0) {
      // user did not enter a value -> set minimal value
      this.taskData.urgency = 0
    }
    if (this.taskData.effort === '') {
      // user did not enter a value -> set minimal value
      this.taskData.effort = 0
    }
    if (this.taskData.project === 0) {
      this.taskData.project = ProjectsModule.getDefaultProjectId
    }

    try {
      const newTask = await ProjectsModule.addTaskAction({
        projectId: this.taskData.project,
        task: this.taskData,
      })

      // this.$emit('confirmed', newTask)

      this.showSnackbar({
        text: `${this.$t('forms.quickTaskCreator.snackBars.addTaskActionSuccess')}`,
        type: 'success',
      })
    } catch (err) {
      console.error(err)
      this.showSnackbar({
        text: `${this.$t('forms.quickTaskCreator.snackBars.addTaskActionError')}`,
        type: 'error',
      })
    }

    // reset task
    this.taskData = {
      id: 0,
      name: '',
      is_completed: false,
      importance: 2,
      urgency: -1,
      description: '',
      effort: '',
      project: 0,
      created: '',
      tags: [],
      notes: {},
      assigned_to_id: null,
    }
    this.handleIsOpenForm()
  }
}
