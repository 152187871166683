
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BaseButton from '@/components/ui/BaseButton.vue'

const StoreUI = namespace('UI')

@Component({
  components: {
    BaseButton,
  },
})
export default class BaseSnackbar extends Vue {
  @Prop({ default: '' }) type?: string
  @Prop() value? = false

  @StoreUI.State
  public dialogPromiseResolve!: (result: boolean) => void

  show = this.value

  @Watch('show')
  emitShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.$emit('input', val)
    if (!val) return
  }

  @Watch('value')
  toggleShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.show = val
  }

  accept() {
    this.show = false
    this.dialogPromiseResolve(true)
  }

  decline() {
    this.show = false
    this.dialogPromiseResolve(false)
  }
}
