
import UserInfoMini from '@/components/navigation/UserInfoMini.vue'
import TiktracMini from '@/components/tiktrac/TiktracMini.vue'
import NavigationDrawerOverall from '@/components/navigation/NavigationDrawerOverall.vue'
import NotificationList from '@/components/lists/NotificationItem/NotificationList.vue'
import { AttendanceState } from '@/store/modules/tiktrac/attendance'
import { Component, Vue, Watch } from 'vue-property-decorator'
import type Tiktrac from 'vue/types/tiktrac'
import { namespace } from 'vuex-class'
import { UserModule } from '@/store/modules/userModule'

const StoreAttendance = namespace('Attendance')

@Component({
  components: {
    TiktracMini,
    UserInfoMini,
    NavigationDrawerOverall,
    NotificationList,
  },
})
export default class AppBar extends Vue {
  @StoreAttendance.Getter
  public isAgencyMode!: boolean

  @StoreAttendance.Getter
  public getAttendanceState!: AttendanceState

  @StoreAttendance.Action
  public checkIn!: () => Promise<boolean>

  @StoreAttendance.Action
  public checkOut!: () => Promise<Tiktrac.Response>

  get company() {
    return UserModule.getUserInfo.company.name
  }

  attendanceStateActive = false
  attendanceStateLoading = true

  async checkInOut() {
    if (this.attendanceStateLoading) {
      return
    }
    this.attendanceStateLoading = true
    await (this.attendanceStateActive ? this.checkOut : this.checkIn)()
    this.attendanceStateLoading = false
  }

  @Watch('getAttendanceState')
  onStateChange(state: AttendanceState, oldState: AttendanceState) {
    if (state == AttendanceState.UNKNOWN) {
      return
    }
    if (oldState == AttendanceState.UNKNOWN) {
      this.attendanceStateLoading = false
    }
    this.attendanceStateActive = state === AttendanceState.ACTIVE
  }
}
