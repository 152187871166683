
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DatePicker from '@/components/forms/DatePicker.vue'
import TimePicker from '@/components/forms/TimePicker.vue'
import InlineEdit from '@/components/forms/InlineEdit.vue'
import TaskDialog from '@/components/dialogs/TaskDialog.vue'

import type Tiktrac from 'vue/types/tiktrac'
import { IDialog, IProject, ISnackbar, ITask, ITaskList } from '@/interfaces'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { UserModule } from '@/store/modules/userModule'

const StoreUI = namespace('UI')

@Component({
  components: {
    DatePicker,
    InlineEdit,
    TaskDialog,
    TimePicker,
  },
})
export default class TaskTimetrackingList extends Vue {
  @Prop()
  readonly project!: IProject

  @Prop()
  readonly highlightedEntryId!: string

  @Prop()
  readonly dialog!: boolean

  @Ref('taskDialog')
  readonly taskDialogRef!: TaskDialog

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  isAddingNewEntry = false
  isAddingNewEntryProcess = false
  get headers() {
    return [
      { text: this.$t('tables.taskTable.headers.completed'), value: 'is_completed' },
      { text: this.$t('tables.taskTable.headers.name'), value: 'name' },
      { text: this.$t('tables.taskTable.headers.urgency'), value: 'urgency' },
      { text: this.$t('tables.taskTable.headers.effort'), value: 'effort' },
      { text: this.$t('tables.taskTable.headers.assignedTo'), value: 'assigned_to_id' },
      { text: '', value: 'actions' },
    ]
  }
  itemsPerPage = 10
  page = 1
  newTask = {
    is_completed: false,
    name: '',
    importance: 0,
    urgency: 0,
    effort: 0,
    assigned_to_id: null,
  }
  tasks: ITaskList = {}
  // validation
  get nameRules() {
    return [
      (v) => !!v || this.$t('tables.taskTable.validation.nameRules.enterName'),
      (v) => v.length <= 100 || this.$t('tables.taskTable.validation.nameRules.length'),
    ]
  }
  get numberRules() {
    return [
      (v) =>
        typeof v === 'number' ||
        this.$t('tables.taskTable.validation.numberRules.positive'),
      (v) => v >= 0 || this.$t('tables.taskTable.validation.numberRules.positive'),
    ]
  }

  timetrackingsSum = {
    hours: 0,
    minutes: 0,
  }

  get getTaskList() {
    return ProjectsModule.getTasks
  }

  get getTasks() {
    const tasks = [] as Array<ITask>
    for (const task of Object.values(this.tasks)) {
      tasks.push({
        id: task.id,
        is_completed: task.is_completed,
        name: task.name,
        urgency: task.urgency,
        effort: task.effort,
        assigned_to_id: task.assigned_to_id,
        project: task.project,
        user: task.user,
        tags: task.tags,
        notes: task.notes,
        created: '',
        description: task.description,
        importance: task.importance,
      })
    }
    return tasks
  }

  get getUserList() {
    // exclude users that are not part of the project
    return (
      UserModule.getUserList?.filter((user) => this.project.users.includes(user.uid)) ||
      []
    )
  }

  get getTaskUrgencyArray() {
    const urgencies: Array<any> = []
    for (let i = 0; i < ProjectsModule.taskUrgency.length; i++) {
      urgencies.push({
        text: ProjectsModule.getTaskUrgency(i).text,
        value: i,
      })
    }
    return urgencies
  }

  @Watch('isAddingNewEntry')
  resetFieldTask(value) {
    if (!value) {
      Object.assign(this.newTask, this.initialStateOfTask())
    }
  }

  @Watch('getTaskList', { deep: true })
  onTaskListUpdate() {
    this.fetchTasks()
  }

  @Watch('project', { immediate: true })
  onTaskLoad(project: IProject, oldProject: IProject) {
    if (project?.id === oldProject?.id) return
    if (!project.id) {
      this.tasks = {}
      return
    }
    this.fetchTasks()
  }

  @Watch('getTasks')
  highlightIssue(tasks) {
    if (!tasks || !tasks.length) {
      return
    }
    if (!this.highlightedEntryId) {
      return
    }
    // find issue
    const idx = tasks.findIndex((i) => i.id === this.highlightedEntryId)
    if (idx === -1) {
      return
    }
    // calc page that contains the issue
    this.page = Math.ceil((idx + 1) / this.itemsPerPage)
  }

  mounted() {
    this.getTimetrackingsSum()

    this.$watch(
      () => this.dialog,
      (dialog: boolean) => {
        if (dialog) {
          this.getTimetrackingsSum()
        }
      }
    )
  }

  created() {}

  initialStateOfTask() {
    this.newTask = {
      is_completed: false,
      name: '',
      importance: 0,
      urgency: 0,
      effort: 0,
      assigned_to_id: null,
    }
  }

  getUserNameById(userId: string) {
    const userList = UserModule.getUserList
    if (!userList) {
      return ''
    }
    const user = userList.find((u) => u.uid === userId)
    if (!user) {
      return ''
    }
    return user.first_name + ' ' + user.last_name
  }

  getTimetrackingsSum() {
    Vue.prototype.$http
      .get(`tiktrac/timetracking/tasks/busylist/${this.project.id}/`)
      .then((response) => {
        this.timetrackingsSum.hours = Math.trunc(response.data / 3600000)
        this.timetrackingsSum.minutes = Math.trunc((response.data % 3600000) / 60000)
      })
  }

  getTaskUrgencyIcon(item) {
    const urgency = item.urgency
    return ProjectsModule.getTaskUrgency(urgency).icon
  }

  getTaskUrgency(item) {
    const urgency = item.urgency
    return ProjectsModule.getTaskUrgency(urgency).text
  }

  fetchTasks() {
    this.tasks = {}
    if (!this.project?.id) {
      return
    }
    this.tasks = { ...ProjectsModule.getTasksByProjectId(this.project.id) }
  }

  filterUserList(item, queryText) {
    let name = item.first_name + ' ' + item.last_name
    name = name.toLowerCase()
    const q = queryText.toLowerCase()
    return name.indexOf(q) > -1
  }

  openDetail(task) {
    this.taskDialogRef.editTask(task)
  }

  async addIssue() {
    this.isAddingNewEntryProcess = true
    // validate entry
    if (!this.validateNewEntry()) {
      this.showSnackbar({
        text: `${this.$t('tables.taskTable.snackBars.validateNewEntryError')}`,
        type: 'error',
      })
      this.isAddingNewEntryProcess = false
      return
    }
    // create new task
    const task: ITask = {
      id: 0,
      name: this.newTask.name,
      description: '',
      is_completed: this.newTask.is_completed,
      importance: this.newTask.importance,
      urgency: this.newTask.urgency,
      effort: this.newTask.effort,
      created: '',
      tags: [],
      notes: {},
      assigned_to_id: this.newTask.assigned_to_id,
    }
    // save task
    if (
      !(await ProjectsModule.addTaskAction({ projectId: this.project.id, task: task }))
    ) {
      this.showSnackbar({
        text: `${this.$t('tables.taskTable.snackBars.addTaskAction.error')}`,
        type: 'error',
      })
    } else {
      this.showSnackbar({
        text: `${this.$t('tables.taskTable.snackBars.addTaskAction.success')}`,
        type: 'success',
      })
    }
    // finish process
    this.isAddingNewEntry = false
    this.isAddingNewEntryProcess = false
  }

  validateNewEntry() {
    const refName = this.$refs['input-name-new'] as any
    const refEffort = this.$refs['input-effort-new'] as any
    if (refName && !refName.valid) {
      console.log(refName)
      return false
    }
    if (refEffort && !refEffort.valid) {
      return false
    }
    return true
  }

  async deleteTask(task: ITask) {
    const consent = await this.showDialog({
      text: `${this.$t('tables.taskTable.snackBars.deleteTask.dialog')}`,
    })
    if (!consent) {
      return
    }

    const data = {
      id: task.id,
      projectId: task.project,
    }

    ProjectsModule.deleteTaskAction(data)
      .then(() => {
        // delete task from local list
        this.$delete(this.tasks, task.id)
        this.showSnackbar({
          text: `${this.$t('tables.taskTable.snackBars.deleteTask.success')}`,
          type: 'success',
        })
      })
      .catch((error: Tiktrac.Response) => {
        this.showSnackbar({
          text: `${this.$t('tables.taskTable.snackBars.deleteTask.error')}`,
          type: 'error',
        })
      })
  }
}
