
import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator'
import { isProp } from '@/services/common'
import { formatterDate } from '@/services/datetime'

@Component({})
export default class DatePicker extends Vue {
  @VModel({ default: null })
  date!: string | Array<string> | null

  @Prop()
  readonly classes?: string

  @Prop()
  readonly dense?: string

  @Prop()
  readonly hideDetails?: string

  @Prop() // mdi icon
  readonly icon?: string

  @Prop() // url to image file
  readonly img?: string

  @Prop()
  readonly label?: string

  @Prop()
  readonly placeholder?: string

  @Prop()
  readonly range?: boolean

  @Prop({ type: [String, Boolean] })
  readonly readonly?: string | boolean

  @Prop({ type: [Function, Promise] })
  readonly save?: Function

  @Prop()
  readonly textField?: string

  disabled = false
  menu = false
  origValue: string | Array<string> | null = null

  get isDense() {
    return isProp(this.dense)
  }

  get isHideDetails() {
    return isProp(this.hideDetails)
  }

  get isReadonly() {
    return isProp(this.readonly)
  }

  get isTextField() {
    return isProp(this.textField)
  }

  get getDelimiter() {
    return this.isTextField ? ' - ' : '<br>'
  }

  get getDateString() {
    // empty value
    if (!this.date) return ''
    // check if date is array (`range` == true)
    if (Array.isArray(this.date)) {
      // TODO display date as string in local timezone
      // TODO save date as UTC date string
      // empty range
      if (!this.date.length) return ''
      // build date string from range values
      let dateString = formatterDate.format(new Date(this.date[0]))
      const dateEnd =
        this.date.length > 1 ? formatterDate.format(new Date(this.date[1])) : ''
      if (dateEnd && dateEnd !== dateString) {
        dateString += this.getDelimiter + dateEnd
      }
      return dateString
    }
    // return formated single date
    return formatterDate.format(new Date(this.date))
  }

  @Watch('menu')
  onToggleMenu(menu: boolean) {
    if (menu) {
      /**
       * on open
       */
      this.origValue = Array.isArray(this.date) ? [...this.date] : this.date
    } else {
      /**
       * on close
       */
      // call `close()` once
      if (!this.disabled) {
        this.close()
      }
    }
  }

  hasChanged() {
    if (Array.isArray(this.date)) {
      if (this.date.length !== this.origValue?.length) {
        return true
      }
      for (let i = 0; i < this.date.length; i++) {
        if (this.date[i] !== this.origValue[i]) {
          return true
        }
      }
      return false
    } else {
      return this.date !== this.origValue
    }
  }

  clear() {
    this.origValue = this.date
    this.date = null
    this.close()
  }

  close() {
    this.disabled = true
    this.$nextTick(async () => {
      // duplicate value when only one date selected in ranged mode
      if (Array.isArray(this.date) && this.date.length === 1) {
        this.date.push(this.date[0])
      }
      // save and emit only when value has changed
      if (this.hasChanged()) {
        // user save function
        if (this.save) {
          await this.save(this.date)
        }
        // emit changes
        this.$emit('save', this.date)
      }
      // close menu
      this.menu = false
      this.$nextTick(() => {
        this.disabled = false
      })
    })
  }
}
