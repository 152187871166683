
import { Component, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DatePicker from '@/components/forms/DatePicker.vue'
import InlineEdit from '@/components/forms/InlineEdit.vue'
import TextEditor from '@/components/forms/TextEditor.vue'
import TaskTable from '@/components/tables/TaskTable.vue'
import BaseMenuButton from '@/components/ui/BaseMenuButton.vue'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { FinanceModule } from '@/store/modules/budgettracking'
import { UserModule } from '@/store/modules/userModule'

import { IDialog, IProject, ISnackbar, IUserData } from '@/interfaces'

const StoreUI = namespace('UI')

@Component({
  components: {
    BaseMenuButton,
    DatePicker,
    InlineEdit,
    TaskTable,
    TextEditor,
  },
})
export default class ProjectDialog extends Vue {
  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  abort = false
  dialog = false
  menuParticipants = false
  menuParticipantAdd = false
  menuParticipantAddId = ''
  isEdit = false
  state = {
    name: {
      disabled: false,
      loading: false,
    },
    is_active: {
      disabled: false,
      loading: false,
    },
    importance: {
      disabled: false,
      loading: false,
    },
    effort: {
      disabled: false,
      loading: false,
    },
    due_date: {
      disabled: false,
      loading: false,
    },
    main_responsibility_id: {
      disabled: false,
      loading: false,
    },
    menuParticipantAddId: {
      disabled: false,
      loading: false,
    },
  }
  highlightedTiktracEntry = ''
  projectData: IProject = {
    id: 0,
    name: '',
    description: '',
    is_active: true,
    is_default: false,
    importance: 0,
    effort: 0,
    due_date: null,
    tasks: [],
    users: [],
    creator: '',
    main_responsibility: null,
    main_responsibility_id: null,
  }
  // validator
  get nameRules() {
    return [
      (v) => !!v || this.$t('dialogs.projectDialog.nameRules.enterName'),
      (v) => v.length <= 100 || this.$t('dialogs.projectDialog.nameRules.nameLength'),
    ]
  }
  // tabs
  tab = 1

  get authorizeResponsibility() {
    return (
      !this.projectData.main_responsibility_id ||
      this.projectData.main_responsibility_id === this.userID
    )
  }

  /**
   * Get the user Id you need to verify
   */
  get userID() {
    return UserModule.getUserID
  }

  get getUserList() {
    return UserModule.getUserList || []
  }

  get getEffortSum() {
    let tasks = ProjectsModule.getTasks

    let allEfforts = 0

    for (let task of Object.values(tasks)) {
      if (task.project === this.projectData.id) {
        allEfforts += task.effort
      }
    }

    return allEfforts
  }

  /**
   * Get user that is currently working on the project (for creator)
   */
  get creatorName() {
    const creator_id = this.projectData.creator
    const creator = UserModule.getUserById(creator_id)
    if (!creator) {
      return ''
    }
    return creator.first_name + ' ' + creator.last_name
  }

  get projectBudgets() {
    return FinanceModule.getProjectBudgetList || []
  }

  get projectCosts() {
    return FinanceModule.getProjectCostsList || []
  }

  /**
   * Get all users that are member of the project
   */
  get getUserListFiltered() {
    const userList = UserModule.getUserList || []
    if (!this.isEdit) {
      return userList
    }
    return userList.filter((user) => this.projectData.users.includes(user.uid)) || []
  }

  /**
   * Get members of the project
   */
  get getParticipants() {
    const users = [] as Array<IUserData>
    const user_list = UserModule.getUserList

    if (!user_list) {
      return users
    }

    for (const user of this.projectData.users) {
      if (!user) {
        continue
      }

      for (const userEl of user_list) {
        if (user === userEl.uid) {
          users.push(userEl)
        }
      }
    }

    return users
  }

  get getParticipantCount() {
    return this.projectData.users.length
  }

  get getImportanceText() {
    if (!this.isEdit) {
      return this.$t('dialogs.projectDialog.importance.title')
    }
    switch (this.projectData.importance) {
      case 0:
        return this.$t('dialogs.projectDialog.importance.unimportant')
      case 1:
        return this.$t('dialogs.projectDialog.importance.someImportant')
      case 2:
        return this.$t('dialogs.projectDialog.importance.middle')
      case 3:
        return this.$t('dialogs.projectDialog.importance.important')
      case 4:
        return this.$t('dialogs.projectDialog.importance.veryImportant')
      default:
        return ''
    }
  }

  @Watch('dialog')
  onDialogToggle(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    if (!val) {
      // on close
      this.initDialog()
      this.initData()
    } else {
      // on open
      this.initDialog()
    }
  }

  @Watch('menuParticipants')
  onToggleMenuParticipants(val: boolean) {
    if (val) {
      this.menuParticipantAdd = false
    }
  }

  mounted() {
    // dependencies
    ProjectsModule.fetchProjects()
    UserModule.fetchUserList()
    FinanceModule.fetchProjectBudgets()
    FinanceModule.fetchProjectCosts()
  }

  initDialog() {
    this.abort = false
  }

  initData() {
    this.projectData = {
      id: 0,
      name: '',
      description: '',
      is_active: true,
      is_default: false,
      importance: 0,
      effort: 0,
      due_date: null,
      tasks: [],
      users: [],
      creator: '',
      main_responsibility: null,
      main_responsibility_id: null,
    }
    this.menuParticipantAdd = false
    this.isEdit = false
  }

  setProcessingState(value: boolean, property = '') {
    this.setDisabled(value, property)
    this.setLoading(value, property)
  }

  setDisabled(value: boolean, property = '') {
    if (property) {
      if (Object.prototype.hasOwnProperty.call(this.state, property)) {
        if (property === 'main_responsibility_id' && !value) {
          if (!this.authorizeResponsibility) {
            // prevent enabeling if another user is responsible for the project
            return
          }
        }
        this.state[property].disabled = value
      }
    } else {
      for (const prop in this.state) {
        this.state[prop].disabled = value
      }
    }
  }

  setLoading(value: boolean, property = '') {
    if (property) {
      if (Object.prototype.hasOwnProperty.call(this.state, property)) {
        this.state[property].loading = value
      }
    } else {
      for (const prop in this.state) {
        this.state[prop].loading = value
      }
    }
  }

  async addParticipant() {
    if (
      !this.menuParticipantAddId ||
      this.projectData.users.includes(this.menuParticipantAddId)
    ) {
      this.menuParticipants = false
      return
    }
    // add new participant
    this.projectData.users.push(this.menuParticipantAddId)
    await this.save('users')
    this.menuParticipants = false
  }

  async save(varName: string | null = null, callback: Function | null = null) {
    if (this.abort) {
      // no save on abort (e.g. on keypress ESC)
      return
    }
    if (varName !== null) {
      /**
       * update project
       */
      const project = ProjectsModule.getProjects[this.projectData?.id]
      if (!project) {
        return false
      }
      if (!Object.prototype.hasOwnProperty.call(project, varName)) {
        return false
      }
      if (!(await this.saveProperty(varName, project))) {
        // rollback changes
        this.projectData[varName] = project[varName]
      } else if (callback) {
        callback()
      }
    } else {
      /**
       * create new project
       */
      // verify project
      if (!this.projectData.name) {
        this.showSnackbar({
          text: `${this.$t('dialogs.projectDialog.snackBars.enterProjectName')}`,
          type: 'warning',
        })
        return
      }
      // if set, add `main_responsibility_id` to participants
      if (this.projectData.main_responsibility_id) {
        const main_responsibility_id = this.projectData.main_responsibility_id
        if (!this.projectData.users.includes(main_responsibility_id)) {
          this.projectData.users.push(main_responsibility_id)
        }
      }
      // start creation process
      this.setProcessingState(true)
      // create project
      const newProject = await ProjectsModule.addProjectAction(this.projectData)
      if (!newProject) {
        // abort creation process
        this.setProcessingState(false)
        return
      }
      // update project in popup
      this.projectData = { ...newProject }
      // finish creation process
      this.setProcessingState(false)
      this.isEdit = true
    }
  }

  async saveProperty(varName: string, project: IProject): Promise<boolean> {
    /**
     * update helper
     */
    // only update primitive values on changes
    // (non-primitive values are always updated)
    if (typeof this.projectData[varName] !== 'object') {
      if (this.projectData[varName] === project[varName]) {
        console.log('NO CHANGES')
        return true
      }
    }
    // check if input is valid
    const ref = this.$refs[`ref-${varName}`] as any
    if (ref && !ref.valid) {
      return false
    }
    // start update process
    this.setProcessingState(true, varName)
    // save changes to one property
    const projectData = {} as IProject
    projectData.id = this.projectData.id
    projectData[varName] = this.projectData[varName]
    const response = await ProjectsModule.updateProjectAction(projectData).catch(() => {
      this.setProcessingState(false, varName)
    })
    if (!response) {
      return false
    }
    if (!this.authorizeResponsibility) {
      this.setLoading(false, varName)
      return true
    }
    this.setProcessingState(false, varName)
    return true
  }

  async deleteProject() {
    let consentText = `${this.$t('dialogs.projectDialog.snackBars.deleteProject')}`
    let pBudgets = this.projectBudgets.filter(
      (p) => p.project_id == this.projectData.id
    )
    let pCosts = this.projectCosts.filter((p) => p.project_id == this.projectData.id)

    if (pBudgets.length || pCosts.length) {
      let pBudget = 0
      let pCost = 0
      let costSentence = ''
      pBudgets.forEach((p) => (pBudget += p.amount))
      pCosts.forEach((p) => (pCost += p.amount))
      if (pCost) {
        costSentence =
          `${this.$t('dialogs.projectDialog.snackBars.costsOf')}` + pCost + ' €'
      }
      consentText +=
        `${this.$t('dialogs.projectDialog.snackBars.costsText')}` +
        pBudget +
        '€' +
        costSentence
    }

    const consent = await this.showDialog({
      text: consentText,
      type: 'warning',
    })
    if (!consent) {
      return
    }
    if (!(await ProjectsModule.deleteProjectAction(this.projectData.id))) {
      return
    }
    this.dialog = false
    this.showSnackbar({
      text: `${this.$t('dialogs.projectDialog.snackBars.deleteSuccess')}`,
    })
  }

  filterUserList(item, queryText) {
    let name = item.first_name + ' ' + item.last_name
    name = name.toLowerCase()
    const q = queryText.toLowerCase()
    return name.indexOf(q) > -1
  }

  public editProject(project: IProject) {
    this.projectData = { ...project }
    this.isEdit = true
    this.dialog = true
    if (!this.authorizeResponsibility) {
      // disable main responsibility input
      this.setDisabled(true, 'main_responsibility_id')
    }
  }

  public createProject() {
    this.initData()
    this.dialog = true
  }
}
