
import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import { IProject } from '@/interfaces'
import { namespace } from 'vuex-class'
const StoreAttendance = namespace('Attendance')
import DailyTeamOverviewDialog from '@/components/dialogs/DailyTeamOverviewDialog.vue'
import ProjectDialog from '@/components/dialogs/ProjectDialog.vue'
import QuickTaskCreator from '@/components/forms/QuickTaskCreator.vue'
import TrackingEntry from '@/components/tiktrac/forms/TrackingEntry.vue'
import QuickTaskCreatorForm from '@/components/forms/QuickTaskCreatorForm.vue'
import BaseButton from '@/components/ui/BaseButton.vue'
import { AttendanceState } from '@/store/modules/tiktrac/attendance'
import type Tiktrac from 'vue/types/tiktrac'
import DailyBaseComponent from '../calendar/DailyBaseComponent.vue'

const ChatbotModule = namespace('ChatbotModule')
@Component({
  components: {
    QuickTaskCreator,
    TrackingEntry,
    ProjectDialog,
    QuickTaskCreatorForm,
    BaseButton,
    DailyTeamOverviewDialog,
    ChatBotMenu,
  },
})
export default class ChatBotMenu extends DailyBaseComponent {
  @Ref() projectDetails!: ProjectDialog

  @ChatbotModule.State
  public vMenuVal
  @ChatbotModule.State
  public isTrackingEntryActive
  @ChatbotModule.State
  public isQuickTaskFormActive
  @ChatbotModule.State
  public isChatListActive

  @ChatbotModule.Mutation
  public toggleValFlag!: (val: string) => void
  @ChatbotModule.Mutation
  public setToFalseVal!: (val) => void
  @ChatbotModule.Mutation
  public activatorChatList!: () => void

  isTaskWasAdded = false
  currentTask: any

  @StoreAttendance.Getter
  public isAgencyMode!: boolean

  @StoreAttendance.Getter
  public getAttendanceState!: AttendanceState

  @StoreAttendance.Action
  public checkIn!: () => Promise<boolean>

  @StoreAttendance.Action
  public checkOut!: () => Promise<Tiktrac.Response>

  attendanceStateActive = false
  attendanceStateLoading = true

  mounted() {
    this.isChatBarActive()
  }

  stateChatBar() {
    if (!this.isChatListActive) {
      document.querySelector?.('.sc-message-list')?.classList.add('inactive')
    } else {
      if (this.isQuickTaskFormActive) {
        this.setToFalseVal('isQuickTaskFormActive')
      } else if (this.isTrackingEntryActive) {
        this.setToFalseVal('isTrackingEntryActive')
      }
      document.querySelector?.('.sc-message-list')?.classList.remove('inactive')
    }
  }
  isChatBarActive() {
    document.querySelector('.sc-user-input--text')?.addEventListener('click', () => {
      this.activatorChatList()
      this.stateChatBar()
      if (this.isTaskWasAdded) {
        this.isTaskWasAdded = false
      }

      document.querySelector('.sc-user-input')?.classList.add('input100')
    })
  }
  inactiveChatList() {
    if (this.isChatListActive) {
      this.setToFalseVal('isChatListActive')
    }

    this.stateChatBar()
  }

  TaskWasAddedClose() {
    if (this.isTaskWasAdded) {
      this.isTaskWasAdded = false
    }
  }

  inactiveTrackingEntry() {
    if (this.isTrackingEntryActive) {
      this.setToFalseVal('isTrackingEntryActive')
    }
  }

  NewTimeTrackingToggle() {
    this.toggleValFlag('isTrackingEntryActive')

    this.setIsEditingFromChatMenu()
    this.inactiveChatList()
    this.TaskWasAddedClose()
  }

  setIsEditingFromChatMenu() {
    if (this.isQuickTaskFormActive) {
      this.setToFalseVal('isQuickTaskFormActive')
    }
  }
  NewTaskToggle() {
    this.toggleValFlag('isQuickTaskFormActive')
    this.inactiveTrackingEntry()
    this.TaskWasAddedClose()
    this.inactiveChatList()
  }
  openProjectDetails(project: IProject | null) {
    if (!project) {
      this.projectDetails.createProject()
    } else {
      this.projectDetails.editProject(project)
    }
  }
  NewProjectToggle() {
    this.openProjectDetails(null)
    this.setIsEditingFromChatMenu()
    this.inactiveTrackingEntry()
    this.inactiveChatList()
    this.TaskWasAddedClose()
  }

  editTaskDetail() {
    this.editTask(this.currentTask)
  }

  async checkInOut() {
    if (this.attendanceStateLoading) {
      return
    }
    this.attendanceStateLoading = true
    await (this.attendanceStateActive ? this.checkOut : this.checkIn)()
    this.attendanceStateLoading = false
  }

  @Watch('getAttendanceState')
  onStateChange(state: AttendanceState, oldState: AttendanceState) {
    if (state == AttendanceState.UNKNOWN) {
      return
    }
    if (oldState == AttendanceState.UNKNOWN) {
      this.attendanceStateLoading = false
    }
    this.attendanceStateActive = state === AttendanceState.ACTIVE
  }
  atEditTask(data) {
    this.currentTask = data
    this.isTaskWasAdded = !this.isTaskWasAdded
  }
}
