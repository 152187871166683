import Vue from 'vue'
import Vuex from 'vuex'
import Attendance from './modules/tiktrac/attendance'
import Timetracking from './modules/tiktrac/timetracking'
import UI from './modules/ui'
import ChatbotModule from './modules/chatbotModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { Attendance, Timetracking, UI, ChatbotModule },
})
