
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppBar from '@/components/bars/AppBar.vue'
import EULADialog from '@/components/dialogs/EULADialog.vue'
import NavigationDrawerMobile from '@/components/navigation/NavigationDrawerMobile.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseSnackbar from '@/components/ui/BaseSnackbar.vue'
import ChatPopup from '@/components/ui/ChatPopup.vue'
import PageLoader from '@/components/ui/PageLoader.vue'
import TutorialDialog from '@/components/tutorial/TutorialDialog.vue'

import { ISnackbar, IQuickNotify } from '@/interfaces'
import { TimetrackingState } from '@/store/modules/tiktrac/timetracking'
import type Tiktrac from 'vue/types/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { UserModule } from './store/modules/userModule'
import { CalendarModule } from './store/modules/calendarModule'
import QuickNotificationItem from '@/components/ui/QuickNotificationItem.vue'

const StoreTimetracking = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({
  components: {
    AppBar,
    ChatPopup,
    BaseDialog,
    BaseSnackbar,
    EULADialog,
    NavigationDrawerMobile,
    PageLoader,
    TutorialDialog,
    QuickNotificationItem,
  },
})
export default class App extends Vue {
  @Ref('mobile-drawer') overallDrawer!: NavigationDrawerMobile

  drawer = false
  loading = true
  showEULA = false
  showTutorial = false

  @StoreUI.State
  public dialog!: any

  @StoreUI.State
  public snackbar!: any
  @StoreUI.State
  public quickNotify!: any

  @StoreTimetracking.Getter
  public getState!: TimetrackingState

  @StoreTimetracking.Action
  public fetchTimetracking!: (month?: string) => Promise<Tiktrac.Response>

  @StoreUI.Action
  public getNextInQueue!: () => Promise<ISnackbar>

  @StoreUI.Action
  public getNextInQueueQuickNotify!: () => Promise<IQuickNotify>

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showQuickNotify!: (data: IQuickNotify) => void

  @StoreUI.Action
  fetchNotifications!: () => void

  get getUserMSAL(): boolean {
    return UserModule.getUserInfo.msal_active
  }

  interval_ms_events: number = 0

  @Watch('snackbar.active')
  unqueueSnackbar(val: boolean) {
    if (!val) {
      this.getNextInQueue().then((snackbar) => {
        if (!snackbar) return
        setTimeout(() => {
          this.showSnackbar({ text: snackbar.text, type: snackbar.type })
        }, 250)
      })
    }
  }

  @Watch('quickNotify.active')
  unqueueQuickNotify(val: boolean) {
    if (!val) {
      this.getNextInQueueQuickNotify().then((quickNotify) => {
        if (!quickNotify) return
        setTimeout(() => {
          this.showQuickNotify({
            avatar: quickNotify.avatar,
            task: quickNotify.task,
            project: quickNotify.project,
            time: quickNotify.time,
            type: quickNotify.type,
            whoAssigned: quickNotify.whoAssigned,
          })
        }, 250)
      })
    }
  }

  @Watch('getState')
  updateOnStateChange(val: TimetrackingState, oldVal: TimetrackingState) {
    if (val === TimetrackingState.UNKNOWN || val === oldVal) {
      return
    }
    // TODO optimize this
    ProjectsModule.fetchAllTasks(true)
  }

  @Watch('getUserMSAL')
  updateIfMSAL(val: boolean) {
    if (!val) {
      clearInterval(this.interval_ms_events)
      // update tasks and busyList entries after microsoft cleanup
      ProjectsModule.fetchAllTasks(true)
      this.fetchTimetracking()
      return
    }
    CalendarModule.syncMSCalendar()
    setInterval(async () => {
      CalendarModule.syncMSCalendar()
    }, 5 * 60 * 1000)
  }

  created() {
    // dependencies
    UserModule.fetchUserInfo().then(() => {
      if (!UserModule.getUserInfo.settings.tutorial_completed) {
        this.showTutorial = true
      }
    })
    this.fetchNotifications()
    setInterval(async () => {
      this.fetchNotifications()
    }, 5 * 60 * 1000)
  }

  mounted() {
    this.showEULA = !localStorage.getItem('acceptedEULA')
    this.loading = false
  }

  toggleDrawer() {
    this.overallDrawer.toggleDrawer()
  }
}
