
import { Component, Ref, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import DatePicker from './DatePicker.vue'
import { ProjectsModule } from '@/store/modules/projectsModule'
import ProjectDialog from '@/components/dialogs/ProjectDialog.vue'

import { VForm } from 'vue/types/extend'
import { ISnackbar } from '@/interfaces'

const StoreUI = namespace('UI')

@Component({
  components: {
    DatePicker,
    ProjectDialog,
  },
})
export default class QuickTaskCreator extends Vue {
  @Ref('form') readonly form!: VForm
  @Ref()
  readonly projectDialog!: ProjectDialog

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  isEditing = false
  projectData: any = {
    id: 0,
    name: '',
    due_date: null,
    importance: 0,
    is_active: true,
    is_default: false,
    description: '',
    effort: 0,
    tasks: [],
    users: [],
    main_responsibility: {
      uid: '',
      first_name: '',
      last_name: '',
      email: '',
      role: 'user',
      wage: '',
      availability: '',
    },
  }
  // validator
  get nameRules() {
    return [
      (v) => !!v || this.$t('forms.quickProjectCreator.nameRules.enter'),
      (v) => v.length <= 100 || this.$t('forms.quickProjectCreator.nameRules.length'),
    ]
  }

  addProject() {
    if (!this.form.validate()) {
      // invalid user input -> abort
      this.showSnackbar({
        text: `${this.$t('forms.quickProjectCreator.snackBars.addProjectError')}`,
        type: 'error',
      })
      return
    }
    ProjectsModule.addProjectAction(this.projectData)
      .then((result) => {
        this.projectDialog.editProject(result)
        this.showSnackbar({
          text: `${this.$t(
            'forms.quickProjectCreator.snackBars.addProjectActionSuccess'
          )}`,
          type: 'success',
        })
      })
      .catch((err) => {
        console.error(err)
        this.showSnackbar({
          text: `${this.$t(
            'forms.quickProjectCreator.snackBars.addProjectActionError'
          )}`,
          type: 'error',
        })
      })
    // reset task
    this.projectData = {
      id: 0,
      name: '',
      due_date: null,
      importance: 0,
      is_active: true,
      is_default: false,
      description: '',
      effort: 0,
      tasks: [],
      users: [],
      main_responsibility: {
        uid: '',
        first_name: '',
        last_name: '',
        email: '',
        role: 'user',
        wage: '',
        availability: '',
      },
    }
    this.isEditing = false
  }
}
