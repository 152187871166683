
import { Component, Vue, Model } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const ChatbotModule = namespace('ChatbotModule')

@Component
export default class AttendenceMeeting extends Vue {
  @Model('dataAttendenceMeeting', { type: Object }) dataAttendenceMeeting!: any

  @ChatbotModule.State
  public isValidateTime

  get nameRules() {
    return [
      (v) => !!v || `${this.$t('dialogs.taskDialog.nameRules.enter')}`,
      (v) => v.length <= 100 || `${this.$t('dialogs.taskDialog.nameRules.length')}`,
    ]
  }
}
