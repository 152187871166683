
import { Component, Prop, Vue } from 'vue-property-decorator'
import { isProp } from '@/services/common'

@Component
export default class TasksListChatBot extends Vue {
  // @Prop({ default: 'primary' }) private color!: string
  @Prop() scopedProps!: any

  openTaskInDialog(id_Task) {
    this.$emit('openDialogForCurrentTask', id_Task)
  }
}
