
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { VueEditor, Quill } from 'vue2-editor'
import BaseButton from '@/components/ui/BaseButton.vue'

@Component({
  components: {
    BaseButton,
    VueEditor,
  },
})
export default class TextEditor extends Vue {
  @VModel() valueSync!: any

  @Prop({ type: [Function, Promise] })
  readonly save?: Function

  disabled = false
  copyDesc = ''
  isActiveBtnsBar = false

  customToolbar = [
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    ['bold', 'italic', 'underline'], // toggled buttons
    [{ color: [] }],
    ['image'],
  ]

  atEditTextArea(delta) {
    if (delta.ops.length > 1) {
      if (this.valueSync !== this.copyDesc) {
        this.isActiveBtnsBar = true
      }
    } else {
      this.copyDesc = this.valueSync
    }
  }

  async saveText(reset: boolean) {
    if (reset) {
      if (this.valueSync !== this.copyDesc) {
        this.valueSync = this.copyDesc
        this.isActiveBtnsBar = false
      }
    }

    if (this.save) {
      this.isActiveBtnsBar = false
      await this.save(this.valueSync)
      this.copyDesc = this.valueSync
    }
  }
}
