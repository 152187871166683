
import { Component, Vue, Watch, Ref, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BaseButton from '@/components/ui/BaseButton.vue'
import { generateIssueID } from '@/services/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'

import { ISnackbar, ITask } from '@/interfaces'
import type Tiktrac from 'vue/types/tiktrac'
import type { VForm } from 'vue/types/extend'

const StoreTiktrac = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({
  components: {
    BaseButton,
  },
})
export default class TrackingEntry extends Vue {
  @Prop({ default: false })
  readonly isEdit!: boolean

  @Prop()
  readonly busyListElemId?: string

  @Ref('form')
  readonly form!: VForm

  @StoreTiktrac.Getter
  public getIsInitializing!: boolean

  @StoreTiktrac.Getter
  public getTimetracking!: Tiktrac.Timetracking

  @StoreTiktrac.Getter
  public getActiveTimer!: string

  @StoreTiktrac.Getter
  public getActiveTaskId!: number

  @StoreTiktrac.Getter
  public getBusyList!: Tiktrac.BusyList

  @StoreTiktrac.Action
  public doUpdate!: (data: {
    issueId: string
    entry: Tiktrac.BusyListElemPartial
  }) => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public doAddIssue!: (data: {
    intent: string
    entry: Tiktrac.BusyListElem
  }) => Promise<Tiktrac.Response>

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  /**
   * project ID which the current time tracking is for
   */
  selectedProject = 0

  /**
   * task name which the current time tracking is for
   */
  selectedTaskName: string | Tiktrac.LooseObject = ''
  dateStart = ''
  dateEnd = ''
  checkIn = ''
  checkOut = ''
  comment = ''
  isCompleted = true

  formValid = false
  rulesName = [(v) => !!v || 'Required']
  selProjects: Array<any> = []
  selTasks: Array<any> = []
  selTaskChangeProject = false

  checkInProcess = false

  task: ITask | undefined = undefined

  get getProjects() {
    return ProjectsModule.getProjects
  }

  setDateInfoToForm(data) {
    this.checkIn = Vue.prototype.$moment(data.start).format('HH:mm')
    this.checkOut = Vue.prototype.$moment(data.end).format('HH:mm')
    this.dateStart = Vue.prototype.$moment(data.start).format('YYYY-MM-DD')
    this.dateEnd = Vue.prototype.$moment(data.end).format('YYYY-MM-DD')
  }

  @Watch('busyListElemId', { immediate: true })
  startSearchTaskByBusyList() {
    if (this.isEdit && this.busyListElemId) {
      const searchedTaskId = this.getBusyList[this.busyListElemId]?.task
      if (!searchedTaskId) {
        return
      }

      ProjectsModule.fetchTaskByID(searchedTaskId).then((taskSearched) => {
        this.task = taskSearched
      })
      this.setDateInfoToForm(this.getBusyList[this.busyListElemId])
    }
  }

  @Watch('dateStart')
  onDateStartUpdate(date: string, dateOld: string) {
    if (!date || date === dateOld) {
      // do nothing when the value is deleted or
      // there is no change
      return
    }
    if (!this.dateEnd) {
      // when end date is not set, set to start date
      this.dateEnd = date
      return
    }
    if (date > this.dateEnd) {
      // if start date is greater than end date, set end date to start date
      this.dateEnd = date
      return
    }
  }

  @Watch('dateEnd')
  onDateEndUpdate(date: string, dateOld: string) {
    if (!date || date === dateOld) {
      // do nothing when the value is deleted or
      // there is no change
      return
    }
    if (!this.dateEnd) {
      // when start date is not set, set to end date
      this.dateStart = date
      return
    }
    if (date < this.dateStart) {
      // if end date is smaller than start date, set start date to end date
      this.dateStart = date
      return
    }
  }

  getSelProjectList() {
    this.selProjects = [
      {
        text: `${this.$t('ticktrack.forms.trackingEntry.getSelProjectList')}`,
        value: 0,
      },
    ]
    for (const p of Object.values(ProjectsModule.getProjects)) {
      this.selProjects.push({
        text: p.name,
        value: p.id,
      })
    }
  }

  getSelTaskList() {
    this.selTasks = []
    for (const t of Object.values(ProjectsModule.getTasks) as Array<ITask>) {
      if (this.selectedProject && t.project !== this.selectedProject) {
        continue
      }
      if (t.id === this.getActiveTaskId) {
        // skip tasks where the timetracking is active
        continue
      }
      if (t.is_completed) {
        // skip finished tasks
        continue
      }
      const projectName = ProjectsModule.getProjects[t.project || 0]?.name
      this.selTasks.push({
        projectName: projectName,
        projectId: t.project,
        text: t.name,
        value: t.id,
      })
    }
  }

  mounted() {
    if (this.getIsInitializing) {
      return
    }
    // init after navigating back to tiktrac
    this.getSelProjectList()
    this.getSelTaskList()

    if (this.task) {
      this.selectedProject = this.task.project || 0
      this.selectedTaskName = this.task.name || ''
    }

    // initially set dates to current date
    if (!this.dateStart && !this.dateEnd) {
      // dateEnd will be set by watcher `onDateStartUpdate`
      this.dateStart = Vue.prototype.$moment().format('YYYY-MM-DD')
    }
  }

  @Watch('task')
  initFormPreset(task) {
    if (!task) {
      this.selectedProject = 0
      this.selectedTaskName = ''
      this.isCompleted = true
    } else {
      this.selectedProject = task.project || 0
      this.selectedTaskName = task.name || ''
      this.isCompleted = task.is_completed
    }
  }

  @Watch('getIsInitializing')
  init(isInitializing) {
    if (isInitializing) {
      return
    }
    ProjectsModule.fetchProjects().then(() => {
      this.getSelProjectList()
      this.getSelTaskList()
    })
  }

  @Watch('getProjects')
  projectsUpdated(projects) {
    if (!projects) return
    this.getSelProjectList()
  }

  resetForm() {
    this.selectedProject = 0
    this.selectedTaskName = ''
    this.selTaskChangeProject = false
  }

  updateSelProjects(projectId: number) {
    this.selectedProject = projectId
    // update select task list
    this.getSelTaskList()
    // If the user choosed an existing task from the list and then changed the project,
    // set a flag that causes the creation of a new task.
    if (typeof this.selectedTaskName === 'string') {
      return
    }
    this.selTaskChangeProject = projectId !== this.selectedTaskName.projectId
  }

  updateSelectedProject(item: string | Tiktrac.LooseObject) {
    // If the user choosed an existing task from the list,
    // auto select the appropriate project.
    if (typeof item === 'string') {
      return
    }
    this.selectedProject = item.projectId || 0
  }

  handlerSaveIssue(e: MouseEvent) {
    e.preventDefault()

    const issueStart = new Date(this.dateStart + ' ' + this.checkIn).getTime()
    const issueEnd = new Date(this.dateEnd + ' ' + this.checkOut).getTime()

    const target =
      (e.target as HTMLButtonElement) || (e.relatedTarget as HTMLButtonElement) || null
    if (!target) {
      return
    }
    this.checkInProcess = true

    // Apply v-combobox content to the bound variable.
    // This is necessary if user types in a new value
    // instead of selecting an existing one from the drop down list.
    const input = this.$refs['selectedTaskName'] as HTMLElement
    input.blur()

    // is user has not selected a project, select the default one
    if (this.selectedProject == 0) {
      this.selectedProject = ProjectsModule.getDefaultProjectId
    }

    // wait for vue to render the change
    this.$nextTick(() => {
      // get task info
      let selectedTaskName = ''
      let selectedTaskId = 0
      if (typeof this.selectedTaskName === 'string' || this.selTaskChangeProject) {
        // user wants to create a new task
        selectedTaskName =
          typeof this.selectedTaskName === 'string'
            ? this.selectedTaskName
            : this.selectedTaskName.text
      } else {
        // user wants to resume tracking an existing task
        selectedTaskId = this.selectedTaskName.value
      }

      // check additional input
      if (!this.form.validate()) {
        this.checkInProcess = false
        return
      }

      // create new issue
      // prettier-ignore
      const { issueId } = generateIssueID();

      // prepare POST data
      const issue: Tiktrac.BusyListElem = {
        id: this.busyListElemId || issueId,
        start: issueStart,
        end: issueEnd,
        pause: 0,
        status: 1, // employee is working
        status_descr: this.comment,
        split: 0,
        split_count: 0,
        project: this.selectedProject,
        task: this.task ? this.task?.id || 0 : selectedTaskId,
        taskName: selectedTaskName,
        taskCompleted: this.isCompleted,
      }

      let data
      if (this.isEdit) {
        data = {
          issueId: this.busyListElemId,
          entry: issue,
        }
      } else {
        data = {
          intent: 'issueAdd',
          entry: issue,
        }
      }

      const saveFunction = this.isEdit ? this.doUpdate : this.doAddIssue

      saveFunction(data)
        .then((response: Tiktrac.Response) => {
          if (response.responseCode === 201) {
            // TODO use a smarter way to update the data
            // fetch updated project list and update task list
            ProjectsModule.fetchProjects(true).then(() => {
              this.getSelTaskList()
            })
            // reset user input
            this.resetForm()
            const form = document.getElementById('form-add-issue') as HTMLFormElement
            if (form) {
              form.reset()
            }
            ;(this.$refs['form'] as HTMLFormElement).resetValidation()
            this.showSnackbar({
              text: `${this.$t(
                'ticktrack.forms.trackingEntry.snackBars.createSuccess'
              )}`,
            })
          } else if (response.responseCode === 200) {
            ProjectsModule.fetchProjects(true).then(() => {
              this.getSelTaskList()
            })

            // reset user input
            this.resetForm()
            const form = document.getElementById('form-add-issue') as HTMLFormElement
            if (form) {
              form.reset()
            }
            ;(this.$refs['form'] as HTMLFormElement).resetValidation()
            this.showSnackbar({
              text: `${this.$t(
                'ticktrack.forms.trackingEntry.snackBars.updateSuccess'
              )}`,
            })
          }
          this.$emit('updateSuccess')
        })
        .catch((error: Tiktrac.Response) => {
          if (error.responseCode === 409) {
            // conflict
            this.showSnackbar({
              text: `${this.$t(
                'ticktrack.forms.trackingEntry.snackBars.timesCollide'
              )}`,
              type: 'error',
            })
          } else {
            this.showSnackbar({
              text: `${this.$t('ticktrack.forms.trackingEntry.snackBars.createError')}`,
              type: 'error',
            })
          }
        })
        .finally(() => {
          this.checkInProcess = false
        })
    })
  }
}
