import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vue from 'vue'

@Module({ namespaced: true })
class ChatbotModule extends VuexModule {
  vMenuVal = false
  isTrackingEntryActive = false
  isQuickTaskFormActive = false
  isChatListActive = true
  isTaskWasAdded = false
  isValidateTime = false

  @Mutation
  vMenuFlag() {
    this.vMenuVal = !this.vMenuVal
  }

  @Mutation
  vMenuSetVal(val: boolean) {
    this.vMenuVal = val
  }
  @Mutation
  setValidTime(val: boolean) {
    this.isValidateTime = val
  }

  @Mutation
  setToFalseVal(val) {
    switch (val) {
      case 'isChatListActive':
        this.isChatListActive = false
        break
      case 'isQuickTaskFormActive':
        this.isQuickTaskFormActive = false
        break
      case 'isTrackingEntryActive':
        this.isTrackingEntryActive = false
        break
      case 'isTaskWasAdded':
        this.isTaskWasAdded = false
        break
    }
  }

  @Mutation
  toggleValFlag(val: string) {
    switch (val) {
      case 'isQuickTaskFormActive':
        this.isQuickTaskFormActive = !this.isQuickTaskFormActive
        break
      case 'isTaskWasAdded':
        this.isTaskWasAdded = !this.isTaskWasAdded
        break
      case 'isTrackingEntryActive':
        this.isTrackingEntryActive = !this.isTrackingEntryActive
        break
    }
  }

  @Mutation
  activatorChatList() {
    this.isChatListActive = true
  }

  @Action
  async sendMsgToChatbot(messageTxt: string) {
    try {
      const res = await Vue.prototype.$http
        .post('/chatbot/', { message: messageTxt })
        .catch((error) => {
          console.error({ error })
        })
      if (!res) {
        return
      }
      const data = JSON.parse(res.data)

      return data
    } catch (error) {
      console.log(error)
    }
  }
}

export default ChatbotModule
