
import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import DailyBaseComponent from '@/components/calendar/DailyBaseComponent.vue'
import { decimalToHours } from '@/services/datetime'

import { ITask } from '@/interfaces'
import { BusyListElem, TimetrackingsPerMonth } from 'vue/types/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { TimetrackingState } from '@/store/modules/tiktrac/timetracking'
const StoreTiktrac = namespace('Timetracking')

@Component({
  components: {},
})
export default class EisenHoverMatrixDialog extends DailyBaseComponent {
  @Prop() returnAmountTasks

  @StoreTiktrac.Getter
  public getState!: TimetrackingState

  // @StoreTiktrac.Action
  // public fetchTimetracking!: () => Promise<Tiktrac.Response>

  @StoreTiktrac.Getter
  public getBusyList!: any

  @StoreTiktrac.Getter
  public getAllTimetrackings!: TimetrackingsPerMonth

  @StoreTiktrac.Getter
  public getActiveStartTime!: number

  @StoreTiktrac.Getter
  public getActivePause!: number | null

  @StoreTiktrac.Getter
  public getCurrent!: { curUserId: string; curMonth: string; isAdmin: boolean }

  @StoreTiktrac.Getter
  public getPauseTime!: number

  abort = false
  dialog = false
  now = 0

  public createDialog() {
    this.dialog = true
  }
  get getTaskList() {
    return ProjectsModule.getTasks
  }

  get passedTimeFormatted() {
    const currentTime = this.now

    return new Date(currentTime - this.getActiveStartTime - this.getPauseTime)
      .toISOString()
      .substr(11, 8)
  }

  @Watch('getActivePause')
  testonly2() {
    if (typeof this.getActivePause === 'number') {
      clearInterval(this.polling)
    } else {
      setInterval(() => {
        this.now = Date.now()
      }, 1000)
    }
  }

  getTaskUrgencyIcon(number) {
    return ProjectsModule.getTaskUrgency(number).icon
  }
  tasks: Array<ITask> = []
  matrix11: Array<ITask> = []
  matrix12: Array<ITask> = []
  matrix13: Array<ITask> = []
  matrix14: Array<ITask> = []

  matrix21: Array<ITask> = []
  matrix22: Array<ITask> = []
  matrix23: Array<ITask> = []
  matrix24: Array<ITask> = []

  matrix31: Array<ITask> = []
  matrix32: Array<ITask> = []
  matrix33: Array<ITask> = []
  matrix34: Array<ITask> = []

  matrix41: Array<ITask> = []
  matrix42: Array<ITask> = []
  matrix43: Array<ITask> = []
  matrix44: Array<ITask> = []

  container: any = {}
  timePassed = 0
  @Watch('dialog')
  toggleDialog(value) {
    if (value) {
      this.initValues()
    }
  }
  @Watch('getTaskList')
  inint() {
    this.tasks = Object.values(this.$deepCopy(this.getTaskList))
    this.initValues()
  }

  @Watch('limiter')
  limiterchanger() {
    this.initValues()
  }
  busylist: Array<BusyListElem> = []

  @Watch('getBusyList')
  initBusy() {
    this.initValues()
  }

  mounted() {
    this.container = document.querySelector(`.matrix.coordinate-2-2`)
  }

  displayUptime(idTask: number | undefined) {
    if (!idTask) {
      return ''
    }

    const filterResult: Array<BusyListElem> = this.busylist.filter(
      (item: BusyListElem) => item.task === idTask
    )

    if (typeof filterResult !== 'undefined') {
      let sum = 0

      for (const busyListElem of filterResult) {
        sum += busyListElem.end - busyListElem.start - busyListElem.pause
      }

      const duration = Vue.prototype.$moment.duration(sum)
      return decimalToHours(duration.asHours(), true, true)
    }
  }

  getProjectNameById(id: number | undefined) {
    if (!id) {
      return ''
    }
    return ProjectsModule.getProjects[id]?.name || ''
  }
  getProjectDeadlineById(id: number | undefined) {
    if (!id) {
      return ''
    }
    return ProjectsModule.getProjects[id]?.due_date || ''
  }

  limiter: number = 0
  updated() {
    this.$nextTick(function () {
      const container = document.querySelector(`.matrix.coordinate-2-2`)
      this.limiter = this.CalculateTasksLimiter(
        container?.clientWidth,
        container?.clientHeight
      )
    })
  }
  polling: any = null
  created() {
    window.addEventListener('resize', this.myWatcherResizer)

    this.polling = setInterval(() => {
      this.now = Date.now()
    }, 1000)
  }
  beforeDestroy() {
    clearInterval(this.polling)
  }
  destroyed() {
    window.removeEventListener('resize', this.myWatcherResizer)
  }

  myWatcherResizer(e) {
    const container = document.querySelector(`.matrix.coordinate-2-2`)
    this.limiter = this.CalculateTasksLimiter(
      container?.clientWidth,
      container?.clientHeight
    )
  }

  CalculateTasksLimiter(width, height): number {
    const taskWidth = 80 + 14 //including margin 2*7px
    const taskHeight = 30 + 14 //including margin 2*7px
    const amountTaskWidth = Math.floor(width / taskWidth)
    const amountTaskHeight = Math.floor(height / taskHeight)

    return amountTaskWidth * amountTaskHeight
  }

  filterByImportanceAndUrgency(urgency: number, importance: number): Array<ITask> {
    let result: Array<ITask> = []
    let maximum: number = 0

    //TODO prioritize task
    let count = 0
    if (maximum !== undefined) {
      result = this.tasks.filter((task) => {
        if (count < this.limiter && !task.is_completed) {
          const res = task.urgency === urgency && task.importance === importance
          if (res) {
            count++
            return true
          }
        }
      })
    }

    return result
  }

  editCurrentTask(idx: number) {
    const currentTask = ProjectsModule.fetchTaskByID(idx)
    currentTask?.then((task) => {
      this.taskDialog.editTask(task)
    })
  }

  initValues() {
    this.tasks = Object.values(this.$deepCopy(this.getTaskList))
    this.busylist = []
    for (const timetrackingPerUser of Object.values(this.getAllTimetrackings)) {
      const busylist = timetrackingPerUser[this.getCurrent.curUserId]?.busyList
      if (!busylist) {
        continue
      }
      const bl: Array<BusyListElem> = Object.values(this.$deepCopy(busylist))
      this.busylist.push(...bl)
    }

    this.matrix11 = this.filterByImportanceAndUrgency(0, 1)
    this.matrix12 = this.filterByImportanceAndUrgency(0, 2)
    this.matrix13 = this.filterByImportanceAndUrgency(0, 3)
    this.matrix14 = this.filterByImportanceAndUrgency(0, 4)

    this.matrix21 = this.filterByImportanceAndUrgency(1, 1)
    this.matrix22 = this.filterByImportanceAndUrgency(1, 2)
    this.matrix23 = this.filterByImportanceAndUrgency(1, 3)
    this.matrix24 = this.filterByImportanceAndUrgency(1, 4)

    this.matrix31 = this.filterByImportanceAndUrgency(2, 1)
    this.matrix32 = this.filterByImportanceAndUrgency(2, 2)
    this.matrix33 = this.filterByImportanceAndUrgency(2, 3)
    this.matrix34 = this.filterByImportanceAndUrgency(2, 4)

    this.matrix41 = this.filterByImportanceAndUrgency(3, 1)
    this.matrix42 = this.filterByImportanceAndUrgency(3, 2)
    this.matrix43 = this.filterByImportanceAndUrgency(3, 3)
    this.matrix44 = this.filterByImportanceAndUrgency(3, 4)
  }

  isTaskIsActiveNow(idx: number) {
    if (!idx) {
      return false
    }
    if (this.getActiveTaskId === idx) {
      return true
    }
    return false
  }
}
