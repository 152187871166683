
import { Component, Ref, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import QuickTaskCreatorForm from '@/components/forms/QuickTaskCreatorForm.vue'
import TaskDialog from '@/components/dialogs/TaskDialog.vue'

import { VForm } from 'vue/types/extend'
import { ISnackbar } from '@/interfaces'

const StoreUI = namespace('UI')

@Component({ components: { QuickTaskCreatorForm, TaskDialog } })
export default class QuickTaskCreator extends Vue {
  @Ref('form') readonly form!: VForm
  @Ref()
  readonly taskDialog!: TaskDialog

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  onAddTask(data) {
    this.taskDialog.editTask(data)
  }
  isEditing = false
  setIsEditing() {
    this.isEditing = false
  }
}
