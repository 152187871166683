
import moment from 'moment'
import { INotificationItem } from '@/interfaces'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ProjectsModule } from '@/store/modules/projectsModule'
import NotificationItem from './NotificationItem.vue'
import TaskDialog from '@/components/dialogs/TaskDialog.vue'
import ProjectDialog from '@/components/dialogs/ProjectDialog.vue'

const StoreUI = namespace('UI')

@Component({
  components: {
    NotificationItem,
    TaskDialog,
    ProjectDialog,
  },
})
export default class NotificationList extends Vue {
  @StoreUI.State
  public unreadNotifications!: Array<INotificationItem>
  @StoreUI.Action
  readNotification
  @Ref()
  readonly taskDialog!: TaskDialog

  @Ref()
  readonly projectDialog!: ProjectDialog

  isNewNotification = false
  notifyIconState = false

  openDialog(verb, id, slug) {
    this.readNotification(slug)
    if (verb === 'assigned_task') {
      this.taskDialog.editTask(ProjectsModule.getTasks[id])
    } else {
      this.projectDialog.editProject(ProjectsModule.getProjects[id])
    }
  }

  calculateTimeFromNow(timestamp: Date) {
    return moment(timestamp).fromNow()
  }

  getNotificationTitle(type) {
    return type === 'assigned_project' ? 'assigned Project' : 'assigned Task'
  }

  notifyIconChangeSrc() {
    if (!this.notifyIconState) {
      return 'noti_icon'
    } else if (this.notifyIconState) {
      return 'noti_active'
    }
  }

  changeNotifyIconState() {
    this.notifyIconState = !this.notifyIconState
    if (this.notifyIconState) {
      this.isNewNotification = false
    }
  }
}
