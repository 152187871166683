import { render, staticRenderFns } from "./BaseMenuButton.vue?vue&type=template&id=085b7f12&scoped=true&"
import script from "./BaseMenuButton.vue?vue&type=script&lang=ts&"
export * from "./BaseMenuButton.vue?vue&type=script&lang=ts&"
import style0 from "./BaseMenuButton.vue?vue&type=style&index=0&id=085b7f12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085b7f12",
  null
  
)

export default component.exports