
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LayoutTwoColumns extends Vue {
  @Prop({ default: false }) readonly even!: string | boolean

  get isEven() {
    return this.even === '' || this.even === 'true' || this.even === true
  }
}
