
import { Component, Vue, Ref, Watch } from 'vue-property-decorator'

import { ProjectsModule } from '@/store/modules/projectsModule'
import ProjectDialog from '@/components/dialogs/ProjectDialog.vue'
import { IProject } from '@/interfaces'

@Component({
  components: {
    ProjectDialog,
  },
})
export default class ProjectList extends Vue {
  @Ref() projectDetails!: ProjectDialog

  projectData: IProject = {
    id: 0,
    name: '',
    description: '',
    is_active: true,
    is_default: false,
    importance: 0,
    effort: 0,
    due_date: null,
    tasks: [],
    users: [],
    creator: '',
    main_responsibility: null,
    main_responsibility_id: null,
  }

  get isLoading() {
    return !ProjectsModule.isInitializedProjects
  }

  get projects() {
    return Object.values(ProjectsModule.getProjects).slice(0, 10)
  }

  mounted() {
    ProjectsModule.fetchProjects()
  }

  getImportanceText(importance, check_default) {
    if (check_default) {
      return this.$t('homePage.projectList.processing')
    }
    switch (importance) {
      case 0:
        return this.$t('homePage.projectList.unimportant')
      case 1:
        return this.$t('homePage.projectList.someImportant')
      case 2:
        return this.$t('homePage.projectList.middle')
      case 3:
        return this.$t('homePage.projectList.important')
      case 4:
        return this.$t('homePage.projectList.veryImportant')
      default:
        return ''
    }
  }

  openProjectDetails(project: IProject) {
    this.projectDetails.editProject(project)
  }
}
