import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  Configuration,
  LogLevel,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  PopupRequest,
  EndSessionRequest,
  BrowserAuthError,
} from '@azure/msal-browser'
import { UIManager } from './UIManager'
import { SsoSilentRequest } from '@azure/msal-browser'

// to store variable to database
import { UserModule } from '@/store/modules/userModule'

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID || '',
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  private myMSALObj: PublicClientApplication // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account: AccountInfo | null // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
  private loginRedirectRequest: RedirectRequest // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  private loginRequest: PopupRequest // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
  private profileRedirectRequest: RedirectRequest
  private profileRequest: PopupRequest
  private mailRedirectRequest: RedirectRequest
  private mailRequest: PopupRequest
  private silentProfileRequest: SilentRequest // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html
  private silentMailRequest: SilentRequest
  private silentLoginRequest: SsoSilentRequest

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG)
    this.account = null

    this.loginRequest = {
      scopes: [
        'User.Read',
        'Calendars.Read',
        'Calendars.ReadWrite',
        'openid',
        'profile',
      ],
    }

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    }

    this.profileRequest = {
      scopes: [
        'User.Read',
        'Calendars.Read',
        'Calendars.ReadWrite',
        'openid',
        'profile',
      ],
    }

    this.profileRedirectRequest = {
      ...this.profileRequest,
      redirectStartPage: window.location.href,
    }

    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.mailRequest = {
      scopes: ['Mail.Read'],
    }

    this.mailRedirectRequest = {
      ...this.mailRequest,
      redirectStartPage: window.location.href,
    }

    this.silentProfileRequest = {
      scopes: [
        'User.Read',
        'Calendars.Read',
        'Calendars.ReadWrite',
        'openid',
        'profile',
      ],
      forceRefresh: false,
    }

    this.silentMailRequest = {
      scopes: ['openid', 'profile', 'Mail.Read'],
      forceRefresh: false,
    }

    this.silentLoginRequest = {
      loginHint: 'IDLAB@msidlab0.ccsctp.net',
    }
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   * TODO: Add account chooser code
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts()
    if (currentAccounts === null) {
      console.log('No accounts detected')
      return null
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log('Multiple accounts detected, need to add choose account code.')
      return currentAccounts[0]
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0]
    }

    return null
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  loadAuthModule(): void {
    this.myMSALObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        this.handleResponse(resp)
      })
      .catch(console.error)
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      this.account = response.account
    } else {
      this.account = this.getAccount()
    }
    if (this.account) {
      UIManager.showWelcomeMessage(this.account)
    }
  }

  /**
   * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
   * @param request
   */
  attemptSsoSilent() {
    this.myMSALObj
      .ssoSilent(this.silentLoginRequest)
      .then(() => {
        this.account = this.getAccount()
        if (this.account) {
          UIManager.showWelcomeMessage(this.account)
        } else {
          console.log('No account!')
        }
      })
      .catch((error) => {
        console.error('Silent Error: ' + error)
        if (error instanceof InteractionRequiredAuthError) {
          this.login('loginPopup')
        }
      })
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  login(signInType: string): void {
    if (signInType === 'loginPopup') {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp)
          // update msal state
          if (!UserModule.getUserInfo.msal_active) {
            const user = {
              ...UserModule.getUserInfo,
              msal_active: true,
              msal_fullsync: true,
            }
            UserModule.updateUserAction(user)
          }
        })
        .catch(console.error)
    } else if (signInType === 'loginRedirect') {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest)
    }
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    let account: AccountInfo | undefined
    if (this.account) {
      account = this.account
    }
    const logOutRequest: EndSessionRequest = {
      account,
    }

    this.myMSALObj
      .logoutPopup(logOutRequest)
      .then(() => {
        // update msal state
        if (UserModule.getUserInfo.msal_active) {
          const user = {
            ...UserModule.getUserInfo,
            msal_active: false,
          }
          UserModule.updateUserAction(user)
        }
      })
      .catch(console.error) // TODO user feedback
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account
    }
    return this.getTokenRedirect(this.silentProfileRequest, this.profileRedirectRequest)
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getProfileTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account
    }
    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest)
  }

  /**
   * Gets the token to read mail data from MS Graph silently, or falls back to interactive redirect.
   */
  async getMailTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentMailRequest.account = this.account
    }
    return this.getTokenRedirect(this.silentMailRequest, this.mailRedirectRequest)
  }

  /**
   * Gets the token to read mail data from MS Graph silently, or falls back to interactive popup.
   */
  async getMailTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentMailRequest.account = this.account
    }
    return this.getTokenPopup(this.silentMailRequest, this.mailRequest)
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string | null> {
    try {
      const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(
        silentRequest
      )
      this.handleResponse(response)
      return response.accessToken
    } catch (e) {
      console.log('silent token acquisition fails.')
      if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
        console.log('acquiring token using redirect')
        const resp: AuthenticationResult | null = await this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .catch((err) => {
            console.error('acquiring token using redirect failed')
            console.error({ err })
            return null
          })
        if (!resp) {
          return null
        }
        this.handleResponse(resp)
        if (!UserModule.getUserInfo.msal_active && this.account) {
          // TODO Checkup if always correct
          const user = {
            ...UserModule.getUserInfo,
            msal_active: true,
          }
          UserModule.updateUserAction(user)
        }
        return resp.accessToken
      } else {
        console.error({ e })
      }
    }

    return null
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest)
      return response.accessToken
    } catch (e) {
      console.log('silent token acquisition fails.')
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect')
        this.myMSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error)
      } else {
        console.error(e)
      }
    }

    return null
  }
}
