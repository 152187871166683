
import UserInfoMini from '@/components/navigation/UserInfoMini.vue'
import { UserModule } from '@/store/modules/userModule'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UserInfoMini,
  },
})
export default class NavigationDrawerContent extends Vue {
  value = false
  drawerOpen = false

  get allRoutesTop() {
    return [
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesTop.start'),
        to: '/',
        icon: 'mdi-home',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesTop.calendar'),
        to: 'calendar',
        icon: 'mdi-calendar',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesTop.projects'),
        to: 'projects',
        icon: 'mdi-file-tree',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesTop.tasks'),
        to: 'tasks',
        icon: 'mdi-checkbox-marked-circle-plus-outline',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesTop.users'),
        to: 'users',
        icon: 'mdi-account-multiple',
        adminOnly: true,
      },
    ]
  }

  get allRoutesBottom() {
    return [
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesBottom.settings'),
        to: 'settings',
        icon: 'mdi-tune',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesBottom.roadmap'),
        href: 'https://atthene.productlane.io/roadmap',
        icon: 'mdi-forum-outline',
        adminOnly: false,
      },
      {
        name: this.$t('navigation.navigationDrawerContent.allRoutesBottom.about'),
        to: 'about',
        icon: 'mdi-information-outline',
        adminOnly: false,
      },
    ]
  }

  allRoutesApps = [
    // {
    //   name: 'Atthene',
    //   logo: require('@/assets/Atthene.png'),
    //   description: 'Assistent',
    //   to: '',
    //   adminOnly: false,
    // },
    // {
    //   name: 'Paytrac',
    //   logo: require('@/assets/paytrac.png'),
    //   description: 'Budgetplanung',
    //   to: 'paytrac',
    //   adminOnly: true,
    // },
    {
      name: 'Tiktrac',
      logo: require('@/assets/tiktrac.png'),
      description: 'Zeiterfassung',
      to: 'tiktrac',
      adminOnly: false,
    },
    // {
    //   name: 'Scriptac',
    //   logo: require('@/assets/scriptac.png'),
    //   description: 'Transkriptionstool',
    //   to: '',
    //   adminOnly: false,
    // }
  ]

  get routesTop() {
    return this.filterRoutes(this.allRoutesTop)
  }

  get routesBottom() {
    return this.filterRoutes(this.allRoutesBottom)
  }

  get routesApps() {
    return this.filterRoutes(this.allRoutesApps)
  }

  filterRoutes(routes) {
    return routes.filter((route) => !route.adminOnly || UserModule.isUserAdmin)
  }
}
