import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Chat from 'vue-beautiful-chat'
import Vuelidate from 'vuelidate'
import '@/plugins/keycloak'
import { AxiosPlugin } from '@/plugins/axios'
import { MomentPlugin } from '@/plugins/moment'
import { FunctionsPlugin } from '@/plugins/funtions'
import i18n from '@/plugins/i18n'

Vue.use(Chat)
Vue.use(Vuelidate)
Vue.use(FunctionsPlugin)

Vue.config.productionTip = false

Vue.use(AxiosPlugin, {
  baseUrl: process.env.VUE_APP_BACKEND_URL,
})
Vue.use(MomentPlugin)

Vue.prototype.$moment.locale(`${i18n.t('momentjs')}`)

function vueInit() {
  return new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
}

if (process.env.VUE_APP_IS_KEYCLOAK === 'false') {
  vueInit().$mount('#app')
} else {
  Vue.prototype.$keycloak
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then(() => {
      vueInit().$mount('#app')
    })
}
