
import { Component, VModel, Vue, Prop, Watch } from 'vue-property-decorator'
import { isProp } from '@/services/common'
import { formatterTime } from '@/services/datetime'

@Component({})
export default class TimePicker extends Vue {
  @VModel({ default: null })
  time!: number | null

  @Prop()
  readonly classes?: string

  @Prop()
  readonly dense?: string

  @Prop()
  readonly hideDetails?: string

  @Prop()
  readonly icon?: string

  @Prop()
  readonly label?: string

  @Prop()
  readonly placeholder?: string

  @Prop({ type: [String, Boolean] })
  readonly readonly?: string | boolean

  @Prop({ type: [Function, Promise] })
  readonly save?: Function

  @Prop()
  readonly textField?: string

  disabled = false
  menu = false
  origValue: number | null = null

  get isDense() {
    return isProp(this.dense)
  }

  get isHideDetails() {
    return isProp(this.hideDetails)
  }

  get isReadonly() {
    return isProp(this.readonly)
  }

  get isTextField() {
    return isProp(this.textField)
  }

  get getTimeString() {
    if (!this.time) return ''
    const date = new Date(this.time)
    return formatterTime.format(date)
  }

  get getISOTimeString() {
    if (!this.time) return ''
    const date = new Date(this.time)
    return date.toTimeString().substr(0, 5)
  }

  set getISOTimeString(timeString) {
    let date = new Date()
    if (this.time) {
      date = new Date(this.time)
    }
    const hoursString = timeString?.substr(0, 2) || ''
    const minutesString = timeString?.substr(3) || ''
    if (!hoursString || !minutesString) {
      return
    }
    date.setHours(Number.parseInt(hoursString))
    date.setMinutes(Number.parseInt(minutesString))
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.time = date.getTime()
  }

  @Watch('menu')
  onToggleMenu(menu: boolean) {
    if (menu) {
      /**
       * on open
       */
      this.origValue = this.time
    } else {
      /**
       * on close
       */
      // call `close()` once
      if (!this.disabled) {
        this.close()
      }
    }
  }

  hasChanged() {
    return this.time !== this.origValue
  }

  close() {
    this.disabled = true
    this.$nextTick(async () => {
      // save and emit only when value has changed
      if (this.hasChanged()) {
        // user save function
        if (this.save) {
          await this.save(this.time)
        }
        // emit changes
        this.$emit('save', this.time)
      }
      // close menu
      this.menu = false
      this.$nextTick(() => {
        this.disabled = false
      })
    })
  }
}
