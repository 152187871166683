
import { Component, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DatePicker from '@/components/forms/DatePicker.vue'
import InlineEdit from '@/components/forms/InlineEdit.vue'
import TextEditor from '@/components/forms/TextEditor.vue'
import TaskTable from '@/components/tables/TaskTable.vue'
import BaseMenuButton from '@/components/ui/BaseMenuButton.vue'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { FinanceModule } from '@/store/modules/budgettracking'
import { UserModule } from '@/store/modules/userModule'

import { IDialog, IProject, ISnackbar, IUserData } from '@/interfaces'

const StoreUI = namespace('UI')

@Component({
  components: {
    BaseMenuButton,
    DatePicker,
    InlineEdit,
    TaskTable,
    TextEditor,
  },
})
export default class DailyTeamOverviewDialog extends Vue {
  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  abort = false
  dialog = false
  menuParticipants = false
  menuParticipantAdd = false
  menuParticipantAddId = ''
  isEdit = false

  //test data only

  items = [
    {
      items: [{ title: 'List Item' }],
      title: 'Felix Meusel',
    },
    {
      // active: true,
      items: [
        { title: 'Website design', projectName: 'Project name', time: '4h 32min' },
        { title: 'Pull request', projectName: 'Project name', time: '4h 32min' },
        { title: 'Meeting', projectName: 'Project name', time: '4h 32min' },
      ],
      title: 'Max Klein',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Lana Klein',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Ernest Szulim',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Vladyslav Shapoval',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Roman Rud',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Yehor Polyhalov',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Piotr Nowakowski',
    },
    {
      items: [{ title: 'List Item' }],
      title: 'Michal Dziedzic',
    },
  ]

  public createDialog() {
    this.dialog = true
  }

  /**
   * Get the user Id you need to verify
   */
  get userID() {
    return UserModule.getUserID
  }

  get getUserList() {
    return UserModule.getUserList || []
  }
}
