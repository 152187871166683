import { ISnackbar, IDialog, IQuickNotify, INotificationItem } from '@/interfaces'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vue from 'vue'

@Module({ namespaced: true })
class UI extends VuexModule {
  // dialog
  dialog = {
    active: false,
    text: '',
    type: 'warning',
  }
  dialogPromise: Promise<boolean> | undefined = undefined
  dialogPromiseResolve: ((result: boolean) => void) | undefined = undefined
  // snackbar
  snackbar = {
    active: false,
    text: '',
    type: 'success',
  }
  //quick Nofity
  quickNotify = {
    active: false,
    avatar: 'avatar',
    task: 'testTask',
    project: 'Project-test',
    time: '6 min ago',
    type: 'meeting',
    whoAssigned: 'Felix Meusel',
  }
  queue: Array<ISnackbar> = []
  queueQuickNotify: Array<IQuickNotify> = []
  unreadNotifications: Array<INotificationItem> = []
  // mobile navigation one pager
  navWindow = 0

  @Mutation
  public ACTIVATE_DIALOG(payload: IDialog) {
    this.dialog.text = payload.text
    this.dialog.type = payload.type || 'warning'
    this.dialog.active = true
    // reset Promise resolve function
    this.dialogPromiseResolve = undefined
    // create new Promise
    this.dialogPromise = new Promise((resolve) => {
      this.dialogPromiseResolve = resolve
    })
  }

  @Action
  public showDialog(data: IDialog): Promise<boolean> | undefined {
    const { text, type } = data
    this.context.commit('ACTIVATE_DIALOG', {
      text,
      type,
    })

    return this.dialogPromise
  }

  @Mutation
  public ACTIVATE_SNACKBAR(payload: ISnackbar) {
    if (this.snackbar.active) {
      this.queue.unshift(payload)
      return
    }
    this.snackbar.text = payload.text
    this.snackbar.type = payload.type || 'success'
    this.snackbar.active = true
  }

  @Action
  public showSnackbar(data: ISnackbar) {
    const { text, type } = data
    this.context.commit('ACTIVATE_SNACKBAR', {
      text,
      type,
    })
  }

  @Mutation
  setNotifications(payload: INotificationItem[]) {
    this.unreadNotifications = [...payload]
  }
  @Action({ commit: 'setNotifications' })
  async fetchNotifications() {
    try {
      const result = await Vue.prototype.$http.get('/notifications/unread/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  removeReadNotification(slug: number) {
    this.unreadNotifications = this.unreadNotifications.filter((n) => n.slug !== slug)
  }

  @Action({ commit: 'removeReadNotification' })
  async readNotification(slug: number) {
    try {
      await Vue.prototype.$http.post('/notifications/mark-as-read/' + slug + '/', {})
      return slug
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  public ACTIVE_QUICKNOTIFY(payload: IQuickNotify) {
    if (this.quickNotify.active) {
      this.queueQuickNotify.unshift(payload)
      return
    }
    this.quickNotify.avatar = payload.avatar
    this.quickNotify.task = payload.task
    this.quickNotify.project = payload.project
    this.quickNotify.time = payload.time
    this.quickNotify.type = payload.type
    this.quickNotify.whoAssigned = payload.whoAssigned
  }
  @Action
  public showQuickNotify(data: IQuickNotify) {
    const { avatar, task, project, time, type, whoAssigned } = data
    this.context.commit('ACTIVE_QUICKNOTIFY', {
      avatar,
      task,
      project,
      time,
      type,
      whoAssigned,
    })
  }
  @Action
  public getNextInQueue() {
    return this.queue.pop()
  }
  @Action
  public getNextInQueueQuickNotify() {
    return this.queueQuickNotify.pop()
  }

  @Mutation
  public RESET_NAV_WINDOW() {
    this.navWindow = 0
  }

  @Action
  public navigateReset() {
    this.context.commit('RESET_NAV_WINDOW')
  }

  @Mutation
  public DECREMENT_NAV_WINDOW() {
    if (this.navWindow > 0) {
      this.navWindow--
    } else {
      this.navWindow = 0
    }
  }

  @Action
  public navigateBack() {
    this.context.commit('DECREMENT_NAV_WINDOW')
  }

  @Mutation
  public INCREMENT_NAV_WINDOW() {
    this.navWindow++
  }

  @Action
  public navigateForward() {
    this.context.commit('INCREMENT_NAV_WINDOW')
  }
}
export default UI
