
import { Component, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ChatBotMenu from './ChatBotMenu.vue'
import ChatBotBusyListForm from './forms/ChatBotBusyListForm.vue'
import TasksListChatBot from './lists/TasksListChatBot.vue'
import ChatBotBtn from './ChatBotBtn.vue'
import AttendenceMeeting from './forms/AttendenceMeeting.vue'
import DailyTeamOverviewDialog from '@/components/dialogs/DailyTeamOverviewDialog.vue'
import { generateIssueID } from '@/services/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'
const ChatbotModule = namespace('ChatbotModule')
const StoreTiktrac = namespace('Timetracking')

import {
  IFnStructure,
  IDoneTask,
  ITaskDoneDetailStructure,
  INewAppointment,
} from '@/interfaces'
import type Tiktrac from 'vue/types/tiktrac'

enum RasaIntent {
  Appointment = 'action_choose_day_and_start_hour',
  CheckReminder = 'action_check_reminder',
  GenerateTaskWindow = 'action_generate_task_window',
  OtherUserTasksDone = 'get_done_another_user_tasks',
  ShowTasks = 'show_task_id_name',
  ShowUsersList = 'action_show_users_list',
}

@Component({
  components: {
    DailyTeamOverviewDialog,
    TasksListChatBot,
    ChatBotBtn,
    ChatBotBusyListForm,
    AttendenceMeeting,
  },
})
export default class ChatBotBeauty extends ChatBotMenu {
  @Ref() dailyDialogDetails!: DailyTeamOverviewDialog

  @StoreTiktrac.Action
  public doAddIssue!: (data: {
    entry: Tiktrac.BusyListElem
  }) => Promise<Tiktrac.Response>

  @ChatbotModule.State
  public isValidateTime

  @ChatbotModule.Mutation
  public setValidTime!: (val: boolean) => void

  @ChatbotModule.Action
  public sendMsgToChatbot!: (messageTxt: string) => Promise<any>

  icons: any = {
    open: {
      img: require('@/assets/chat.png'),
      name: 'default',
    },
    close: {
      img: require('@/assets/close.png'),
      name: 'default',
    },
    file: {
      img: require('@/assets/close.png'),
      name: 'default',
    },
    closeSvg: {
      img: require('@/assets/close.png'),
      name: 'default',
    },
  }

  isChatOpen = false

  dataBusyList: any = {
    dateTestStart: '',
    dateTestEnd: '',
    durationTimeTest: null,
    isValidateTime: false,
  }

  dataAttendenceMeeting: any = {
    taskData: {
      id: 0,
      name: '',
      is_completed: false,
      importance: 2,
      urgency: -1,
      description: '',
      effort: '',
      project: 0,
    },
    attendenceUsers: [],
    valueUsers: [],
  }

  /**
   * The list of all the participants of the conversation.
   *
   * @var id used to establish the author of a message
   * @var name the user name
   * @var imageUrl is supposed to be the user avatar
   */
  participants = [
    {
      id: 'user2',
      name: 'HelperBot',
      imageUrl: require('@/assets/atthene_chat_logo.svg'),
    },
  ]
  // The list of the messages to show, can be paginated and adjusted dynamically.
  messageList = [
    {
      type: 'text',
      author: `user2`,
      data: {
        text: `Hi, I'm the Atthene. I'm a first instance to demonstrate the skills of my kind! What questions do you have?`,
      },
    },
  ]
  newMessagesCount = 0
  message1 = ''
  buttonsList = []
  // to determine whether the chat window should be open or closed

  // when set to a value matching the participant.id it shows the typing indicator for the specific user
  showTypingIndicator = ''
  // specifies the color scheme for the component
  colors = {
    header: {
      bg: '#4e8cff',
      text: '#ffffff',
    },
    launcher: {
      bg: '#4e8cff',
    },
    messageList: {
      bg: '#ffffff',
    },
    sentMessage: {
      bg: '#626B74', // # bg color user
      text: '#FFFFFF',
    },
    receivedMessage: {
      bg: 'white',
      text: '#222222',
    },
    userInput: {
      bg: '#f4f7f9',
      text: '#177B7B', //input color - primary doesnt work
    },
  }
  // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
  alwaysScrollToBottom = true
  // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
  messageStyling = true

  startTimeAppoinment = 0
  endTimeAppoinment = 0
  testTaskOnly: any = {}

  get nameRules() {
    return [
      (v) => !!v || `${this.$t('dialogs.taskDialog.nameRules.enter')}`,
      (v) => v.length <= 100 || `${this.$t('dialogs.taskDialog.nameRules.length')}`,
    ]
  }

  get getTaskUrgencyIcon() {
    const urgency = this.dataAttendenceMeeting.taskData.urgency
    return ProjectsModule.getTaskUrgency(urgency).icon
  }

  get getTaskUrgencyArray() {
    const urgencies: Array<any> = []
    for (let i = 0; i < ProjectsModule.taskUrgency.length; i++) {
      urgencies.push({
        text: ProjectsModule.getTaskUrgency(i).text,
        value: i,
      })
    }
    return urgencies
  }

  get getImportanceText() {
    switch (this.dataAttendenceMeeting.taskData.importance) {
      case 0:
        return this.$t('dialogs.projectDialog.importance.unimportant')
      case 1:
        return this.$t('dialogs.projectDialog.importance.someImportant')
      case 2:
        return this.$t('dialogs.projectDialog.importance.middle')
      case 3:
        return this.$t('dialogs.projectDialog.importance.important')
      case 4:
        return this.$t('dialogs.projectDialog.importance.veryImportant')
      default:
        return ''
    }
  }

  created() {
    const helpQuery = 'help me'
    this.sendMsgToChatbot(helpQuery).then(this.reviewDataStructAndKeys)
  }

  mounted() {
    // @ts-ignore
    this.messageList.forEach((x) => (x.liked = false))
  }

  openChat() {
    this.isChatOpen = true

    this.newMessagesCount = 0
  }

  closeChat() {
    this.isChatOpen = false
  }

  validateTime() {
    const unixFormat = Math.round(
      new Date(this.dataBusyList.dateTestStart).getTime() / 1000
    )

    const testdataEnd = this.takeDataEndAppointment(unixFormat)
    const unixFormatEnd = Math.round(new Date(testdataEnd).getTime() / 1000)
    if (unixFormat > unixFormatEnd) {
      this.setValidTime(true)
    } else {
      this.setValidTime(false)
    }
  }

  handleOnType() {
    this.$root.$emit('onType')
  }

  sender() {
    const unixFormat = Math.round(
      new Date(this.dataBusyList.dateTestStart).getTime() / 1000
    )

    const testdataEnd = this.takeDataEndAppointment(unixFormat)
    const unixFormatEnd = Math.round(new Date(testdataEnd).getTime() / 1000)

    const test = `${(unixFormat * 1000).toString()}-${(
      unixFormatEnd * 1000
    ).toString()}-${this.dataBusyList.durationTimeTest}`

    const newQuery = `/intent_choose_users_list{\"date_start_hour\":\"${test}"}` //eslint-disable-line
    this.readyQueryBtn(newQuery)
  }

  takeDataEndAppointment(unixFormat: number): string {
    const date = new Date(unixFormat * 1000)
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}T${this.dataBusyList.dateTestEnd}`
  }

  showAllTeamOverview() {
    this.dailyDialogDetails.createDialog()
  }

  showAllDetailsDialog(curentTask_id) {
    const currentTask = ProjectsModule.fetchTaskByID(curentTask_id)
    currentTask?.then((task) => {
      this.editTask(task)
    })
  }

  calcDurationTime(durationTime: number): string {
    const h = Math.floor(durationTime / 60)
    const min = durationTime % 60

    return `${h}h ${min} min`
  }

  sentUserList() {
    const UserArr = []
    Object.entries(this.dataAttendenceMeeting.valueUsers).map((user: any) => {
      const userUid = user['1'].payload
      const firstSplit = userUid.split(':')
      const secondSplit = firstSplit[1].split('"')
      // @ts-ignore
      UserArr.push(secondSplit[1])
    })

    const newQuery = `/intent_generate_task_window{\"user_list\":\"${UserArr.join()}\"}` //eslint-disable-line
    this.readyQueryBtn(newQuery)
  }

  readyQueryBtn(param?: string) {
    if (!param) {
      return
    }
    this.sendMsgToChatbot(param).then(this.reviewDataStructAndKeys)
  }

  updateTask() {
    ProjectsModule.updateTaskAction({
      projectId: this.dataAttendenceMeeting.taskData.project,
      task: this.dataAttendenceMeeting.taskData,
      taskId: this.dataAttendenceMeeting.taskData.id,
    }).then((res) => {
      // delete the task updater message
      this.messageList.pop()
      // delete the message from Atthene
      this.messageList.pop()
    })
  }

  async addTask() {
    if (this.dataAttendenceMeeting.taskData.urgency < 0) {
      // user did not enter a value -> set minimal value
      this.dataAttendenceMeeting.taskData.urgency = 0
    }
    if (this.dataAttendenceMeeting.taskData.effort === '') {
      // user did not enter a value -> set minimal value
      this.dataAttendenceMeeting.taskData.effort = 0
    }
    if (this.dataAttendenceMeeting.taskData.project === 0) {
      this.dataAttendenceMeeting.taskData.project = ProjectsModule.getDefaultProjectId
    }

    try {
      const newTask = await ProjectsModule.addTaskAction({
        projectId: this.dataAttendenceMeeting.taskData.project,
        task: this.dataAttendenceMeeting.taskData,
      })

      this.testTaskOnly = newTask
      await this.addIssue()
      this.editTask(this.testTaskOnly)

      this.showSnackbar({
        text: `${this.$t('forms.quickTaskCreator.snackBars.addTaskActionSuccess')}`,
        type: 'success',
      })
    } catch (err) {
      this.showSnackbar({
        text: `${this.$t('forms.quickTaskCreator.snackBars.addTaskActionError')}`,
        type: 'error',
      })
    }

    // reset task
    this.dataAttendenceMeeting.taskData = {
      id: 0,
      name: '',
      is_completed: false,
      importance: 2,
      urgency: -1,
      description: '',
      effort: '',
      project: 0,
    }
  }

  async addIssue() {
    const { issueId } = generateIssueID()

    const issue: Tiktrac.BusyListElem = {
      id: issueId,
      start: new Date(Number(Number(this.startTimeAppoinment))).getTime(),
      end: new Date(Number(this.endTimeAppoinment)).getTime(),
      pause: 0,
      status: 1, // employee is working
      status_descr: '',
      split: 0,
      split_count: 0,
      project: this.testTaskOnly.project || 0,
      task: this.testTaskOnly.id,
      taskName: this.testTaskOnly.name,
      taskCompleted: this.testTaskOnly.is_completed,
    }

    // save issue
    await this.doAddIssue({ entry: issue }).catch((error: Tiktrac.Response) => {
      if (error.responseCode === 409) {
        // conflict
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.timesCollideError'
          )}`,
          type: 'error',
        })
      } else {
        this.showSnackbar({
          text: `${this.$t(
            'ticktrack.tables.taskTimetrackingList.snackBars.createError'
          )}`,
          type: 'error',
        })
      }
    })
    this.showSnackbar({
      text: `${this.$t(
        'ticktrack.tables.taskTimetrackingList.snackBars.createSuccess'
      )}`,
      type: 'success',
    })
  }

  doneTasksList(data: any, timerActive: boolean) {
    const { tasks_details, user_info } = data

    let taskData: IDoneTask = {
      items: [],
      timerActive,
      userName: user_info,
    }

    Object.entries(tasks_details as ITaskDoneDetailStructure).forEach(
      ([key, { name, id, duration }]) => {
        taskData.items.push({
          id,
          title: name,
          time: this.calcDurationTime(duration),
        })
      }
    )

    const msgData = { text: '', type: 'testStucture' }
    const message1 = this.generateMsgType(msgData, taskData)

    this.messageList = [...this.messageList, message1] as any
  }

  attendeesUsersList(details: any) {
    Object.entries(details).forEach(([key, values]) => {
      if (key === 'buttons') {
        ;(values as any).forEach((btnElem) => {
          const { title, payload } = btnElem

          this.dataAttendenceMeeting.attendenceUsers.push({
            title,
            payload,
          })
        })
      }
    })

    this.message1 = this.generateMsgType({ text: '', type: 'userList' })
    this.messageList = [...this.messageList, this.message1] as any
  }

  generateMsgFromRasa(text?: string, type?: string) {
    this.message1 = this.generateMsgType({ text, type })
    this.messageList = [...this.messageList, this.message1] as any
  }

  generateButton(data: any) {
    const btnData = {
      text: data.title,
      type: 'button',
      payload: data.payload,
      title: data.title,
    }
    this.message1 = this.generateMsgType(btnData)
    this.buttonsList = [...this.buttonsList, this.message1] as any
  }

  generateMsgType(dataSet, specifData?: any) {
    let msg = {
      type: 'text',
      author: 'user2',
      items: specifData,
      data: dataSet,
    } as any

    return msg
  }

  generateTaskWindow(data: any) {
    Object.entries(data).forEach(([key, values]) => {
      if (key === 'task_window') {
        const { end, start } = values as INewAppointment
        this.startTimeAppoinment = start
        this.endTimeAppoinment = end
        this.addTask()
      }
    })
  }

  executeDependsOnRasaIntent(intent: string, details?: any) {
    switch (intent) {
      case RasaIntent.Appointment:
        this.generateMsgFromRasa('', 'busyListTest')
        break
      case RasaIntent.OtherUserTasksDone:
        this.doneTasksList(details, true)
        break
      case RasaIntent.ShowTasks:
        this.doneTasksList(details, false)
        break
      case RasaIntent.ShowUsersList:
        this.attendeesUsersList(details)
        break
      case RasaIntent.GenerateTaskWindow:
        this.generateTaskWindow(details)
        break
      case RasaIntent.CheckReminder: {
        this.dataAttendenceMeeting.taskData = this.$deepCopy(
          ProjectsModule.getTasks[details.task_id]
        )
        this.message1 = this.generateMsgType({ text: '', type: 'reminderUpdate' })
        this.messageList = [...this.messageList, this.message1] as any
        break
      }
      default: {
        break
      }
    }
  }

  reviewDataStructAndKeys(data: any) {
    data.forEach((arrayItem) => {
      Object.entries(arrayItem).forEach(([key, values]) => {
        switch (key) {
          case 'text':
            this.generateMsgFromRasa(values as string)
            break

          case 'custom':
            Object.entries(values as IFnStructure).forEach(([key, value]) => {
              const {
                active,
                details: { intent, values },
              } = value

              if (active) {
                this.executeDependsOnRasaIntent(intent, values)
              }
            })
            break
          case 'buttons':
            ;(values as any).forEach((btnElem) => {
              this.generateButton(btnElem)
              this.messageList = [...this.messageList, ...this.buttonsList] as any
              this.buttonsList = []
            })
            break
          default:
            break
        }
      })
    })
  }

  onMessageWasSent(message) {
    this.messageList = [...this.messageList, message]
    let DataFromCall = this.sendMsgToChatbot(message.data.text)
    DataFromCall.then((data: any) => this.reviewDataStructAndKeys(data))
  }
}
