import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import Vue from 'vue'
import { IUserData, IUserFields } from '@/interfaces'
import { LooseObject } from 'vue/types/tiktrac'

@Module({
  name: 'user',
  store: store,
  dynamic: true,
  namespaced: true,
})
class User extends VuexModule {
  userInfo: IUserData = {
    uid: '',
    first_name: '',
    last_name: '',
    role: '',
    profile_picture: '',
    email: '',
    wage: '',
    availability: '',
    msal_active: false,
    msal_fullsync: false,
    settings: {},
    company: {
      id: 0,
      name: '',
      max_users: 0,
      subscription_expiration_date: '',
      agency_mode: false,
    },
  }
  hasLocalPicture: boolean = false
  userList: Array<IUserData> = []

  get getUserID(): string {
    return this.userInfo.uid
  }

  get getUserInfo(): IUserData {
    return this.userInfo
  }

  get getUserSettings(): LooseObject {
    return this.userInfo.settings
  }

  get getUserList(): Array<IUserData> {
    return this.userList
  }

  get getUserName(): string {
    return this.userInfo.first_name + ' ' + this.userInfo.last_name
  }

  get isUserAdmin() {
    return this.userInfo.role === 'admin'
  }

  get getProfilePicture(): string | File | null {
    if (this.hasLocalPicture) return this.userInfo.profile_picture
    const picturePath = process.env.VUE_APP_FILES_BASE_URL || ''
    return this.userInfo.profile_picture
      ? picturePath + this.userInfo.profile_picture
      : ''
  }

  get getUserById() {
    return (id: string) => {
      const users = this.getUserList || []
      return users.find((u) => u.uid === id) || null
    }
  }

  @Mutation
  setLocalPicture(newPicture: string) {
    this.userInfo.profile_picture = newPicture
    this.hasLocalPicture = true
  }

  @Mutation
  setUserInfo(userInfo: IUserData) {
    if (userInfo) this.userInfo = userInfo
  }

  @Action({ commit: 'setUserInfo' })
  async fetchUserInfo() {
    try {
      const result = await Vue.prototype.$http.get('/overall/userinfo/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  setUserList(userList: Array<IUserData>) {
    if (userList) this.userList = userList
  }

  @Action({ commit: 'setUserList' })
  async fetchUserList() {
    try {
      const result = await Vue.prototype.$http.get('/overall/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  createUser(user: IUserData) {
    if (user) this.userList.push(user)
  }

  @Action({ commit: 'createUser' })
  async createUserAction(user: IUserFields) {
    try {
      const result = await Vue.prototype.$http.post('/overall/', user) // TODO feedback if you try to add the same email multiple times
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  updateUser(user: IUserData) {
    if (user.uid === this.userInfo.uid) {
      this.userInfo = { ...user }
    }
    this.userList.forEach((userOld) => {
      if (userOld.uid === user.uid) {
        userOld.first_name = user.first_name
        userOld.last_name = user.last_name
        userOld.wage = user.wage
        userOld.availability = user.availability
        userOld.role = user.role
      }
    })
  }

  @Action({ commit: 'updateUser' })
  async updateUserAction(user: IUserFields) {
    try {
      const result = await Vue.prototype.$http.put(
        '/overall/keycloak/' + user.uid + '/',
        user
      )
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteUser(userid: string) {
    this.userList = this.userList.filter((user) => user.uid != userid)
  }

  @Action({ commit: 'deleteUser' })
  async deleteUserAction(userid: string) {
    try {
      await Vue.prototype.$http.delete('/overall/keycloak/' + userid + '/')
      return userid
    } catch (e) {
      console.log(e)
    }
  }
}

export const UserModule = getModule(User)
