
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import NotificationItem from '@/components/lists/NotificationItem/NotificationItem.vue'

@Component({
  components: {
    NotificationItem,
  },
})
export default class QuickNotificationItem extends Vue {
  @Prop() value? = false
  @Prop()
  item: any

  show = this.value
  timeout = -1
  dismissible = true

  @Watch('show')
  emitShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.$emit('input', val)
    if (!val) return
    this.dismissible = true
    this.timeout = 10000
  }
  @Watch('value')
  toggleShow(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    this.show = val
  }
}
