
import { Component, Prop, Vue, PropSync, Model } from 'vue-property-decorator'
import { isProp } from '@/services/common'
import { namespace } from 'vuex-class'
const ChatbotModule = namespace('ChatbotModule')

@Component
export default class ChatBotBusyListForm extends Vue {
  @Model('dataBusyList', { type: Object }) dataBusyList!: any

  @ChatbotModule.State
  public isValidateTime

  @ChatbotModule.Mutation
  public setValidTime!: (val: boolean) => void
}
