
import { Component, Vue } from 'vue-property-decorator'
import NavigationDrawerContent from './NavigationDrawerContent.vue'

@Component({
  components: {
    NavigationDrawerContent,
  },
})
export default class NavigationDrawerMobile extends Vue {
  value = false
  /**
   * toggleDrawer
   */
  public toggleDrawer() {
    this.value = !this.value
  }
}
