
import { Component, Prop, Vue } from 'vue-property-decorator'
import { isProp } from '@/services/common'

@Component
export default class BaseButton extends Vue {
  @Prop({ default: 'primary' }) private color!: string
  @Prop() private xSmall!: string | undefined
  @Prop() private text!: string
  @Prop() private disabled!: boolean
  @Prop() private loading!: boolean

  @Prop({ type: [String, Boolean] })
  readonly outlined?: string | boolean

  @Prop({ type: [String, Boolean] })
  readonly plain?: string | boolean

  get isPlain() {
    return isProp(this.plain)
  }
}
