
import { INotificationItem } from '@/interfaces'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class NotificationItem extends Vue {
  @Prop()
  item!: INotificationItem

  notificationMsg(verb) {
    switch (verb) {
      case 'assigned_project':
        return `A project has been assigned to you`
      case 'assigned_task':
        return `A task has been assigned to you`
    }
  }
  openNotification() {
    this.$emit('itemClick', this.item.verb, this.item.target_object_id, this.item.slug)
  }
}
