import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/store'
import { IProject, IProjectList, ITask, ITaskList, ITag, ITagList } from '@/interfaces'
import Vue from 'vue'
import i18N from '@/plugins/i18n'
import { getAccessMSToken } from '@/plugins/msal'

@Module({
  name: 'projects',
  store: store,
  dynamic: true,
  namespaced: true,
})
class Projects extends VuexModule {
  // flags
  isInitializedProjects = false
  isInitializedTasks = false
  isInitializedTags = false
  isInitializingProjects = false
  isInitializingTasks = false
  isInitializingTags = false
  // data
  projectsList: IProjectList = {}
  taskList: ITaskList = {}
  tagList: ITagList = {}
  /*
   * The urgency of a task is saved as an integer from 0 to 4.
   * This array maps the urgency to an object containing the
   * icon and text respectively
   */
  taskUrgency = [
    {
      icon: require('@/assets/icon_urgency_1.svg'),
      text: i18N.t('homePage.quickTaskCreator.taskUrgency.notUrgent'),
    },
    {
      icon: require('@/assets/icon_urgency_2.svg'),
      text: i18N.t('homePage.quickTaskCreator.taskUrgency.putOff'),
    },
    {
      icon: require('@/assets/icon_urgency_3.svg'),
      text: i18N.t('homePage.quickTaskCreator.taskUrgency.schedule'),
    },
    {
      icon: require('@/assets/icon_urgency_4.svg'),
      text: i18N.t('homePage.quickTaskCreator.taskUrgency.urgent'),
    },
  ]

  get needToInitProjects(): boolean {
    return !this.isInitializedProjects && !this.isInitializingProjects
  }

  get needToInitTasks(): boolean {
    return !this.isInitializedTasks && !this.isInitializingTasks
  }

  get needToInitTags(): boolean {
    return !this.isInitializedTags && !this.isInitializingTags
  }

  /**
   * Get all projects that the current user is part of
   */
  get getProjects(): IProjectList {
    const userId = this.context.rootGetters['user/getUserID']
    return Object.fromEntries(
      Object.entries(this.projectsList).filter(([_, project]: Array<any>) =>
        project.users.includes(userId)
      )
    )
  }

  /**
   * Get all projects of the company (admin)
   */
  get getAllProjects(): IProjectList {
    return this.projectsList
  }

  /**
   * Get the default project ID of the current user
   */
  get getDefaultProjectId() {
    const defaultProject = Object.values(this.getProjects).find(
      (p) => p.is_default
    )
    return defaultProject?.id || 0
  }

  get getTasks(): ITaskList {
    const userId = this.context.rootGetters['user/getUserID']
    // show tasks that are assigned to nobody or to the requesting user
    const tasksFiltered = Object.entries(this.taskList).filter(([key, task]) => {
      return (
        (!task.assigned_to && task.user === userId) || task.assigned_to_id === userId
      )
    })
    return Object.fromEntries(tasksFiltered)
  }

  get getAllTags(): ITagList {
    return this.tagList
  }

  get getTaskUrgency() {
    return (value: number) => {
      return this.taskUrgency[value] || {}
    }
  }

  get getTasksByProjectId() {
    return (id: number): ITaskList => {
      const tasks = {}
      const taskIds = this.projectsList[id]?.tasks
      if (!taskIds) {
        return tasks
      }
      for (const taskId of taskIds) {
        const task = this.taskList[taskId]
        if (!task) {
          // TODO fetch missing task
          continue
        }
        tasks[taskId] = task
      }
      return tasks
    }
  }

  @Mutation
  setIsInitializingProjects(value: boolean) {
    this.isInitializingProjects = value
  }

  @Mutation
  setIsInitializingTasks(value: boolean) {
    this.isInitializingTasks = value
  }

  @Mutation
  setIsInitializingTags(value: boolean) {
    this.isInitializingTags = value
  }

  @Mutation
  setProjects(projects: IProjectList) {
    if (projects) {
      this.projectsList = projects
      this.isInitializedProjects = true
      this.isInitializingProjects = false
    }
  }

  @Action({ commit: 'setProjects' })
  async fetchProjects(force = false) {
    if (!this.needToInitProjects && !force) {
      return
    }
    this.setIsInitializingProjects(true)
    try {
      const result = await Vue.prototype.$http.get('/api/project/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  addProject(newProject: IProject) {
    // only add on success
    if (newProject) Vue.set(this.projectsList, newProject.id, newProject)
  }
  @Action({ commit: 'addProject' })
  async addProjectAction(newProject: IProject) {
    // convert the date for the backend
    if (newProject.due_date)
      newProject.due_date = new Date(newProject.due_date).toISOString()
    try {
      const result = await Vue.prototype.$http.post('/api/project/', newProject)
      return result.data
    } catch (e) {
      console.error({ error: e })
      if (e?.response?.status === 409) {
        // duplicate project name
        this.context.dispatch(
          'UI/showSnackbar',
          {
            text: `${i18N.t('modules.projectsModule.projectExists')}`,
            type: 'error',
          },
          { root: true }
        )
      } else {
        // other error
        this.context.dispatch(
          'UI/showSnackbar',
          {
            text: `${i18N.t('modules.projectsModule.errorCreating')}`,
            type: 'error',
          },
          { root: true }
        )
      }
    }
  }

  @Mutation
  updateProject(project: IProject) {
    // trigger change detection
    Vue.set(this.projectsList, project.id, project)
  }
  @Action({ commit: 'updateProject' })
  async updateProjectAction(editedProject: IProject) {
    // convert the date for the backend
    if (editedProject.due_date)
      editedProject.due_date = new Date(editedProject.due_date).toISOString()
    try {
      const result = await Vue.prototype.$http.put(
        `/api/project/${editedProject.id}/`,
        editedProject
      )
      return result.data
    } catch (e) {
      console.error({ error: e })
      if (e?.response?.status === 409) {
        // duplicate project name
        this.context.dispatch(
          'UI/showSnackbar',
          {
            text: `${i18N.t('modules.projectsModule.projectExists')}`,
            type: 'error',
          },
          { root: true }
        )
      } else {
        // other error
        this.context.dispatch(
          'UI/showSnackbar',
          {
            text: `${i18N.t('modules.projectsModule.errorCreating')}`,
            type: 'error',
          },
          { root: true }
        )
      }
    }
  }

  @Mutation
  deleteProject(id: number) {
    // TODO it seems redundant to have the tasks in two places
    // delete item with the given id and ensure reactivity
    Vue.delete(this.projectsList, id)
    // delete all tasks with deleted project ID
    for (const task of Object.values(this.taskList)) {
      if (task.project === id) {
        delete this.taskList[task.id]
      }
    }
    this.taskList = { ...this.taskList }
  }
  @Action({ commit: 'deleteProject' })
  async deleteProjectAction(id: number) {
    try {
      await Vue.prototype.$http.delete(`/api/project/${id}/`)
      // just pass the id here, since on a successful response we can assume the item got deleted
      return id
    } catch (e) {
      console.error({ error: e })
      this.context.dispatch(
        'UI/showSnackbar',
        {
          text: `${i18N.t('modules.projectsModule.projectCantBeDeleted')}`,
          type: 'error',
        },
        { root: true }
      )
    }
  }

  /**
   * Tasks
   */

  @Mutation
  setTask(task: ITask) {
    if (task) {
      this.taskList[task.id] = task
    }
  }

  @Mutation
  setTasks(tasks: ITaskList) {
    // TODO this should also update the tasks for each project in `this.projectsList`
    if (tasks) {
      this.taskList = tasks
      this.isInitializedTasks = true
      this.isInitializingTasks = false
    }
  }

  @Action({ commit: 'setTasks' })
  async fetchAllTasks(force = false) {
    if (!this.needToInitTasks && !force) {
      return
    }
    this.setIsInitializingTasks(true)
    try {
      const result = await Vue.prototype.$http.get('/api/task/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }
  @Action
  async fetchTaskByID(id: number) {
    try {
      const result = await Vue.prototype.$http.get(`/api/task/${id}/`)
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  addTask(task: ITask) {
    if (!task || !task.project) {
      return
    }
    // TODO it seems redundant to have the tasks in two places
    this.projectsList[task.project].tasks.push(task.id)
    Vue.set(this.taskList, task.id, task)
  }

  @Action({ commit: 'addTask' })
  async addTaskAction(addObject: { projectId: number; task: ITask }) {
    try {
      const body = { ...addObject.task, project: addObject.projectId }
      const result = await Vue.prototype.$http.post('/api/task/', body)
      return result.data
    } catch (e) {
      console.log(e)
    }
  }
  @Mutation
  updateTask(task: ITask) {
    if (!task || !task.project) {
      return
    }
    //trigger change detection
    Vue.set(this.taskList, task.id, task)
  }
  @Action({ commit: 'updateTask' })
  async updateTaskAction(addObject: {
    projectId: number
    task: ITask
    taskId: number
  }) {
    try {
      const ms_auth = await getAccessMSToken()
      const body = { ...addObject.task, project: addObject.projectId }
      const result = await Vue.prototype.$http.put(
        `/api/task/${addObject['taskId']}/?ms_auth=${ms_auth}`,
        body
      )

      const finalData = result.data
      const activeTaskId = this.context.rootGetters['Timetracking/getActiveTaskId']

      if (finalData.is_completed && activeTaskId === finalData.id) {
        this.context.dispatch('Timetracking/doCheckOutWhenTaskCompleted', finalData, {
          root: true,
        })
      }
      return finalData
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteTask(ids: { id: number; projectId: number | undefined }) {
    // find the index of the deleted element in the array
    Vue.delete(this.taskList, ids.id)
    if (ids.projectId) {
      const idx = this.projectsList[ids.projectId].tasks.findIndex(
        (taskId) => taskId === ids.id
      )
      this.projectsList[ids.projectId].tasks.splice(idx, 1)
    }
  }
  @Action({ commit: 'deleteTask' })
  async deleteTaskAction(ids: { id: number; projectId: number | undefined }) {
    const ms_token = await getAccessMSToken()
    try {
      await Vue.prototype.$http.delete(`/api/task/${ids.id}/?ms_auth=${ms_token}`)
      // just pass the id here, since on a successful response we can assume the item got deleted
      return ids
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Tags
   */

  @Mutation
  setTags(tags: ITagList) {
    if (tags) {
      this.tagList = tags
      this.isInitializedTags = true
      this.isInitializingTags = false
    }
  }

  @Action({ commit: 'setTags' })
  async fetchAllTags(force = false) {
    if (!this.needToInitTags && !force) {
      return
    }
    this.setIsInitializingTags(true)
    try {
      const result = await Vue.prototype.$http.get('/overall/tasks/tags/')
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Action({ commit: 'setTask' })
  async fetchTagsForTask(taskId) {
    if (!taskId) {
      return []
    }
    const task = this.taskList[taskId]
    if (!task) {
      return []
    }
    try {
      const result = await Vue.prototype.$http.get(`/overall/tasks/${taskId}/tags/`)
      return result.data
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  addTagToTask(addObject: { tag: ITag; taskId: number } | undefined) {
    if (!addObject) {
      return
    }
    if (!this.taskList[addObject.taskId].tags) {
      this.taskList[addObject.taskId].tags = []
    }
    this.taskList[addObject.taskId].tags!.push(addObject.tag.id)
    Vue.set(this.tagList, addObject.tag.id, addObject.tag)
  }

  @Action({ commit: 'addTagToTask' })
  async addTagToTaskAction(addObject: { tag: ITag; taskId: number }) {
    try {
      const result = await Vue.prototype.$http.post(
        `/overall/tasks/${addObject.taskId}/tags/`,
        addObject.tag
      )
      return {
        tag: { ...result.data },
        taskId: addObject.taskId,
      }
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  deleteTagFromTask(ids: { tagId: number; taskId: number }) {
    // Vue.delete(this.tagList, ids.tagId)
    // find the index of the deleted element in the array
    const tags = this.taskList[ids.taskId].tags
    if (!tags) {
      return
    }
    const idx = tags.findIndex((tagId) => tagId === ids.tagId)
    tags.splice(idx, 1)
  }

  @Action({ commit: 'deleteTagFromTask' })
  async deleteTagFromTaskTaskAction(ids: { tagId: number; taskId: number }) {
    try {
      await Vue.prototype.$http.delete(
        `/overall/tasks/${ids.taskId}/tags/${ids.tagId}/`
      )
      // just pass the id here, since on a successful response we can assume the item got deleted
      return ids
    } catch (e) {
      console.log(e)
    }
  }
}

export const ProjectsModule = getModule(Projects)
