
import { Component, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import InlineEdit from '@/components/forms/InlineEdit.vue'
import TextEditor from '@/components/forms/TextEditor.vue'
import TaskTimetrackingList from '@/components/tiktrac/tables/TaskTimetrackingList.vue'
import BaseMenuButton from '@/components/ui/BaseMenuButton.vue'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { UserModule } from '@/store/modules/userModule'

import { IDialog, ISnackbar, ITag, ITagList, ITask, IUserData } from '@/interfaces'
import type Tiktrac from 'vue/types/tiktrac'
import { getAccessMSToken } from '@/plugins/msal'
import { getMonth } from '@/services/tiktrac'

const StoreUI = namespace('UI')
const StoreTiktrac = namespace('Timetracking')

@Component({
  components: {
    BaseMenuButton,
    InlineEdit,
    TaskTimetrackingList,
    TextEditor,
  },
})
export default class TaskDialog extends Vue {
  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  @StoreTiktrac.Getter
  public getBusyList!: Tiktrac.BusyList

  @StoreTiktrac.Action
  public fetchTimetracking!: (month: string) => Promise<Tiktrac.Response>

  abort = false
  dialog = false
  isEdit = false
  disabled = {
    assigned_to_id: false,
    effort: false,
    is_completed: false,
    name: false,
    project: false,
    urgency: false,
    importance: false,
  }
  // tabs
  tab = 0
  // tags
  tagInput: ITag | string | null = ''
  // checklist
  checklistAddFormInput = ''
  checklistAddForm = false

  highlightedTiktracEntry = ''
  taskData: ITask = {
    id: 0,
    name: '',
    is_completed: false,
    urgency: 0,
    importance: 0,
    description: '',
    created: '',
    tags: [],
    notes: {},
    project: 0,
  }

  taskConvertationsIsLoading = false

  busyList = {}

  microsoftSwitcherIsHovered = false

  // validator
  get nameRules() {
    return [
      (v) => !!v || `${this.$t('dialogs.taskDialog.nameRules.enter')}`,
      (v) => v.length <= 100 || `${this.$t('dialogs.taskDialog.nameRules.length')}`,
    ]
  }
  get numberRules() {
    return [
      (v) =>
        typeof v === 'number' ||
        `${this.$t('dialogs.taskDialog.numberRules.positive')}`,
      (v) => v >= 0 || `${this.$t('dialogs.taskDialog.numberRules.positive')}`,
    ]
  }

  get getProjectList() {
    return Object.values(ProjectsModule.getProjects)
  }

  get getUserList() {
    const project = ProjectsModule.getProjects[this.taskData.project || 0]
    return (
      UserModule.getUserList?.filter((user) => project?.users.includes(user.uid)) || []
    )
  }

  get createdBy() {
    const creator_id = this.taskData.user || ''
    const creator = UserModule.getUserById(creator_id)
    if (!creator) {
      return ''
    }
    return creator.first_name + ' ' + creator.last_name
  }

  get creatorData() {
    const creator_id = this.taskData.user || ''
    const creator = UserModule.getUserById(creator_id)

    return creator
  }

  get getTaskUrgencyIcon() {
    const urgency = this.taskData.urgency
    return ProjectsModule.getTaskUrgency(urgency).icon
  }

  get getTaskUrgencyArray() {
    const urgencies: Array<any> = []
    for (let i = 0; i < ProjectsModule.taskUrgency.length; i++) {
      urgencies.push({
        text: ProjectsModule.getTaskUrgency(i).text,
        value: i,
      })
    }
    return urgencies
  }
  get getImportanceText() {
    if (!this.isEdit) {
      return this.$t('dialogs.projectDialog.importance.title')
    }
    switch (this.taskData.importance) {
      case 0:
        return this.$t('dialogs.projectDialog.importance.unimportant')
      case 1:
        return this.$t('dialogs.projectDialog.importance.someImportant')
      case 2:
        return this.$t('dialogs.projectDialog.importance.middle')
      case 3:
        return this.$t('dialogs.projectDialog.importance.important')
      case 4:
        return this.$t('dialogs.projectDialog.importance.veryImportant')
      default:
        return ''
    }
  }

  get getAllTags(): Array<ITag> {
    let allTags = this.$deepCopy(ProjectsModule.getAllTags) as ITagList
    this.getTaskTags.forEach((tag: ITag) => {
      // remove tags that are already used for this task
      delete allTags[tag.id]
    })
    // return array sorted by name
    return Object.values(allTags).sort((a, b) =>
      a.name === b.name ? 0 : a.name < b.name ? -1 : 1
    )
  }

  get getTaskTags(): Array<ITag> {
    let tags = [] as Array<ITag>

    this.taskData.tags?.forEach((tagId) => {
      if (ProjectsModule.getAllTags[tagId]) {
        tags.push(ProjectsModule.getAllTags[tagId])
      }
    })
    // return array sorted by name
    return tags.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
  }

  get getChecklist() {
    return ProjectsModule.getTasks[this.taskData?.id]?.notes?.checklist || []
  }

  get getTaskIsNotEmpty() {
    for (const t in this.getBusyList) {
      const elem = this.getBusyList[t]
      if (elem.task == this.taskData.id) return true
    }
    return false
  }

  get getMsStatus() {
    let list = {}
    for (let entry in this.busyList) {
      if (this.busyList[entry].task === this.taskData.id) {
        list[entry] = this.busyList[entry]
      }
    }
    for (let i in list) {
      if (!list[i].is_ms_event) {
        return false
      } else {
        return true
      }
    }

    return false
  }

  @Watch('dialog')
  async onDialogToggle(val: boolean, oldVal: boolean) {
    if (val === oldVal) return
    if (!val) {
      // on close
      this.save('description')
      this.initDialog()
      this.initData()
    } else {
      // on open
      this.initDialog()
      // quickfix: this fetches the task tags only when needed to improve the performance for `GET /api/task/`
      const task = await ProjectsModule.fetchTagsForTask(this.taskData.id)
      this.taskData.tags = task.tags
    }
  }

  @Watch('getBusyList')
  recalculateMsStatus() {
    this.busyList = this.$deepCopy(this.getBusyList)
  }

  mounted() {
    // dependencies
    ProjectsModule.fetchProjects()
    ProjectsModule.fetchAllTags()
    UserModule.fetchUserList()
    this.busyList = this.$deepCopy(this.getBusyList)
  }

  initDialog() {
    this.abort = false
  }

  initData() {
    this.taskData = {
      id: 0,
      name: '',
      is_completed: false,
      importance: 0,
      urgency: 0,
      description: '',
      created: '',
      tags: [],
      notes: {},
      project: 0,
    }
    this.isEdit = false
  }

  setDisabled(value: boolean, property = '') {
    if (property) {
      if (Object.prototype.hasOwnProperty.call(this.disabled, property)) {
        this.disabled[property] = value
      }
    } else {
      for (const prop in this.disabled) {
        this.disabled[prop] = value
      }
    }
  }

  saveTag() {
    if (!this.tagInput) {
      return
    }
    let tag = { id: 0, name: '', created_by: '' }
    if (typeof this.tagInput === 'string') {
      tag.name = this.tagInput
    } else {
      const tags = ProjectsModule.getTasks[this.taskData.id].tags
      if (!tags || tags.includes(this.tagInput.id)) {
        // tag already in list
        this.$nextTick(() => {
          this.tagInput = ''
        })
        return
      }
      tag = this.tagInput
    }
    ProjectsModule.addTagToTaskAction({
      taskId: this.taskData.id,
      tag: tag,
    }).finally(() => {
      this.tagInput = ''
    })
  }

  deleteTag(tagId: number) {
    ProjectsModule.deleteTagFromTaskTaskAction({
      tagId: tagId,
      taskId: this.taskData.id,
    })
  }

  saveChecklistItem() {
    if (this.checklistAddFormInput === '') {
      return
    }
    if (!this.taskData.notes.checklist) {
      this.taskData.notes.checklist = []
    }
    this.taskData.notes.checklist.push({
      value: false,
      text: this.checklistAddFormInput,
    })

    this.save('notes', () => {
      // TODO When the checklist was empty before this, the DOM is not updated.
      // update local task representation
      this.$set(
        this.taskData.notes,
        'checklist',
        ProjectsModule.getTasks[this.taskData.id].notes.checklist
      )

      this.resetChecklistAddForm()
    })
  }

  async toggleChecklistItem(item: { value: boolean; text: string }) {
    item.value = !item.value

    await this.save('notes', () => {
      // on success
      // update local task representation
      this.$set(
        this.taskData.notes,
        'checklist',
        ProjectsModule.getTasks[this.taskData.id].notes.checklist
      )
    })
    // on error
    item.value = !item.value
  }

  resetChecklistAddForm() {
    this.checklistAddForm = false
    this.checklistAddFormInput = ''
  }

  async updateMicrosoftSyncronization() {
    const ms_auth = await getAccessMSToken()
    this.taskConvertationsIsLoading = true
    const intent = !this.getMsStatus
    if (intent) {
      Vue.prototype.$http
        .post('/overall/convert-to-microsoft/', {
          task: this.taskData,
          ms_auth: ms_auth,
          intent: 'convert',
        })
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.taskConvertationsIsLoading = false
          const month = getMonth()

          this.fetchTimetracking(month)
        })
    } else {
      Vue.prototype.$http
        .post('/overall/convert-to-microsoft/', {
          task: this.taskData,
          ms_auth: ms_auth,
          intent: 'reconvert',
        })
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.taskConvertationsIsLoading = false
          const month = getMonth()

          this.fetchTimetracking(month)
        })
    }
  }

  async save(varName: string | null = null, callback: Function | null = null) {
    if (this.abort) {
      // no save on abort (e.g. on keypress ESC)
      return
    }
    if (varName !== null) {
      /**
       * update task
       */
      const task = ProjectsModule.getTasks[this.taskData?.id]
      if (!task) {
        return false
      }
      if (!Object.prototype.hasOwnProperty.call(task, varName)) {
        return false
      }
      if (!(await this.saveProperty(varName, task))) {
        this.taskData[varName] = task[varName]
      } else if (callback) {
        callback()
      }
    } else {
      /**
       * create new task
       */
      // verify task
      if (!this.taskData.name) {
        this.showSnackbar({
          text: `${this.$t('dialogs.taskDialog.snackBars.enterNameWarning')}`,
          type: 'warning',
        })
        return
      }
      // set default project when not set
      if (!this.taskData.project) {
        this.taskData.project =
          Object.values(ProjectsModule.getProjects).find((p) => p.is_default)?.id || 0
      }
      // start creation process
      this.setDisabled(true)
      // create task
      const newTask = await ProjectsModule.addTaskAction({
        projectId: this.taskData.project || 0,
        task: this.taskData,
      })
      if (!newTask) {
        // abort creation process
        this.setDisabled(false)
        return
      }
      // update task in popup
      this.taskData = { ...newTask }
      // finish creation process
      this.setDisabled(false)
      this.isEdit = true
    }
  }

  async saveProperty(varName: string, task: ITask): Promise<boolean> {
    /**
     * update helper
     */
    // only update primitive values on changes
    // (non-primitive values are always updated)
    if (typeof this.taskData[varName] !== 'object') {
      if (this.taskData[varName] === task[varName]) {
        console.log('NO CHANGES')
        return true
      }
    }
    // check if input is valid
    const ref = this.$refs[`ref-${varName}`] as any
    if (ref && !ref.valid) {
      return false
    }
    // start update process
    this.setDisabled(true, varName)
    // save changes to one property
    const taskData = {} as ITask
    taskData[varName] = this.taskData[varName]
    const data = {
      projectId: this.taskData.project || 0,
      task: taskData,
      taskId: task.id,
    }
    if (!(await ProjectsModule.updateTaskAction(data))) {
      this.showSnackbar({
        text: `${this.$t('dialogs.taskDialog.snackBars.updateTaskError')}`,
        type: 'error',
      })
      this.setDisabled(false, varName)
      return false
    }
    this.setDisabled(false, varName)
    return true
  }

  async deleteTask() {
    const consent = await this.showDialog({
      text: `${this.$t('dialogs.taskDialog.snackBars.deleteTaskWarning')}`,
      type: 'warning',
    })
    if (!consent) {
      return
    }
    ProjectsModule.deleteTaskAction({
      id: this.taskData.id,
      projectId: this.taskData.project,
    })
      .then(() => {
        this.dialog = false
        this.showSnackbar({
          text: `${this.$t('dialogs.taskDialog.snackBars.deleteTaskSuccess')}`,
        })
      })
      .catch(() => {
        this.showSnackbar({
          text: `${this.$t('dialogs.taskDialog.snackBars.deleteTaskError')}`,
          type: 'error',
        })
      })
  }

  updateSelProjects(val: number) {
    this.taskData.project = val
    this.save('project')
  }

  filterUserList(item, queryText) {
    let name = item.first_name + ' ' + item.last_name
    name = name.toLowerCase()
    const q = queryText.toLowerCase()
    return name.indexOf(q) > -1
  }

  public editTask(task: ITask) {
    this.taskData = { ...task }
    this.taskData.project = task.project || 0
    this.isEdit = true
    this.dialog = true
  }

  public createTask(projectId = 0) {
    this.initData()
    if (projectId) {
      this.taskData.project = projectId
    }
    this.dialog = true
  }

  public editTimetracking(item: Tiktrac.BusyListElem) {
    const task = ProjectsModule.getTasks[item.task || 0]
    if (!task) {
      this.showSnackbar({
        text: `${this.$t('dialogs.taskDialog.snackBars.entryWarning')}`,
        type: 'warning',
      })
      return
    }
    this.tab = 1
    this.taskData = { ...task }
    this.isEdit = true
    this.dialog = true
    this.highlightedTiktracEntry = item.id
  }
}
