import Vue from 'vue'

import type Tiktrac from 'vue/types/tiktrac'

export function getMonth(timestamp = 0) {
  const date = new Date()
  let dateString = ''

  if (timestamp > 0) {
    date.setTime(timestamp)
  }
  // 2021-06-07T14:35:50.833Z => 2106
  dateString = Vue.prototype.$moment(date).format('YYMM')

  return dateString
}

export function generateIssueID() {
  // get current timestamp in seconds
  // TODO This could be manipulated by setting the system date/time to any value
  const now = Date.now()
  // prettier-ignore
  const issueId = now.toString() + '_' + Math.random().toString(36).substr(2, 4)

  return {
    issueId,
    now,
  }
}

/**
 * Use this to set the end of an issue to now (only for display purposes).
 */
export function setIssueEndToNow(issue: Tiktrac.BusyListElem) {
  if (!issue) {
    return
  }
  const now = Date.now()
  // only update the end if 15 minutes passed since start
  if (now - issue.start >= 15 * 60 * 1000) {
    issue.end = now
  }
}
