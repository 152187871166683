import _Vue from 'vue'
import Axios, { AxiosRequestConfig, AxiosStatic } from 'axios'
import { authService } from '@/services'
import i18N from '@/plugins/i18n'

export function AxiosPlugin<AxiosPlugOptions>(
  Vue: typeof _Vue,
  options?: AxiosPluginOptions
): void {
  Vue.prototype.$http = Axios.create({
    baseURL: options?.baseUrl,
  }) as AxiosStatic
  Vue.prototype.$http.interceptors.request.use(async (config: AxiosRequestConfig) => {
    // set authorization token
    const token = await authService.updateToken()
    config.headers.common['Authorization'] = 'Bearer ' + token
    // set custom header for timezone (offset in hours compared to UTC)
    config.headers.common['X-Timezone-Offset'] = new Date().getTimezoneOffset() / -60
    // set custom header for user preferred language
    config.headers.common['X-Language'] = i18N.locale || 'en'
    return config
  })
}

export class AxiosPluginOptions {
  baseUrl = ''
}
