
import { Component, Vue, Watch, Ref, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import BaseButton from '@/components/ui/BaseButton.vue'

import { ProjectsModule } from '@/store/modules/projectsModule'
import { UserModule } from '@/store/modules/userModule'

import type Tiktrac from 'vue/types/tiktrac'
import type { VForm } from 'vue/types/extend'
import { decimalToHours, formatterDate, formatterTime } from '@/services/datetime'
import { TimetrackingState } from '@/store/modules/tiktrac/timetracking'
import { ISnackbar, IDialog, IProject, ITask } from '@/interfaces'

const StoreTiktrac = namespace('Timetracking')
const StoreUI = namespace('UI')

interface Segment {
  /**
   * current circular progress in X %
   */
  progressPercent: number
  /**
   * circular progress start point in deg
   * 270 deg == 12 o'clock
   *   0 deg ==  3 o'clock
   */
  rotate: number
  /**
   * timestamp indicating the start time of the segment
   */
  startTime: number
  /**
   * enum indicating wheather the segment represents an active or a pause state
   */
  state: TimetrackingState
}

@Component({
  components: {
    BaseButton,
  },
})
export default class TiktracMini extends Vue {
  @StoreTiktrac.Getter
  public getIsInitializing!: boolean

  @StoreTiktrac.Getter
  public getActiveStartTime!: number

  @StoreTiktrac.Getter
  public getTimetracking!: Tiktrac.Timetracking

  @StoreTiktrac.Getter
  public getActiveTimer!: string

  @StoreTiktrac.Getter
  public getActiveTimerLimit!: number | null

  @StoreTiktrac.Getter
  public getActiveTaskId!: number

  @StoreTiktrac.Getter
  public getActivePause!: number | null

  @StoreTiktrac.Getter
  public getActivePauses!: Array<Tiktrac.PausesEntry>

  @StoreTiktrac.Getter
  public getPauseTime!: number

  @StoreTiktrac.Getter
  public getState!: TimetrackingState

  @StoreTiktrac.Action
  public fetchTimetracking!: () => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public checkIn!: (task: ITask | undefined) => Promise<boolean>

  @StoreTiktrac.Action
  public doCheckOut!: (data: {
    intent: string
    issueId: string
    time: number
    project: number
    taskName: string
    taskId: number
  }) => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public doPauseStart!: (data: {
    intent: string
    issueId: string
    time: number
  }) => Promise<Tiktrac.Response>

  @StoreTiktrac.Action
  public doPauseEnd!: (data: {
    intent: string
    issueId: string
    time: number
  }) => Promise<Tiktrac.Response>

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @Prop() readonly task!: ITask | undefined
  @Ref('form') readonly form!: VForm

  isTimerInitialized = false
  dialogCheckOut = false
  dialogMobile = false
  now = Date.now()

  /**
   * Timestamp of the checkout
   */
  checkOutTime = 0

  /**
   * 100% value of circular progress in milliseconds
   */
  constProgressFull = 12 * 60 * 60 * 1000 // 12 hours in milliseconds

  /**
   * pause and working segments of the circular progress
   */
  segments: Array<Segment> = []
  /**
   * tracked working time in milliseconds
   */
  timePassed = 0
  /**
   * tracked pause time in milliseconds
   */
  pauseTimePassed = 0
  /**
   * project ID which the current time tracking is for
   */
  selectedProject = 0
  /**
   * task name which the current time tracking is for
   */
  selectedTaskName: string | Tiktrac.LooseObject = ''

  /**
   * interval pointer generated by `setInterval()`
   */
  intervalDateTime: number | null = null
  /**
   * interval pointer generated by `setInterval()`
   */
  intervalTimetracking: number | null = null
  /**
   * interval pointer generated by `setInterval()`
   */
  intervalPause: number | null = null

  formValid = false
  rulesName = [(v) => !!v || 'Required']
  selProjects: Array<any> = []
  selTasks: Array<any> = []
  selTaskChangeProject = false
  isTaskDone = false
  isActivePlusButton = false
  inputTask: HTMLSelectElement | string = ''
  currentTaskNameNotCheckin: string | undefined = ''

  /**
   * flag
   */
  checkInProcess = false
  /**
   * flag
   */
  checkOutProcess = false
  /**
   * flag
   */
  togglePauseProcess = false

  get passedTimeFormatted() {
    return new Date(this.timePassed).toISOString().substr(11, 8)
  }

  get pauseTimeFormatted() {
    if (this.pauseTimePassed) {
      return new Date(this.pauseTimePassed).toISOString().substr(11, 8)
    } else {
      return ''
    }
  }

  get isActive() {
    return this.getState === TimetrackingState.ACTIVE
  }

  get isInactive() {
    return (
      this.getState === TimetrackingState.INACTIVE ||
      this.getState === TimetrackingState.UNKNOWN
    )
  }

  get isPaused() {
    return this.getState === TimetrackingState.PAUSED
  }

  get curSegmentRotate() {
    const segLen = this.segments.length
    return !segLen ? 0 : this.segments[segLen - 1].rotate
  }

  get curSegmentProgressPercent() {
    const segLen = this.segments.length
    return !segLen ? 0 : this.segments[segLen - 1].progressPercent
  }

  get curSegmentColor() {
    const segLen = this.segments.length
    if (!segLen) {
      return 'primary'
    }
    return this.segments[segLen - 1].state === TimetrackingState.ACTIVE
      ? 'primary'
      : 'warning'
  }

  get curDateTime() {
    return formatterDate.format(this.now) + '&emsp;' + formatterTime.format(this.now)
  }

  get getProjects() {
    return ProjectsModule.getProjects
  }

  get getWorkedHoursString() {
    const pause = this.getActivePause || 0
    const wh = this.checkOutTime - this.getActiveStartTime - pause
    return decimalToHours(wh / 1000 / 60 / 60, false)
  }

  get getCurrentTaskName() {
    return ProjectsModule.getTasks[this.getActiveTaskId]?.name || ''
  }

  getSelProjectList() {
    this.selProjects = [
      {
        text: `${this.$t('ticktrack.ticktrackMini.getSelProjectList')}`,
        value: 0,
      },
    ]
    for (const p of Object.values(ProjectsModule.getProjects)) {
      this.selProjects.push({
        text: p.name,
        value: p.id,
      })
    }
  }

  getSelTaskList() {
    this.selTasks = []
    for (const t of Object.values(ProjectsModule.getTasks) as Array<ITask>) {
      if (this.selectedProject && t.project !== this.selectedProject) {
        continue
      }
      if (t.id === this.getActiveTaskId) {
        // skip tasks where the timetracking is active
        continue
      }
      if (t.is_completed) {
        // skip finished tasks
        continue
      }
      const projectName = ProjectsModule.getProjects[t.project || 0]?.name
      this.selTasks.push({
        projectName: projectName,
        projectId: t.project,
        text: t.name,
        value: t.id,
      })
    }
  }

  created() {
    UserModule.fetchUserInfo().then(() => {
      this.fetchTimetracking().then(() => {
        if (
          this.getState === TimetrackingState.ACTIVE ||
          this.getState === TimetrackingState.PAUSED
        ) {
          this.loadFormPreset()
        }
      })
    })
  }

  mounted() {
    if (this.getIsInitializing) {
      return
    }
    // init after navigating back to tiktrac
    this.initializeTimers()
    this.getSelProjectList()
    this.getSelTaskList()
  }

  @Watch('now')
  changeCurrentTask() {
    let busyList = this.getTimetracking.busyList
    let taskName: string | undefined = ''

    for (let item in busyList) {
      if (this.now >= busyList[item].start && this.now < busyList[item].end) {
        if (busyList[item].is_all_day) continue

        let currentItemTaskId = busyList[item].task
        taskName = ProjectsModule.getTasks[currentItemTaskId]?.name || ''
      }
    }

    this.currentTaskNameNotCheckin = taskName
  }

  @Watch('getIsInitializing')
  init(isInitializing) {
    if (isInitializing) {
      return
    }
    // init after page reload after the store is ready
    this.initializeTimers()
    ProjectsModule.fetchProjects().then(() => {
      this.getSelProjectList()
      this.getSelTaskList()
    })
  }

  @Watch('getProjects')
  projectsUpdated(projects) {
    if (!projects) return
    this.getSelProjectList()
  }

  @Watch('getState')
  toggleTimer(state: TimetrackingState, oldState: TimetrackingState) {
    const stateChange = oldState !== TimetrackingState.UNKNOWN && state !== oldState
    if (!stateChange) {
      return
    }
    if (oldState === TimetrackingState.INACTIVE) {
      this.loadFormPreset()
    }
    if (state === TimetrackingState.ACTIVE) {
      // CHECKIN
      if (this.intervalPause !== null) {
        clearInterval(this.intervalPause)
      }
      this.pauseTimePassed = this.getPauseTime
      const startTime = this.getActiveStartTime
      // set timer
      this.timePassed = Date.now() - startTime - this.getPauseTime
      // add new segment
      this.segments.push({
        progressPercent: 0,
        rotate: this.calcRotation(startTime),
        startTime: startTime,
        state: TimetrackingState.ACTIVE,
      })
      // activate timer
      this.initializeInterval(TimetrackingState.ACTIVE)
    } else if (state === TimetrackingState.PAUSED) {
      // PAUSE
      if (this.intervalTimetracking !== null) {
        clearInterval(this.intervalTimetracking)
      }
      const startTime = this.getActivePause || 0
      // add new segment
      this.segments.push({
        progressPercent: 0,
        rotate: this.calcRotation(startTime),
        startTime: startTime,
        state: TimetrackingState.PAUSED,
      })
      this.initializeInterval(TimetrackingState.PAUSED)
    } else {
      // CHECKOUT/RESET
      this.clearAllIntervals()
      this.timePassed = 0
      this.pauseTimePassed = 0
      this.segments = []
    }
  }

  @Watch('segments.length')
  addProgressSegment(val: number, oldVal: number) {
    if (!this.isTimerInitialized && oldVal !== -1) {
      // when called manually during initialization.
      // used to prevent auto trigger during initialization.
      return
    }
    if (val <= oldVal && val !== 0) {
      return
    }
    if (oldVal !== -1 && val !== 0 && val !== oldVal + 1) {
      // only proceed if segments array is grown by one or when set empty
      return
    }
    if (val === 0) {
      // delete all segments
      const svgs = document.querySelectorAll(
        '.v-progress-circular > svg:not(:last-of-type)'
      )
      svgs.forEach((elem) => {
        elem.remove()
      })
      // reset initial segment
      const overlay = document.querySelector(
        '.v-progress-circular > svg .v-progress-circular__overlay'
      ) as HTMLElement
      if (!overlay) {
        return
      }
      this.calcProgressPixel(0, overlay)
    } else {
      const firstSegment = val === 1
      // add new segment
      const svg = document.querySelector(
        '.v-progress-circular > svg:first-of-type'
      ) as HTMLElement
      if (!svg) {
        return
      }
      if (!firstSegment) {
        // freeze old segment
        const svgNew = svg.cloneNode(true) as HTMLElement
        svg.insertAdjacentElement('afterend', svgNew)
        // set progress background color to transparent
        const underlay = svg.querySelector(
          '.v-progress-circular__underlay'
        ) as HTMLElement
        underlay.style.stroke = 'transparent'
      }
      const progress = this.segments[val - 1]
      // set rotation
      svg.style.transform = `rotate(${progress.rotate}deg)`
      // set segment color
      const overlay = svg.querySelector('.v-progress-circular__overlay') as HTMLElement
      overlay.style.stroke =
        progress.state === TimetrackingState.ACTIVE
          ? 'var(--v-primary-base)'
          : 'var(--v-warning-base)'
      // set segment progress
      const timeDelta = this.calcTimeDelta(progress.progressPercent)
      this.calcProgressPixel(timeDelta, overlay)
    }
  }

  @Watch('dialogCheckOut')
  onDialogCheckoutToggle(val: boolean) {
    if (!val) {
      return
    }
    // set checkout time on open
    this.checkOutTime = Date.now()
  }

  beforeDestroy() {
    this.clearAllIntervals()
  }

  resetForm() {
    this.selectedProject = 0
    this.selectedTaskName = ''
    this.selTaskChangeProject = false
    this.isActivePlusButton = false
    this.changeInputBgColor(false)
  }

  /**
   * Set timer values
   */
  initializeTimers() {
    const now = Date.now()
    // init pause timer
    if (this.getActivePause === null) {
      this.pauseTimePassed = this.getPauseTime
    } else {
      this.pauseTimePassed = now - this.getActivePause
    }
    // init work timer
    this.timePassed = now - this.getActiveStartTime - this.pauseTimePassed
    // init segments
    this.segments = []
    this.setSegments(now)
    // init intervals
    this.initializeInterval(this.getState)
    this.isTimerInitialized = true
  }

  initializeInterval(state: TimetrackingState) {
    // init current date interval
    this.intervalDateTime = window.setInterval(() => {
      this.now = Date.now()
    }, 1000)
    // init tiktrac progressbar intervals
    let startTime = 0
    switch (state) {
      case TimetrackingState.ACTIVE:
        startTime = this.getActiveStartTime
        this.intervalTimetracking = window.setInterval(() => {
          const now = Date.now()
          // check if timer is valid
          if (this.getActiveTimerLimit && now >= this.getActiveTimerLimit) {
            this.checkOutTime = Date.now()
            this.checkOut(null, true)
            return
          }
          // calc progress
          this.timePassed = now - startTime - this.getPauseTime
          this.updateCurrentProgress(now)
        }, 1000)
        break
      case TimetrackingState.PAUSED:
        startTime = this.getActivePause || 0
        this.intervalPause = window.setInterval(() => {
          const now = Date.now()
          // check if timer is valid
          if (this.getActiveTimerLimit && now >= this.getActiveTimerLimit) {
            this.checkOutTime = Date.now()
            this.checkOut(null, true)
            return
          }
          // calc progress
          this.pauseTimePassed = now - startTime
          this.updateCurrentProgress(now)
        }, 1000)
        break
    }
  }

  setSegments(now: number) {
    let start = this.getActiveStartTime
    if (!start) {
      return
    }
    let trackingTimePassed = now - start
    this.getActivePauses.forEach((pause) => {
      // add active + pause segment
      const timeActive = pause.start - start
      const timePause = (pause.end || now) - pause.start
      this.segments.push({
        rotate: this.calcRotation(start),
        progressPercent: this.calcProgressPercent(timeActive),
        startTime: start,
        state: TimetrackingState.ACTIVE,
      })
      this.addProgressSegment(this.segments.length, -1)
      this.segments.push({
        rotate: this.calcRotation(pause.start),
        progressPercent: this.calcProgressPercent(timePause),
        startTime: pause.start,
        state: TimetrackingState.PAUSED,
      })
      this.addProgressSegment(this.segments.length, -1)
      // set rest of tracking time
      trackingTimePassed -= timeActive + timePause
      // update start
      start = pause.end || now
    })
    // set rest of working time
    if (trackingTimePassed > 0) {
      this.segments.push({
        rotate: this.calcRotation(start),
        progressPercent: this.calcProgressPercent(trackingTimePassed),
        startTime: start,
        state: TimetrackingState.ACTIVE,
      })
      this.addProgressSegment(this.segments.length, -1)
    }
  }

  calcRotation(startTime: number) {
    const date = new Date(startTime)
    const timeOfDay = (date.getHours() + date.getMinutes() / 60) % 12 // ∈ [0;12)
    return ((360 / 12) * timeOfDay - 90) % 360
  }

  calcProgressPercent(timeDelta: number) {
    return (timeDelta / this.constProgressFull) * 100
  }

  calcTimeDelta(progressPercent: number) {
    return (progressPercent / 100) * this.constProgressFull
  }

  calcProgressPixel(timeDelta: number, overlay: HTMLElement) {
    let range = overlay.attributes['stroke-dasharray']?.value
    if (range === undefined) {
      return
    }
    range = Number.parseFloat(range)
    const deltaHours = timeDelta / 1000 / 60 / 60
    const offset = range - (range / 12) * deltaHours
    overlay.attributes['stroke-dashoffset'].value = offset.toString() + 'px'
  }

  updateCurrentProgress(now: number) {
    const segLen = this.segments.length
    if (!segLen) {
      return
    }
    const segment = this.segments[segLen - 1]
    segment.progressPercent = this.calcProgressPercent(now - segment.startTime)
    this.$set(this.segments, segLen - 1, segment)
  }

  updateSelProjects(projectId: number) {
    this.selectedProject = projectId
    // update select task list
    this.getSelTaskList()
    // If the user choosed an existing task from the list and then changed the project,
    // set a flag that causes the creation of a new task.
    if (typeof this.selectedTaskName === 'string') {
      return
    }
    this.selTaskChangeProject = projectId !== this.selectedTaskName.projectId
  }

  updateSelectedProject(item: string | Tiktrac.LooseObject) {
    if (this.isActivePlusButton && typeof item !== 'string') {
      this.isActivePlusButton = false
      this.changeInputBgColor(false)
    }

    // If the user choosed an existing task from the list,
    // auto select the appropriate project.
    if (typeof item === 'string') {
      return
    }
    this.selectedProject = item.projectId || 0
  }

  clearAllIntervals() {
    if (this.intervalDateTime !== null) {
      clearInterval(this.intervalDateTime)
    }
    if (this.intervalTimetracking !== null) {
      clearInterval(this.intervalTimetracking)
    }
    if (this.intervalPause !== null) {
      clearInterval(this.intervalPause)
    }
  }

  loadFormPreset() {
    // TODO optimize this
    if (this.getActiveTimer) {
      const activeIssue = this.getTimetracking['busyList'][this.getActiveTimer] || {}
      if (activeIssue.is_autogenerated === false) {
        this.selectedProject = activeIssue?.project || 0
        const projectName = ProjectsModule.getProjects[activeIssue.project]?.name || ''
        if (activeIssue.taskName) {
          this.selectedTaskName = {
            projectId: activeIssue.project,
            projectName: projectName,
            text: activeIssue.taskName,
            value: activeIssue.task,
          }
        } else {
          let projectName = ''
          ProjectsModule.fetchProjects(true).then(() => {
            projectName = ProjectsModule.getProjects[activeIssue.project]?.name || ''
          })

          ProjectsModule.fetchTaskByID(activeIssue.task).then((res) => {
            if (res.name) {
              this.selectedTaskName = {
                projectId: activeIssue.project,
                projectName: projectName,
                text: res.name || '',
                value: activeIssue.task,
              }
            }
          })
        }
      }
    }
  }

  openPopupOnMobile() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      // only for mobile view
      return
    }
    this.dialogMobile = true
  }

  async checkin() {
    this.checkInProcess = true
    await this.checkIn(this.task)
    this.checkInProcess = false
  }

  checkOut(e: MouseEvent | null, silent = false) {
    this.checkOutProcess = true

    if (this.getState === TimetrackingState.INACTIVE) {
      // TODO
      !silent && alert(this.$t('ticktrack.ticktrackMini.checkoutAlert'))
      this.checkOutProcess = false
      return
    }

    if (!silent) {
      // Apply v-combobox content to the bound variable.
      // This is necessary if user types in a new value
      // instead of selecting an existing one from the drop down list.
      const input = this.$refs['selectedTaskName'] as HTMLElement
      input.blur()
    }

    // is user has not selected a project, select the default one
    if (this.selectedProject == 0) {
      this.selectedProject = ProjectsModule.getDefaultProjectId
    }

    // wait for vue to render the change
    this.$nextTick(() => {
      // get task info
      let selectedTaskName = ''
      let selectedTaskId = 0
      if (typeof this.selectedTaskName === 'string' || this.selTaskChangeProject) {
        // user wants to create a new task
        selectedTaskName =
          typeof this.selectedTaskName === 'string'
            ? this.selectedTaskName
            : this.selectedTaskName.text
      } else {
        // user wants to resume tracking an existing task
        selectedTaskId = this.selectedTaskName.value
      }

      // check additional input
      if (e && !this.form.validate()) {
        this.checkOutProcess = false
        return
      }

      // prepare POST data
      const data = {
        intent: 'checkout',
        issueId: this.getActiveTimer,
        time: this.checkOutTime,
        project: this.selectedProject,
        taskName: selectedTaskName,
        taskId: selectedTaskId,
        taskCompleted: false,
      }

      const doCheckOut = (data) => {
        // checkout
        this.doCheckOut(data)
          .then(() => {
            // TODO use a smarter way to update the data
            // fetch updated project list and update task list
            ProjectsModule.fetchProjects(true).then(() => {
              this.getSelTaskList()
            })
            // reset user input
            this.resetForm()
            this.form.resetValidation()
          })
          .catch((error: Tiktrac.Response) => {
            if (error.responseCode === 409) {
              // conflict
              // TODO handle this special case
              !silent &&
                this.showSnackbar({
                  text: `${this.$t('ticktrack.ticktrackMini.snackBars.checkoutError')}`,
                  type: 'error',
                })
            } else {
              !silent &&
                this.showSnackbar({
                  text: `${this.$t(
                    'ticktrack.ticktrackMini.snackBars.checkoutTimeError'
                  )}`,
                  type: 'error',
                })
            }
          })
          .finally(() => {
            this.checkOutProcess = false
            this.dialogCheckOut = false
            this.dialogMobile = false
          })
      }

      if (silent) {
        data.taskCompleted = false
        doCheckOut(data)
      } else {
        data.taskCompleted = this.isTaskDone
        doCheckOut(data)
        this.isTaskDone = false
      }
    })
  }

  togglePause(e: MouseEvent) {
    // get current timestamp
    // TODO This could be manipulated by setting the system date/time to any value
    const curTime = new Date().getTime()
    this.togglePauseProcess = true

    if (this.getState === TimetrackingState.INACTIVE) {
      this.togglePauseProcess = false
      return
    }

    if (this.getState === TimetrackingState.ACTIVE) {
      // start pause
      const data = {
        intent: 'pauseStart',
        issueId: this.getActiveTimer,
        time: curTime,
      }
      this.doPauseStart(data).then(() => {
        this.togglePauseProcess = false
      })
    } else {
      // end pause
      const data = {
        intent: 'pauseEnd',
        issueId: this.getActiveTimer,
        time: curTime,
      }
      this.doPauseEnd(data).then(() => {
        this.togglePauseProcess = false
      })
    }
  }

  changeInputBgColor(param: boolean) {
    const inputTask = document.querySelector('#uniqTask') as HTMLSelectElement
    const fieldsetEl = inputTask?.parentElement
      ?.previousElementSibling as HTMLSelectElement
    fieldsetEl.classList.remove('bg-combobox-primary')
    if (param) {
      fieldsetEl.classList.add('bg-combobox-primary')
    }
  }

  whenTaskIsTyping() {
    this.inputTask = document.querySelector('#uniqTask') as HTMLSelectElement
    const selectedTaskNameVal = this.inputTask?.value

    this.isActivePlusButton = true
    this.changeInputBgColor(true)
    if (!selectedTaskNameVal || selectedTaskNameVal.length === 0) {
      this.isActivePlusButton = false
      this.changeInputBgColor(false)
    }
  }

  handleClickButton() {
    const input = this.$refs['selectedTaskName'] as HTMLElement
    if (this.isActivePlusButton) {
      input.blur()
      this.$nextTick(() => {
        this.selectedTaskName = ''
        this.isActivePlusButton = false
        this.changeInputBgColor(false)
      })
    } else {
      input.focus()
      this.$nextTick(() => {
        this.selectedTaskName = ''
        this.isActivePlusButton = true
      })
    }
  }
}
