import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import tiktracRoutes from './tiktrac-routes'
import paytracRoutes from './paytrac-routes'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/ProjectsPage/Index.vue'),
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/views/TasksPage/Index.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/SettingsPage/Index.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/UsersPage/Index.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/CalendarPage/Index.vue'),
  },
]
routes.push(tiktracRoutes)
routes.push(paytracRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
