
import { Component, Vue, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TaskDialog from '@/components/dialogs/TaskDialog.vue'

import { IDialog, ISnackbar, ITask } from '@/interfaces'
import type Tiktrac from 'vue/types/tiktrac'

import { ProjectsModule } from '@/store/modules/projectsModule'
const StoreTiktrac = namespace('Timetracking')
const StoreUI = namespace('UI')

@Component({ components: { TaskDialog } })
export default class DailyBaseComponent extends Vue {
  @StoreTiktrac.Getter
  public getActiveTaskId!: number

  @StoreUI.Action
  public showDialog!: (data: IDialog) => Promise<boolean>

  @StoreUI.Action
  public showSnackbar!: (data: ISnackbar) => void

  @Ref('taskDialog') taskDialog!: TaskDialog

  async completeTask(task: ITask) {
    let taskData = {} as ITask
    taskData.is_completed = true
    const data = {
      projectId: task.project || 0,
      task: taskData,
      taskId: task.id,
    }
    if (!(await ProjectsModule.updateTaskAction(data))) {
      this.showSnackbar({
        text: `${this.$t('dialogs.taskDialog.snackBars.updateTaskError')}`,
        type: 'error',
      })
    } else {
      this.showSnackbar({
        text: `${this.$t('calendar.dailyBaseComponent.snackBars.completeTaskSuccess')}`,
        type: 'success',
      })
    }
  }

  editTask(item: ITask) {
    this.taskDialog.editTask(item)
  }

  editTimetracking(item: Tiktrac.BusyListElem) {
    this.taskDialog.editTimetracking(item)
  }

  deleteTask(item: ITask) {
    // ask user if he really wants to perfom this action
    this.showDialog({
      text: `${this.$t('calendar.dailyBaseComponent.snackBars.deleteTaskTitle')}`,
    }).then((response: boolean) => {
      if (!response) {
        // declined
        return
      }
      // delete task
      if (item.id === this.getActiveTaskId) {
        this.showSnackbar({
          text: `${this.$t('calendar.dailyBaseComponent.snackBars.deleteTaskError')}`,
          type: 'error',
        })
      } else {
        ProjectsModule.deleteTaskAction({
          id: item.id,
          projectId: item.project,
        })
      }
    })
  }
}
