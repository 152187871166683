
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ProjectDialog from '@/components/dialogs/ProjectDialog.vue'
import QuickTaskCreator from '@/components/forms/QuickTaskCreator.vue'
import QuickTaskCreatorForm from '@/components/forms/QuickTaskCreatorForm.vue'
import BaseButton from '@/components/ui/BaseButton.vue'
import ChatBotMenu from '../chatBot/ChatBotMenu.vue'
import ChatBotBeauty from '../chatBot/ChatBotBeauty.vue'
const ChatbotModule = namespace('ChatbotModule')

@Component({
  components: {
    QuickTaskCreator,
    ProjectDialog,
    QuickTaskCreatorForm,
    BaseButton,
    ChatBotMenu,
    ChatBotBeauty,
  },
})
export default class ChatPopup extends Vue {
  @Ref('chatBotBeauty') botBeauty!: ChatBotBeauty

  @ChatbotModule.State
  public vMenuVal

  @ChatbotModule.State
  public isTrackingEntryActive

  @ChatbotModule.State
  public isQuickTaskFormActive

  @ChatbotModule.State
  public isChatListActive

  @ChatbotModule.Mutation
  public vMenuFlag!: () => void

  @ChatbotModule.Mutation
  public vMenuSetVal!: (val: boolean) => void

  @ChatbotModule.Action
  public sendMsgToChatbot!: (messageTxt: string) => Promise<any>

  localChatFlagVal = false
  value = false

  mounted() {
    setTimeout(() => {
      this.sendReminderRequest()
    }, 2000)
  }

  @Watch('localChatFlagVal')
  onStateChatFlagVal() {
    this.vMenuSetVal(this.localChatFlagVal)
    this.value = !this.value
  }

  chatFlag() {
    this.vMenuFlag()
  }

  sendReminderRequest() {
    const now = new Date()
    const reminderUpdate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).toISOString()
    if (localStorage.getItem('taskReminder') != reminderUpdate) {
      localStorage.setItem('taskReminder', reminderUpdate)
      this.sendMsgToChatbot('/intent_task_reminders').then((data) => {
        if (!data || (Array.isArray(data) && !data.length)) {
          // no reminder
          return
        }
        // reminder -> open chat window
        this.localChatFlagVal = true
        this.$nextTick(() => {
          this.botBeauty.reviewDataStructAndKeys(data)
        })
      })
    }
  }
}
