var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-card',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"rendering"},[_c('v-sheet',{staticClass:"timeTracking-wrapper"},[(_vm.isTrackingEntryActive)?_c('TrackingEntry',{style:({
            background: '#FFFFFF',
            padding: '0 40px',
            boxShadow: '0px 3px 6px #00000029',
          })}):_vm._e()],1),_c('v-sheet',{staticClass:"quick-creator"},[(_vm.isQuickTaskFormActive)?_c('QuickTaskCreatorForm',{class:{
            mobile: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.md,
          },style:({
            position: 'absolute',
            bottom: '40px',
            right: '-3px',
            boxShadow: '0px 3px 6px #00000029',
          }),on:{"setIsEditingFromChatMenu":_vm.setIsEditingFromChatMenu,"confirmed":_vm.atEditTask}}):_vm._e()],1),_c('TaskDialog',{ref:"taskDialog"}),_c('ProjectDialog',{ref:"projectDetails"})],1),_c('div',{staticClass:"d-flex flex-column justify-end",style:({
        gap: '35px',
      })},[(_vm.isAgencyMode)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-btn',{class:{
            'btn-checkin': !_vm.attendanceStateActive,
            'btn-checkout': _vm.attendanceStateActive,
          },attrs:{"fab":"","dark":"","small":"","width":"52","height":"52","color":!_vm.attendanceStateActive ? 'primary' : '#C38080',"disabled":_vm.attendanceStateLoading},on:{"click":_vm.checkInOut}},[(_vm.attendanceStateActive)?[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v("mdi-arrow-collapse-right")])]:[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v("mdi-arrow-collapse-right")])]],2)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary","width":"52","height":"52"},on:{"click":function($event){return _vm.NewProjectToggle()}}},[_c('v-img',{staticClass:"mt-1",attrs:{"width":"1rem","height":"1rem","contain":"","src":require('@/assets/Icon-project-diagram.svg')}})],1)],1),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary","width":"52","height":"52"},on:{"click":_vm.NewTimeTrackingToggle}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary","width":"52","height":"52"},on:{"click":_vm.NewTaskToggle}},[_c('v-img',{staticClass:"mt-1",attrs:{"width":"1rem","height":"1rem","contain":"","src":require('@/assets/Icon-open-task.svg')}})],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }