import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/src/locale/de'
import en from 'vuetify/src/locale/en'
import i18N from '@/plugins/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // active element/text
        primary: '#177B7B',
        // normal text
        secondary: '#0F0F0F',
        accent: '#82B1FF',
        error: '#C38080',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#D6B384',
      },
    },
  },
  lang: {
    locales: { de, en },
    current: `${i18N.t('vuetify')}`,
  },
})
