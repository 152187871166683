import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/store'
import Vue from 'vue'
import { callCalendar } from '@/plugins/msal/index'
import i18N from '@/plugins/i18n'

import { AxiosResponse } from 'axios'
import { ICalendarSortedEvents, ITaskList } from '@/interfaces'

@Module({
  name: 'calendar',
  store: store,
  dynamic: true,
  namespaced: true,
})
class Calendar extends VuexModule {
  sortedCalendarEvents: ICalendarSortedEvents = {
    importantTasks: [],
    urgentTasks: [],
    mediumTasks: [],
    importantProjects: [],
    urgentProjects: [],
    mediumProjects: [],
  }
  tasks: ITaskList = {}

  get getSortedCalendarEvents() {
    return this.sortedCalendarEvents
  }
  @Mutation
  initializeCalendarValues(values: ICalendarSortedEvents) {
    this.sortedCalendarEvents = values
  }

  @Action({ commit: 'initializeCalendarValues' })
  async fetchCalendarValues() {
    try {
      const result = await Vue.prototype.$http.get('/overall/calendar-data/')
      return result.data
    } catch (err) {
      console.error(err)
    }
  }

  get tasksWithTimeTracking() {
    return this.tasks
  }
  @Mutation
  initializeTasks(values: ITaskList) {
    this.tasks = values
  }

  @Action({ commit: 'initializeTasks' })
  async fetchTasksWithTimetracking() {
    try {
      const result = await Vue.prototype.$http.get('/overall/tasks-with-timetracking/')
      return result.data
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  async syncMSCalendar() {
    // TODO has to be optimized
    const response: AxiosResponse | undefined = await callCalendar()
    if (!response) {
      this.context.dispatch(
        'UI/showSnackbar',
        { text: i18N.t('calendar.microsoft.snackBars.syncError'), type: 'error' },
        { root: true }
      )
      return
    }
    if (response.status === 200) {
      // MS outlook sync caused changes -> update tasks and busyList entries
      this.context.dispatch('projects/fetchAllTasks', true, { root: true })
      this.context.dispatch('Timetracking/fetchTimetracking', null, { root: true })
    }
  }
}

export const CalendarModule = getModule(Calendar)
