import type DateTime from 'vue/types/datetime'
import i18N from '@/plugins/i18n'

const lang = `${i18N.t('datetime')}`

const formatDate: DateTime.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
}
const formatTime: DateTime.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
}
const formatDecimalNumber = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const formatterDate = new Intl.DateTimeFormat(lang, formatDate)
export const formatterTime = new Intl.DateTimeFormat(lang, formatTime)
export const formatterDecimalNumber = new Intl.NumberFormat(lang, formatDecimalNumber)

export const toISODateString = function (date: Date): string {
  return date.toISOString().substring(0, 10)
}

export const hoursToDecimal = function (hours: string): string {
  const idxDelimiter = hours.indexOf(':')
  const h = Number.parseInt(hours.substr(0, idxDelimiter))
  const m = Number.parseInt(hours.substr(idxDelimiter + 1))
  const decimal = h + m / 60
  return formatterDecimalNumber.format(decimal)
}

export const decimalToHours = function (
  decimal: number,
  round: boolean = true,
  showSeconds: boolean = false
): string {
  const wh = decimal < 0 ? Math.ceil(decimal) : Math.floor(decimal)
  // delete the two decimal places
  let worked_hours = '0'
  if (wh > -10 && wh < 10) {
    if (decimal < 0) {
      worked_hours = '-0' + wh.toString().substring(1)
    } else {
      worked_hours = '0' + wh.toString()
    }
  } else {
    worked_hours = wh.toString()
  }
  // get minutes from the decimal places
  // get decimal part
  let wm = (decimal - wh) % 1
  // calc minutes per hour
  wm = wm * 60
  // get positive result
  if (wm < 0) {
    wm *= -1
  }
  // round result
  wm = round ? Math.round(wm) : Math.floor(wm)
  // append zero if needed
  let worked_minutes = wm.toString()
  if (wm < 10) {
    worked_minutes = '0' + worked_minutes
  }
  // get seconds
  let worked_seconds = ''
  if (showSeconds) {
    // TODO
    worked_seconds = ':00'
  }

  return worked_hours + ':' + worked_minutes + worked_seconds
}

// Return the number `n` with exactly `digits` decimal points
export const toFixedNumber = function (n: number, digits: number = 2) {
  const pow = Math.pow(10, digits)
  return Math.round(n * pow) / pow
}
