
import { Component, VModel, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { isProp } from '@/services/common'

@Component({})
export default class InlineEdit extends Vue {
  @VModel() valueSync!: any

  @Prop({ type: [String, Boolean] })
  readonly alwaysShowInput?: string | boolean

  @Prop({ type: String, default: '' })
  readonly classes!: string

  @Prop({ type: [String, Boolean] })
  readonly disabled?: string | boolean

  @Prop({ type: Function, default: (a) => a })
  readonly fromRepr!: Function

  @Prop({ type: [String, Boolean] })
  readonly hideDetails?: string | boolean

  @Prop({ type: [String, Boolean], default: true })
  readonly outlined?: string | boolean

  @Prop({ type: [String, Boolean] })
  readonly loading?: string | boolean

  @Prop({ type: String, default: '' })
  readonly placeholder!: string

  @Prop({ type: [String, Boolean] })
  readonly readonly?: string | boolean

  @Prop({ type: Array, default: () => [] })
  readonly rules!: Array<Function>

  @Prop({ type: [Function, Promise] })
  readonly save?: Function

  @Prop({ type: Function, default: (a) => a })
  readonly toRepr!: Function

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Ref()
  readonly ref!: any

  disabledInput = false
  editing = false
  origValue: any = null
  valid = false

  get isAlwaysShowInput() {
    return isProp(this.alwaysShowInput)
  }

  get isDisabled() {
    return isProp(this.disabled)
  }

  get isHideDetails() {
    return isProp(this.hideDetails)
  }

  get isOutlined() {
    return isProp(this.outlined)
  }

  get isLoading() {
    return isProp(this.loading)
  }

  get isReadonly() {
    return isProp(this.readonly)
  }

  get getClasses() {
    return `editable ${this.classes}`
  }

  get valueRepr() {
    return this.toRepr(this.valueSync)
  }

  set valueRepr(value) {
    this.valueSync = this.fromRepr(value)
  }

  @Watch('editing')
  onToggleEditing(editing: boolean) {
    if (editing) {
      /**
       * on start editing
       */
      this.origValue = this.valueSync
      this.valid = this.isInputValid()
    }
  }

  mounted() {
    this.valid = this.isInputValid()
  }

  hasChanged() {
    return this.valueSync !== this.origValue
  }

  isInputValid() {
    return this.ref.valid
  }

  open() {
    if (this.isReadonly) {
      return
    }
    this.editing = true
  }

  close(event: Event) {
    if (event.type === 'blur' && this.isAlwaysShowInput) {
      return
    }
    this.$nextTick(async () => {
      // save and emit only when value has changed
      if (this.hasChanged()) {
        // validate input
        if (!this.isInputValid()) {
          return
        }
        // user save function
        if (this.save) {
          this.disabledInput = true
          await this.save(this.valueSync)
          this.disabledInput = false
        }
        // emit changes
        this.$emit('save', this.valueSync)
      }
      // leave edit state
      if (!this.isAlwaysShowInput) {
        this.editing = false
      }
    })
  }
}
